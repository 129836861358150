import "react-quill/dist/quill.snow.css"; // import the styles
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row, Spin } from "antd";
import { verifyExistingPassword, changePassword } from "../api/changePassapi";
import { Button, Form, Input, Space } from "antd";
import axios from "axios";
import UserData from "./UserData";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

function Changepass() {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const userEmail = UserData.getEmail();
  const userJwtToken = localStorage.getItem("Token");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = async (values) => {
    setIsModalVisible(true);
    if (values["newPassword"] !== values["confirmPassword"]) {
      message.warning(
        "New Password and Confirm Password does not match! Please try again."
      );
      setIsModalVisible(false);

      return;
    } else {
      const givenPassword = values["existingPassword"];

      const response = await verifyExistingPassword(
        userEmail,
        givenPassword,
        userJwtToken
      );

      if (response.status === 200) {
        const response = await changePassword(
          values.newPassword,
          values.existingPassword,
          values.confirmPassword,
          userJwtToken
        );

        if (response.status === 200) {
          navigate("/admin");
          message.success("Password updated successfully!");
        } else {
        }
        setIsModalVisible(false);
      }
    }
  };

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            <h1>Change password</h1>
          </div>
          <Form onFinish={onFinish} layout="vertical">
            <Row justify="center">
              <Col span={8}>
                <Form.Item
                  label="Existing Password"
                  name="existingPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Existing Password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter your password" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={8}>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your New Password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter new password" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={8}>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={8}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {isModalVisible && (
            <div className="spinner-loading">
              {/* <Spin size="large" /> */}
              <Spin
                indicator={<LoadingOutlined className="spin-icon" spin />}
              />
            </div>
          )}
        </div>
        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Changepass;
