import axios from "axios";
// Create Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Dev API URL
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ------------------------------------------//
export const get_Flats_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-flat?populate[0]=seo&populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=sustainaibility.side_img&&populate[4]=contact_us.contact_detail.logo &populate[5]=contact_us1_fields.logo&populate[6]=contact_us2_fields.logo`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

//-------Get All Flat Subpages------------------------------

export const get_Flats_Subpages = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/master-pages?page_title=flats&populate[0]=master_subpages`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put("/api/page-flat", postformdata);
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

export const subpageupdate = async (id, postformdata) => {
  try {
    const response = await axiosInstance.put(
      `/api/master-subpages/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const uploadImageAPI = async (formData, userJwtToken) => {
  const uploadResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: formData,
    }
  );
  return uploadResponse;
};

export const postresponseAPI = async (postformdata, userJwtToken) => {
  const postresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/page-home`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: postformdata, // Convert data to JSON string and send in the request body
    }
  );
  return postresponse;
};

export const deleteimageAPI = async (imageId, userJwtToken) => {
  const deleteresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload/files/${imageId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
    }
  );
  return deleteresponse;
};

// login.js
export const loginAPI = async (email, password) => {
  const loginresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/local`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    }
  );
  return loginresponse;
};

// ------------------------------------------//
export const get_Flats_agri_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-agriculture?populate[0]=agriculture_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_agri = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-agriculture",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_auto_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-automotive?populate[0]=automotive_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_auto = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-automotive",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_cons_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-construction?populate[0]=construction_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_cons = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-construction",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_pack_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-packaging?populate[0]=packaging_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_pack = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-packaging",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_appl_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-appliance?populate[0]=appliance_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_appl = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-appliance",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_buil_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-building?populate[0]=building_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_buil = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-building",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_ener_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-energy?populate[0]=energy_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_ener = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-energy",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_tran_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-transportation?populate[0]=transportation_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_tran = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-transportation",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Flats_sust_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-flats-sustainaibility?populate[0]=sustainaibility_details.image&populate[1]=xcarbs_details_1.image&populate[2]=xcarbs_details_1.pdf_items.pdf&populate[3]=xcarbs_details_2.image&populate[4]=xcarbs_details_2.pdf_items.pdf `
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Flats_sust = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-flats-sustainaibility",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//
