
import axios from "axios";
// Create Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Dev API URL
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const get_Banner_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-home?populate[0]=seo&populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=newsletter_section`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const get_Structuralshapes_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-structural-shape?populate[0]=seo&populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=technical_assessment.technical_assessment_img&populate[4]=subpage&populate[5]=contact_us.contact_detail.logo &populate[6]=contact_us1_fields.logo&populate[7]=contact_us2_fields.logo`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_barsnrods_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-bars-rod?populate[0]=seo&populate[1]=banner_section.main_img&populate[2]=businessline_block_item.image_carousel&populate[3]=contact_us.contact_detail.logo &populate[6]=contact_us1_fields.logo&populate[7]=businessline_block_item.bg_img`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Homedata = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put("/api/page-home", postformdata);
    return response.data;
  } catch (error) {}
};

export const Save_StructuralShape = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/page-structural-shape",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Save_Barsnrods = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/page-bars-rod",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const getBusinessData = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-home?populate[1]=banner_block.banner_item.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadImageAPI = async (formData, userJwtToken) => {
  const uploadResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: formData,
    }
  );
  return uploadResponse;
};

export const postresponseAPI = async (postformdata, userJwtToken) => {
  const postresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/page-home`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: postformdata, // Convert data to JSON string and send in the request body
    }
  );
  return postresponse;
};

export const deleteimageAPI = async (imageId, userJwtToken) => {
  const deleteresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload/files/${imageId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
    }
  );
  return deleteresponse;
};

// login.js
export const loginAPI = async (email, password) => {
  const loginresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/local`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    }
  );
  return loginresponse;
};

// ------------------------------------------//
export const get_Structural_product_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-structuralshape-product?populate[0]=section1_details.image&populate[1]=horizontal_block_product&populate[2]=section3_details&populate[3]=pdf_item.title&populate[4]=pdf_item.pdf`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Structural_product = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-structuralshape-product",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Structural_a913_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-stshape-a913?populate[0]=sec1.image&populate[1]=a913_table&populate[2]=process_acrd_dtls.image&populate[3]=weight_acrd_dtls .image&populate[4]=weld_acrd_table1&populate[5]=weld_acrd_dtls1.image&populate[6]=weld_acrd_table2&populate[7]=tough_acrd_dtls.image&populate[8]=code_acrd_dtls.image&populate[9]=seismic_acrd_dtls.image&populate[10]=avl_acrd_dtls.image&populate[11]=weight_acrd_dtls.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Structural_a913 = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-stshape-a913",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const get_a913mecaltbls = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.get(
      "/api/a913-mecal-tbls",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Save_a913mecaltbls_product_ByID = async (
  postformdata,
  id,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      `/api/a913-mecal-tbls/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Delete_a913mecaltbls_product_ByID = async (id, userJwtToken) => {
  try {
    const response = await axiosInstance.delete(`/api/a913-mecal-tbls/${id}`);
    return response.data;
  } catch (error) {}
};

export const Save_a913mecaltbls_product = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.post(
      "/api/a913-mecal-tbls",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const get_a913weldbtbl1s = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.get(
      "/api/a913-weldb-tbl-1s",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Save_a913weldbtbl1s_product_ByID = async (
  postformdata,
  id,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      `/api/a913-weldb-tbl-1s/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Delete_a913weldbtbl1s_product_ByID = async (id, userJwtToken) => {
  try {
    const response = await axiosInstance.delete(`/api/a913-weldb-tbl-1s/${id}`);
    return response.data;
  } catch (error) {}
};
export const Save_a913weldbtbl1s_product = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.post(
      "/api/a913-weldb-tbl-1s",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const get_a913weldbtb2s = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.get(
      "/api/a913-weldb-tbl-2s",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Save_a913weldbtbl2s_product_ByID = async (
  postformdata,
  id,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      `/api/a913-weldb-tbl-2s/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Delete_a913weldbtbl2s_product_ByID = async (id, userJwtToken) => {
  try {
    const response = await axiosInstance.delete(`/api/a913-weldb-tbl-2s/${id}`);
    return response.data;
  } catch (error) {}
};

export const Save_a913weldbtbl2s_product = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.post(
      "/api/a913-weldb-tbl-2s",
      postformdata
    );
    return response.data;
  } catch (error) {}
};


// ------------------------------------------//
export const get_Structural_sustainaibility_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-structuralshape-sustainaibility?populate[0]=section1_details.image&populate[1]=section2_details.image&populate[2]=section2_details.pdf_items.pdf&populate[3]=section3_details.image&populate[4]=section3_details.pdf_items.pdf&populate[5]=section4_details.image&populate[6]=section4_details.pdf_items.pdf&populate[7]=section5_details.image&populate[8]=section5_details.pdf_items`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Structural_sustainaibility = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-structuralshape-sustainaibility",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Structural_projectgallery_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-structuralshape-project?populate[0]=main_title&populate[1]=projectgallery_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Structural_projectgallery = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-structuralshape-project",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// -------------newly added for newletter section ------------//
export const get_Newshome_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subscribers?`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

// -------------SEARCH ------------//
export const get_Search_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `api/search-blocks/?populate[0]=desc `
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Search_ByID = async (postformdata, id, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      `api/search-blocks/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

// ---------ss table value for product subpage ------------//
export const get_SStable_Data = async () => {
  try {
    const response = await axiosInstance.get(`/api/ss-table-values?populate=*`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_SStable_product_ByID = async (
  postformdata,
  id,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      `/api/ss-table-values/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Save_SStable_product = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.post(
      "/api/ss-table-values",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Delete_SStable_product_ByID = async (id, userJwtToken) => {
  try {
    const response = await axiosInstance.delete(`/api/ss-table-values/${id}`);
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ---------ss table value master category for product subpage ------------//
export const get_SStablemcategory_Data = async () => {
  try {
    const response = await axiosInstance.get(`/api/ss-master-categories?`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_SStablemcategory_product = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      "api/ss-master-categories/1",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ---------ss table value master subcategory for product subpage ------------//
export const get_SStablemsubcategory_Data = async () => {
  try {
    const response = await axiosInstance.get(`/api/ss-master-subcategories?`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_SStablemsubcategory_product = async (
  postformdata,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.put(
      "/api/ss-master-subcategories/1",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

export const editImageAPI = async (formData, userJwtToken) => {
  const uploadResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: formData,
    }
  );
  return uploadResponse;
};
