import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Save_Structural_product,
  get_Structural_product_Data,
  get_SStable_Data,
  // Save_SStable_product,
  get_SStablemcategory_Data,
  Save_SStable_product_ByID,
  Save_SStable_product,
  get_SStablemsubcategory_Data,
  Save_SStablemcategory_product,
  Save_SStablemsubcategory_product,
  Delete_SStable_product_ByID,
} from "../../api/strucshapesApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message, Spin, InputNumber } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs, Table, Tag, Select, Modal, Tooltip } from "antd";
import { Col, Row } from "antd";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import { useNavigate } from "react-router-dom";

import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
import TypedInputNumber from "antd/es/input-number";
const { Header, Content, Footer } = Layout;

function Product() {
  const [ProductformData, setProductformData] = useState({});
  const [section4formData, setSection4formData] = useState({});
  const [section4form] = Form.useForm(); // Using Ant Design Form seo
  const section4formRef = useRef();
  const [productform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const productformRef = useRef();
  // ------------------------------------------------------ //
  const [Section2formData, setSection2formData] = useState({});
  const [section2form] = Form.useForm(); // Using Ant Design Form seo
  const section2formRef = useRef();

  const [Section3formData, setSection3formData] = useState({});
  const [section3form] = Form.useForm(); // Using Ant Design Form seo
  const section3formRef = useRef();
  const [categories, setCategories] = useState([]);
  const [subcategories, setsubCategories] = useState([]);
  const [Section3tableformData, setSection3tableformData] = useState({});
  const [section3tableform] = Form.useForm(); // Using Ant Design Form seo
  const section3tableformRef = useRef();

  const [isDisabled, setIsDisabled] = useState(false);

  const handleCategoryChange = (value) => {
    if (value !== categories[0].id) {
      setIsDisabled(true);
      form.resetFields(["subcat_id"]);

      // for.resetFields()
    } else {
      setIsDisabled(false);
    }
  };

  const [intitialTableVal, SetinitialTablVal] = useState({
    key: 0,
    section: "",
    Weight: "",
    A: "",
    d: "",
    b: "",
    tw: "",
    bf: "",
    tf: "",
    // cat_id: 1,
    // A992: "",
    // A913_Gr_50add65: "",
    // A913_Gr_70: "",
    // A913_Gr_80: "",
    // A709: "",
    // A572_Gr_60: "",
    // A572_Gr_50: "",
    // A572_Gr_65: "",
    // A588: "",
  });

  const { Option } = Select;

  // ------------------------------------------------------- //
  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };
  const [isUploading, setIsUploading] = useState(false);

  // function for handling image change for sustainaibility tab
  const handleaproductChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setProductformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          productformRef.current.setFieldsValue({
            [ProductformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  // ------------------------------------------------------- //
  // function for handling image change for xcarb tab
  const handlesection2Change = async (info, index) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setSection2formData((prevFields) => {
        const updatedFields = { ...prevFields };
        updatedFields.imagexcarb1Url = url;
        updatedFields.image = uploadResponse[0].id;
        return updatedFields;
      });
      section2formRef.current.setFieldsValue({
        [Section2formData.key]: { imagexcarb1Url: url }, // Update the existing_img_id field in the form
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  // ------------------------------------------------------- //

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);

    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_Structural_product_Data();
      const data = response.data;

      const productData = {
        title: response.data.data.attributes.section1_details.title,
        description: response.data.data.attributes.section1_details.description,
        page_url: response.data.data.attributes.section1_details.page_url,
        image: response.data.data.attributes.section1_details.image.data.id,
        existing_id: response.data.data.attributes.section1_details.id,
        imagesUrl:
          response.data.data.attributes.section1_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.section1_details.img_alt_txt,
      };
      // ------------------------------horizontal component data---------------------------- //
      const section2Data = {
        left_description:
          response.data.data.attributes.horizontal_block_product
            .left_description,
        right_description:
          response.data.data.attributes.horizontal_block_product
            .right_description,
      };

      const section4Data = {
        pdfname:
          response.data.data.attributes.pdf_item.pdf.data.attributes.name,
        attachment: response.data.data.attributes.pdf_item,
        title: response.data.data.attributes.pdf_item.title,
        // pdfUrl: response.data.data.attributes.pdf_item.pdf.attributes.url,
      };
      // ---------------------------------------------------------- //
      // ------------------------------section 3 head data---------------------------- //
      // const section3headData = {
      //   title: response.data.data.attributes.section3_details.title,
      // };
      // ---------------------------------------------------------- //
      //------------set section 1 data--------------
      setProductformData(productData);
      productform.setFieldsValue(productData); // Set form fields value
      //------------set horizontal data--------------
      setSection2formData(section2Data);
      section2form.setFieldsValue(section2Data); // Set form fields value
      setSection4formData(section4Data);
      section4form.setFieldsValue(section4Data);
      //------------set section 3 head data--------------
      // setSection3formData(section3headData);
      // section3form.setFieldsValue(section3headData); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchtableData();
  }, []);

  const fetchtableData = async () => {
    try {
      const response = await get_SStable_Data();
      const data = response.data.data;

      // --------------------trial for table data------------------- //
      const section3tableData = data.map((item) => ({
        section: item.attributes.section,
        Weight: item.attributes.Weight,
        A: item.attributes.A,
        b: item.attributes.b,
        d: item.attributes.d,
        tw: item.attributes.tw,
        tf: item.attributes.tf,
        bf: item.attributes.bf,
        A992: item.attributes.A992,
        A913_Gr_50add65: item.attributes.A913_Gr_50add65,
        A913_Gr_70: item.attributes.A913_Gr_70,
        A913_Gr_80: item.attributes.A913_Gr_80,
        A709: item.attributes.A709,
        A588: item.attributes.A588,
        A572_Gr_60: item.attributes.A572_Gr_60,
        A572_Gr_50: item.attributes.A572_Gr_50,
        A572_Gr_65: item.attributes.A572_Gr_65,
        key: item.id,
        cat_id: item.attributes.master_category_id.data?.id,
        cat_name: item.attributes.master_category_id.data?.attributes.cat_name,
        subcat_id: item.attributes.master_subcategory.data?.id,
        subcat_name:
          item.attributes.master_subcategory.data?.attributes.subcat_name,
      }));
      // ---------------------------------------------------------- //
      //------------set section 3 table data--------------
      setSection3tableformData(section3tableData);
      section3tableform.setFieldsValue(section3tableData);
      setTableData(section3tableData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchtablemcategoryData();
  }, []);

  const fetchtablemcategoryData = async () => {
    try {
      const response = await get_SStablemcategory_Data();
      setCategories(response.data.data);
      const data = response.data;

      // --------------------trial for table data------------------- //
      const section3tablecatData = data.map((item) => ({
        cat_name: item.attributes.cat_name,
      }));
      // {
      //   cat_name: response.data.data[0].attributes.cat_name,
      // };
      // ---------------------------------------------------------- //
      //------------set section 3 table data--------------
      // setSection3tableformData(section3tablecatData);
      section3tableform.setFieldsValue(section3tablecatData);
      setTableData(section3tablecatData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchtablemsubcategoryData();
  }, []);

  const fetchtablemsubcategoryData = async () => {
    try {
      const response = await get_SStablemsubcategory_Data();
      const data = response.data;
      setsubCategories(response.data.data);

      // --------------------trial for table data------------------- //
      const section3tablesubcatData = {
        subcat_name: response.data.data[0].attributes.subcat_name,
      };

      // ---------------------------------------------------------- //
      //------------set section 3 table data--------------
      setSection3tableformData(section3tablesubcatData);
      section3tableform.setFieldsValue(section3tablesubcatData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProductInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setProductformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleXcarb1InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSection2formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  const handleSection4InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSection4formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleSection3InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSection3formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  const [isSpinVisible, setIsSpinVisible] = useState(false);
  const navigate = useNavigate();

  const productsave = async (index) => {
    setIsSpinVisible(true);

    try {
      await productform.validateFields();
      const data = {
        data: {
          section1_details: {
            description: ProductformData.description,
            page_url: ProductformData.page_url,
            title: ProductformData.title,
            image: ProductformData.image,
            existing_id: ProductformData.existing_id,
            imagesUrl: ProductformData.imagesUrl,
            img_alt_txt: ProductformData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Structural_product(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsSpinVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsSpinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsSpinVisible(false);
    }
  };
  // --------------------------------------------------- //
  const horisave = async (index) => {
    setIsSpinVisible(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          horizontal_block_product: {
            left_description: Section2formData.left_description,
            right_description: Section2formData.right_description,
          },
        },
      };

      try {
        Save_Structural_product(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsSpinVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsSpinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsSpinVisible(false);
    }
  };

  const section3tablesave = async (index, id) => {
    setIsSpinVisible(true);
    try {
      await section3form.validateFields();
      const data = {
        data: {
          section: Section3formData.section,
          Weight: Section3formData.Weight,
          A: Section3formData.A,
          b: Section3formData.b,
          d: Section3formData.d,
          tw: Section3formData.tw,
          tf: Section3formData.tf,
          bf: Section3formData.bf,
          A992: Section3formData.A992,
          A913_Gr_50add65: Section3formData.A913_Gr_50add65,
          A913_Gr_70: Section3formData.A913_Gr_70,
          A913_Gr_80: Section3formData.A913_Gr_80,
          A709: Section3formData.A709,
          A588: Section3formData.A588,
          A572_Gr_60: Section3formData.A572_Gr_60,
          A572_Gr_50: Section3formData.A572_Gr_50,
          A572_Gr_65: Section3formData.A572_Gr_65,
          cat_name: Section3formData.cat_name,
          subcat_name: Section3formData.subcat_name,
          id: id,
        },
      };
      try {
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
    }
    try {
      await section3form.validateFields();
      const data = {
        data: {
          cat_name: Section3formData.cat_name,
          subcat_name: Section3formData.subcat_name,
        },
      };
      try {
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
    }
    try {
      await section3form.validateFields();
      const data = {
        data: {
          cat_name: Section3formData.cat_name,
          subcat_name: Section3formData.subcat_name,
        },
      };
      try {
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsSpinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsSpinVisible(false);
    }
    try {
      await section3form.validateFields();
      const data = {
        data: {
          cat_name: Section3formData.cat_name,
          subcat_name: Section3formData.subcat_name,
        },
      };

      try {
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsSpinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsSpinVisible(false);
    }
    try {
      await section3form.validateFields();
      const data = {
        data: {
          cat_name: Section3formData.cat_name,
          subcat_name: Section3formData.subcat_name,
        },
      };

      try {
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsSpinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsSpinVisible(false);
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDelete = (key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk: async () => {
        setTableData((prev) => prev.filter((item) => item.key !== key));
        await Delete_SStable_product_ByID(key, userJwtToken);

        message.success("Record deleted successfully");
      },
      onCancel: () => {
        message.info("Delete operation cancelled");
      },
      okText: "Yes",
      cancelText: "No",
    });
  };
  const Dropdownoptions = [
    { name: "Yes", value: "true" },
    { name: "No", value: "false" },
    { name: "Not Applicable", value: "" },
  ];
  const handleEdit = (record) => {
    // setIsEditing(true);
    const mappedRecord = {
      key: record.key,
      section: record.section,
      Weight: record.Weight,
      A: record.A,
      d: record.d,
      // b: record.b,
      tw: record.tw,
      bf: record.bf,
      tf: record.tf,
      A992: record.A992,
      A913_Gr_50add65: record.A913_Gr_50add65,
      A913_Gr_70: record.A913_Gr_70,
      A913_Gr_80: record.A913_Gr_80,
      A709: record.A709,
      A572_Gr_60: record.A572_Gr_60,
      A572_Gr_50: record.A572_Gr_50,
      A572_Gr_65: record.A572_Gr_65,
      A588: record.A588,
      cat_id: record.cat_id,
      // cat_name: item.attributes.master_category_id.data?.attributes.cat_name,
      subcat_id: record.subcat_id,
      // subcat_name:
      // item.attributes.master_subcategory.data?.attributes.subcat_name,
    };
    // section3tableform.setFieldsValue(mappedRecord);
    if (record.cat_id === categories[0].id) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      form.resetFields(["subcat_id"]);
    }
    form.setFieldsValue(mappedRecord);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "Category",
      dataIndex: "cat_name",
      key: "cat_name",
      sorter: (a, b) => a.cat_name.localeCompare(b.cat_name),
    },
    {
      title: "Sub Category",
      dataIndex: "subcat_name",
      key: "subcat_name",
      sorter: (a, b) => a.subcat_name.localeCompare(b.subcat_name),
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      sorter: (a, b) => a.section.localeCompare(b.section),

      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Weight lb/ft",
      dataIndex: "Weight",
      key: "Weight",
    },
    {
      title: "A in.2",
      dataIndex: "A",
      key: "A",
    },
    {
      title: "d in.2",
      dataIndex: "d",
      key: "d",
    },
    // {
    //   title: "b in.2",
    //   dataIndex: "b",
    //   key: "b",
    // },
    {
      title: "tw in.2",
      dataIndex: "tw",
      key: "tw",
    },
    {
      title: "bf in.2",
      dataIndex: "bf",
      key: "bf",
    },
    {
      title: "tf in.2",
      dataIndex: "tf",
      key: "tf",
    },
    {
      title: "A992",
      dataIndex: "A992",
      key: "A992",
    },
    {
      title: "A913 gR 50+65",
      dataIndex: "A913_Gr_50add65",
      key: "A913_Gr_50add65",
    },
    {
      title: "A913 gR 70",
      dataIndex: "A913_Gr_70",
      key: "A913_Gr_70",
    },
    {
      title: "A913 gR 80",
      dataIndex: "A913_Gr_80",
      key: "A913_Gr_80",
    },
    {
      title: "A709",
      dataIndex: "A709",
      key: "A709",
    },
    {
      title: "A572 Gr 60",
      dataIndex: "A572_Gr_60",
      key: "A572_Gr_60",
    },
    {
      title: "A572 Gr 50",
      dataIndex: "A572_Gr_50",
      key: "A572_Gr_50",
    },
    {
      title: "A572 Gr 65",
      dataIndex: "A572_Gr_65",
      key: "A572_Gr_65",
    },
    {
      title: "A588",
      dataIndex: "A588",
      key: "A588",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a onClick={() => setIsEditing(true)}>Edit</a> */}
          <a onClick={() => handleEdit(record)}>Edit</a>
          <a onClick={() => handleDelete(record.key)}>Delete</a>
        </Space>
      ),
    },
  ];
  const data = [
    // {
    //   key: "1",
    //   section: "John Brown",
    //   category: "New York No. 1 Lake Park",
    //   // tags: ["nice", "developer"],
    // },
    // {
    //   key: "2",
    //   section: "Jim Green",
    //   // page_url: "London No. 1 Lake Park",
    // },
    // {
    //   key: "3",
    //   page_title: "Joe Black",
    //   page_url: "Sydney No. 1 Lake Park",
    // },
  ];
  const [tableData, setTableData] = useState(data);
  const handleAddFields = () => {
    form.resetFields();
    // setIsEditing("editing");
    setIsModalVisible(true);

    section3tableform.resetFields();
    // setEditingKey(null);
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const mappedRecord = {
      key: values.key,
      section: values.section,
      Weight: values.Weight ? values.Weight : "",
      A: values.A ? values.A : "",
      d: values.d ? values.d : "",
      b: values.b ? values.b : "",
      tw: values.tw ? values.tw : "",
      bf: values.bf ? values.bf : "",
      tf: values.tf ? values.tf : "",
      A992: values.A992 ? values.A992 : "",
      A913_Gr_50add65: values.A913_Gr_50add65,
      A913_Gr_70: values.A913_Gr_70,
      A913_Gr_80: values.A913_Gr_80,
      A709: values.A709,
      A572_Gr_60: values.A572_Gr_60,
      A572_Gr_50: values.A572_Gr_50,
      A572_Gr_65: values.A572_Gr_65,
      A588: values.A588,
      master_category_id: values.cat_id ? values.cat_id : null,
      master_subcategory: values.subcat_id ? values.subcat_id : null,
    };
    let data = {
      data: mappedRecord,
    };

    try {
      if (values.key === 0 || values.key === undefined) {
        try {
          Save_SStable_product(data, userJwtToken)
            .then(async (response) => {
              await fetchData();
              await fetchtableData();
              setIsModalVisible(false);
            })
            .catch((err) => {
            });
        } catch (error) {}
      } else {
        try {
          Save_SStable_product_ByID(data, values.key, userJwtToken)
            .then(async (response) => {
              await fetchData();
              await fetchtableData();
              setIsModalVisible(false);
            })
            .catch((err) => {
            });
        } catch (error) {}
      }
    } catch (error) {}
  };
  const handleFileChange = async (e) => {
    setIsSpinVisible(true);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      setSection4formData((prevFields) => {
        const updatedFields = { ...prevFields };
        // updatedFields[index].imageUrl = url;
        updatedFields.pdfname = uploadResponse[0].name;
        updatedFields.attachment = {
          title: "pdf item",
          pdf: uploadResponse[0].id,
        };
        return updatedFields;
      });
      setIsSpinVisible(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsSpinVisible(false);
    } finally {
    }
  };

  const section4save = async (index) => {
    setIsSpinVisible(true);
    let pdfarr = section4formData.attachment.pdf.data
      ? section4formData.attachment.pdf.data.id
      : section4formData.attachment.pdf;
    try {
      await section4form.validateFields();
      const data = {
        data: {
          pdf_item: {
            title: section4formData.title,
            pdf: pdfarr,
          },
        },
      };
      try {
        Save_Structural_product(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsSpinVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsSpinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsSpinVisible(false);
    }
  };
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            {/* <h1>Products and Size Range</h1> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/structuralshapes")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>Products and Size Range</h1>
            </div>
          </div>
          <Row>
            <Col span={24}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="Section 1 details" key="1">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols</b> and numbers to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="section1fencing"
                    layout="vertical"
                    form={productform}
                    initialValues={ProductformData}
                    onValuesChange={handleProductInputChange}
                    onFinish={onFinish}
                    ref={productformRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={4}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={ProductformData.description}
                            onChange={(content, delta, source, editor) =>
                              handleProductInputChange({
                                description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an Image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={ProductformData.imagesUrl}
                                  imageUrl={ProductformData.imagesUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handleaproductChange(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please input Alt/Title Text!",
                          //   }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => productsave(0)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => productsave(1)}>
                            Save & Continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isSpinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Horizontal block details" key="2">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        <b>400 Characters</b> max, including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                        <b>'Left Description'</b> and <b>'Right Description'</b>{" "}
                        fields is recommended.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="xcarb1"
                    layout="vertical"
                    form={section2form}
                    initialValues={Section2formData}
                    onValuesChange={handleXcarb1InputChange}
                    onFinish={onFinish}
                    ref={section2formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Left Description"
                          name="left_description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Left Description",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Section2formData.left_description}
                            onChange={(content, delta, source, editor) =>
                              handleXcarb1InputChange({
                                left_description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label=" Right Description"
                          name="right_description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Right Description",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Section2formData.right_description}
                            onChange={(content, delta, source, editor) =>
                              handleXcarb1InputChange({
                                right_description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => horisave(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => horisave(1)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => horisave(2)}>
                            Save & Continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isSpinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Section 3 details" key="3">
                  <Button
                    type="primary"
                    className="add-fields"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={handleAddFields}
                  />
                  <div style={{ overflowX: "auto" }}>
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      // dataSource={Section3tableformData}
                    />
                  </div>

                  {/* <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input title",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row> */}

                  <Modal
                    title="Edit Record"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    // footer={[
                    //   <Button key="back" onClick={handleCancel}>
                    //     Cancel
                    //   </Button>,
                    //   <Button
                    //     key="submit"
                    //     type="primary"
                    //     onClick={() => section3tablesave(1)}
                    //   >
                    //     Save
                    //   </Button>,
                    // ]}
                    footer={null}
                  >
                    <Form
                      form={form}
                      name="basic"
                      initialValues={intitialTableVal}
                      onFinish={onFinish}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <Row>
                        <Col span={8} style={{ display: "none" }}>
                          <Form.Item
                            name="key"
                            label="Section Title"
                            rules={[
                              {
                                required: true,
                                message: "Please input Section title!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="cat_id"
                            label="Category"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select an option"
                              onChange={handleCategoryChange}
                            >
                              {categories.map((category, index) => (
                                <Option key={index} value={category.id}>
                                  {category.attributes.cat_name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item name="subcat_id" label="Sub Category">
                            <Select
                              disabled={isDisabled}
                              placeholder="Select an option"
                            >
                              {subcategories.map((category, index) => (
                                <Select.Option key={index} value={category.id}>
                                  {category.attributes.subcat_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="section"
                            label="Section Title"
                            rules={[
                              {
                                required: true,
                                message: "Please input Section title!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={6}>
                          <Form.Item name="Weight" label="Weight">
                            {/* <TypedInputNumber
                              style={{ width: "100%" }}
                              // parser={(value) => value.replace(/\D/g, "")}
                              controls={false} // Hide the up/down arrows
                              onKeyPress={handleKeyPress}
                            /> */}
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item name="A" label="A">
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name="d" label="d">
                            <Input />
                          </Form.Item>
                        </Col>
                        {/* <Col span={6}>
                          <Form.Item name="b" label="b">
                            <Input />
                          </Form.Item>
                        </Col> */}
                        <Col span={6}>
                          <Form.Item name="tw" label="tw">
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name="bf" label="bf">
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name="tf" label="tf">
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <h4>Availability:</h4>

                      <Row>
                        <Col span={8}>
                          <Form.Item
                            name="A992"
                            label="A992"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="A913_Gr_50add65"
                            label="A913_Gr_50add65"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            name="A913_Gr_70"
                            label="A913_Gr_70"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            {/* <Input /> */}
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="A913_Gr_80"
                            label="A913_Gr_80"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="A709"
                            label="A709"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="A572_Gr_60"
                            label="A572_Gr_60"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="A572_Gr_50"
                            label="A572_Gr_50"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            name="A572_Gr_65"
                            label="A572_Gr_65"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="A588"
                            label="A588"
                            rules={[
                              {
                                required: true,
                                message: "Please select an option!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an option">
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                              <Option value="null">Not Applicable</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* 
                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item> */}

                      <div
                        // className="savebtns"
                        style={{
                          marginTop: 20,
                          alignItems: "flex-end",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Space>
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={() => handleCancel()}
                            >
                              Cancel
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                        </Space>
                      </div>
                    </Form>
                  </Modal>

                  {/* <Row>
                    <Col className="col-pad" span={12}>
                      {!isEditing && (
                        <Button
                          type="primary"
                          className="add-fields"
                          shape="circle"
                          icon={<PlusOutlined />}
                          onClick={handleAddFields}
                        />
                      )}
                    </Col>
                  </Row> */}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Section 4 details" key="4">
                  <Form
                    name="section4"
                    layout="vertical"
                    form={section4form}
                    initialValues={section4formData}
                    onValuesChange={handleSection4InputChange}
                    onFinish={onFinish}
                    ref={section4formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      {/*}    <Col span={3}>
                        <Form.Item
                          label="Field"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      */}
                      <Col span={6}>
                        <Form.Item
                          label="Pdf"
                          name="pdfname"
                          // initialValue={field.pdfid} // initialValue={}
                          rules={[
                            {
                              required: true,
                              message: "Please upload Attachment!",
                            },
                          ]}
                        >
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange(e)}
                              // onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="xcarb1pdfupload"
                            />
                            <label>
                              <Button
                                className="btn btn-primary"
                                onClick={() =>
                                  // triggerFileInput(index)
                                  document
                                    .getElementById("xcarb1pdfupload")
                                    .click()
                                }
                              >
                                Upload PDF
                              </Button>
                            </label>

                            <div>
                              {section4formData.pdfname ? (
                                <Tooltip title={section4formData.pdfname}>
                                  <p>File: {section4formData.pdfname}</p>
                                </Tooltip>
                              ) : (
                                "No File Selected"
                              )}
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => section4save(0)}
                          >
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => section4save(1)}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isSpinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Product;
