
import axios from "axios";
// Create Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Dev API URL
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const get_wiresolns_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-wire-solution?populate[0]=seo&populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=contact_us.contact_detail.logo &populate[4]=contact_us1_fields.logo&populate&populate[5]=contact_us2_fields.logo&populate[6]=pdf_upload.pdf`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_wiresolns = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/page-wire-solution",
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const uploadImageAPI = async (formData, userJwtToken) => {
  const uploadResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: formData,
    }
  );
  return uploadResponse;
};

export const postresponseAPI = async (postformdata, userJwtToken) => {
  const postresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/page-home`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: postformdata, // Convert data to JSON string and send in the request body
    }
  );
  return postresponse;
};

export const deleteimageAPI = async (imageId, userJwtToken) => {
  const deleteresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload/files/${imageId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
    }
  );
  return deleteresponse;
};

// login.js
export const loginAPI = async (email, password) => {
  const loginresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/local`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    }
  );
  return loginresponse;
};

export const uploadPdfAPI = async (formData, userJwtToken, file) => {
  try {
    // const formData = new FormData();
    formData.append("file", file);
    const response = await axiosInstance.post(`/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userJwtToken}`,
      },
    });
    // Handle the response here
  } catch (error) {
    console.error("Error uploading file:", error);
    // Handle error
  }
};


// ------------------------------------------//
export const get_Wire_steel_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-wiresol-steel?populate[0]=section1_details.image&populate[1]=section2_details.image&populate[2]=section3_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Wire_steel = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-wiresol-steel",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Wire_fencing_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-wiresol-fencing?populate[0]=fencing_details.image&populate[1]=horizontal_block_fencing`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Wire_fencing = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-wiresol-fencing",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//

// ------------------------------------------//
export const get_Wire_wirerope_Data = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/subpage-wiresol-wirerope?populate[0]=section1_details.image&populate[1]=horizontal_content&populate[2]=wiresol_accord&populate[3]=section2_details.image`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Wire_wirerope = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      "/api/subpage-wiresol-wirerope",
      postformdata
    );
    return response.data;
  } catch (error) {}
};
// ------------------------------------------//
