// import Quill from "quill";

// const Link = Quill.import("formats/link");

// class CustomLink extends Link {
//   static create(value) {
//     let node = super.create(value);
//     node.removeAttribute("target");
//     node.removeAttribute("rel");
//     node.setAttribute("href", value);

//     return node;
//   }

//   static formats(domNode) {
//     return domNode.getAttribute("href");
//   }
// }

// Quill.register(CustomLink, true);

import Quill from "quill";

const Link = Quill.import("formats/link");

class CustomLink extends Link {
  static create(value) {
    let node = super.create(value);
    node.removeAttribute("target");
    node.removeAttribute("rel");
    node.setAttribute("href", value);

    // Check if the URL contains a '#'
    if (value.includes("#")) {
      node.setAttribute("target", "_self");
    } else {
      node.setAttribute("target", "_blank");
    }

    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("href");
  }
}

Quill.register(CustomLink, true);
