import React from "react";
import { Layout, Row, Col, Divider } from "antd";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
function Dashboard() {
  return (
    <>
      <Layout>
        <Headercomp />
        <div className="body-pad">
          <Row>
            <Col span={24}>
              <div className="main-head">
                <h1>Dashboard</h1>
              </div>
            </Col>
          </Row>
          <div className="dashlinks">
            <Row>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/home">Home</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/structuralshapes">Structural Shapes</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/barsnrods">Bars and Rods</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/flats">Flats</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/logistics">Logistics</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/wiresolns">Wire Solutions</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/news">News</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/subscribers">Subscribers</Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="card-area">
                  <Link to="/admin/social">Social</Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Dashboard;
