import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Save_Flats,
  get_Flats_Data,
  get_Flats_Subpages,
  subpageupdate,
} from "../api/flatsApi";
import { Button, Form, Input, Space, Select, Menu } from "antd";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Upload, message } from "antd";
import UserData from "./UserData";
// // home
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { uploadImageAPI } from "../api/bannerApi";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import { Save_Search_ByID, get_Search_Data } from "../api/strucshapesApi";

const { Header, Content, Footer } = Layout;

const MyForm = () => {
  const navigate = useNavigate();

  const [SubpageData, setSubpageData] = useState([]);
  const [SeoformData, setSeoFormData] = useState({});
  const [BannerformData, setBannerFormData] = useState([]);
  const [BusinessformData, setBusinessFormData] = useState([]);
  const [SustainaibilityformData, setSustainabilityFormData] = useState([]);
  const [ContactUs1Data, setContactUs1Data] = useState([]);
  const [ContactUs2Data, setContactUs2Data] = useState([]);
  const [ContactUs1, setContactUs1] = useState("");
  const [ContactUs2, setContactUs2] = useState("");

  const [form] = Form.useForm(); // Using Ant Design Form seo
  const [Bannerform] = Form.useForm(); // Using Ant Design Form
  const [Businessform] = Form.useForm(); // Using Ant Design Form
  const [Sustainaibilityform] = Form.useForm(); // Using Ant Design Form
  const [ContactUs1form] = Form.useForm(); // Using Ant Design Form
  const [ContactUs2form] = Form.useForm(); // Using Ant Design Form
  const { TextArea } = Input;
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");
  const [pendingTab, setPendingTab] = useState(null);
  const formRef = useRef();
  const userJwtToken = localStorage.getItem("Token");
  const SeoformRef = useRef();
  const BannerformRef = useRef();
  const BusinessformRef = useRef();
  const SustainaibilityformRef = useRef();
  const { Option } = Select;
  const [Searchdata, Setsearchdata] = useState([]);

  const [formSaved, setFormSaved] = useState(false); // State to track whether form has been saved
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const handlecontactTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActivecontactTab(key);
    }
  };
  //editor for banner tab
  function handleQuillChangeba(content, key, field) {
    // Assuming BannerformData is part of your component's state
    setBannerFormData((prevFields) => {
      // Find the field that needs to be updated
      const fieldIndex = prevFields.findIndex((f) => f.key === key);

      // Make a copy of the previous fields
      const newFields = [...prevFields];

      // Update the specific field
      newFields[fieldIndex] = {
        ...newFields[fieldIndex],
        [field]: content,
      };

      // Return the updated fields
      return newFields;
    });
  }

  const handle_upload_img_for_contact2 = async (info, index) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setContactUs2Data((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].imageUrl = url;
        updatedFields[index].logo = uploadResponse[0].id;

        return updatedFields;
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  const [isUploading, setIsUploading] = useState(
    BannerformData.map(() => false)
  );
  const handleChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        setIsUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBannerFormData((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].imageUrl = url;
            updatedFields[index].main_img = uploadResponse[0].id;

            return updatedFields;
          });
          // Update the form values with the new imageUrl
          BannerformRef.current.setFieldsValue({
            [BannerformData[index].key]: { imageUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading((prevState) => {
            // Stop loading when the upload is done
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const [islineUploading, setIslineUploading] = useState(
    BusinessformData.map(() => false)
  );
  // function for handling image chage change for business line
  const handlebuChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        setIslineUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBusinessFormData((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].imageLink = url;
            updatedFields[index].bg_img = uploadResponse[0].id;

            return updatedFields;
          });
          // Update the form values with the new imageUrl
          BusinessformRef.current.setFieldsValue({
            [BusinessformData[index].key]: { imageLink: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIslineUploading((prevState) => {
            // Stop loading when the upload is done
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const [issuUploading, setIssuUploading] = useState(false);
  // function for handling image change for sustainaibility tab
  const handlesuChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        setIssuUploading(true);
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setSustainabilityFormData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.side_img = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          SustainaibilityformRef.current.setFieldsValue({
            [SustainaibilityformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIssuUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handleDeleteBannerFormField = (key) => {
    const index = BannerformData.findIndex((field) => field.key === key);
    if (index > -1) {
      const existingImgId = BannerformData[index].existing_img_id;
      const currentDeleteImgId =
        BannerformRef.current.getFieldValue("delete_img_id");
      BannerformRef.current.setFieldsValue({
        delete_img_id: currentDeleteImgId
          ? `${currentDeleteImgId}, ${existingImgId}`
          : `${existingImgId},`,
      });
      const newBannerformData = [...BannerformData];
      newBannerformData.splice(index, 1);
    }
  };

  // function for deleting business line form field
  const handleDeleteBusinessFormField = (key) => {
    const index = BusinessformData.findIndex((field) => field.key === key);
    if (index > -1) {
      const existingImgId = BusinessformData[index].existing_img_id;
      const currentDeleteImgId =
        BusinessformRef.current.getFieldValue("delete_img_id");
      BusinessformRef.current.setFieldsValue({
        delete_img_id: currentDeleteImgId
          ? `${currentDeleteImgId}, ${existingImgId}`
          : `${existingImgId},`,
      });
      const newBusinessformData = [...BusinessformData];
      newBusinessformData.splice(index, 1);
      setBusinessFormData(newBusinessformData);
    }
  };

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const seosave = async (index) => {
    setIsModalVisible(true);

    try {
      await form.validateFields();
      const data = {
        data: {
          seo: {
            meta_description: SeoformData.meta_description,
            meta_keywords: SeoformData.meta_keywords,
            page_title: SeoformData.page_title,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Flats(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  // save button for banner
  const handleBannerFinish = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await Bannerform.validateFields();
      const data = {
        data: {
          banner_section: [
            {
              id: BannerformData[0].existing_id,
              title: BannerformData[0].title,
              navigator_text: BannerformData[0].navigator_text,
              main_img: BannerformData[0].main_img,
              img_alt_title: BannerformData[0].img_alt_title,
              // id: values.existing_id,
            },
          ],
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[0].text = BannerformData[0].title;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };

      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Flats(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              // If index is 1, proceed to the next tab
              setFormEdited(false);
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the next tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };

  const CompareBusninesslineWithSubpage = async (BusniArr, SubpArr) => {
    BusniArr.forEach((item1) => {
      SubpArr.forEach((item2) => {
        if (item1.url === item2.attributes.page_url) {
          if (item1.title_text !== item2.attributes.page_title) {
            const data = {
              data: {
                id: item2.id,
                page_title: item1.title_text,
              },
            };
            try {
              subpageupdate(item2.id, data)
                .then((response1) => {
                  get_Flats_Subpages()
                    .then((response) => {
                      const subpage_data = response.data.data;
                      setSubpageData(
                        subpage_data["0"].attributes.master_subpages.data
                      );
                    })
                    .catch((err) => {});
                })
                .catch((err) => {});
            } catch (error) {}
          }
        }
      });
    });
  };
  // save button for business line handleBusinessfinish
  const handleBusinessfinish = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await Businessform.validateFields();
      const data = {
        data: {
          businessline_block_item: BusinessformData.map((item) => ({
            id: item.existing_id,
            url: item.url,
            title_text: item.title_text,
            bg_img: item.bg_img,
            order_no: item.order_no,
            img_alt_title: item.img_alt_title,
          })),
        },
      };

      try {
        await CompareBusninesslineWithSubpage(BusinessformData, SubpageData);

        Save_Flats(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              // If index is 1, proceed to the next tab
              setFormEdited(false);
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the next tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };

  // save button for sustainability tab
  const sustainaibilitysave = async (index) => {
    setIsModalVisible(true);

    try {
      await Sustainaibilityform.validateFields();
      const data = {
        data: {
          sustainaibility: {
            sustainaibility_heading:
              SustainaibilityformData.sustainaibility_heading,
            side_img: SustainaibilityformData.side_img,
            existing_id: SustainaibilityformData.existing_id,
            imagesUrl: SustainaibilityformData.imagesUrl,
            side_img_alt_txt: SustainaibilityformData.side_img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[1].text =
        SustainaibilityformData.sustainaibility_heading;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});

        Save_Flats(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the prev tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const contact1_save = async (index) => {
    setIsModalVisible(true);

    try {
      await ContactUs1form.validateFields();

      let setarr = ContactUs1Data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          imageUrl: item.imageUrl,
          contact_no: item.contact_no,
          address: item.address,
          country_code: item.country_code,
        };
      });
      const data = {
        data: {
          Contact_us1: ContactUs1,
          contact_us1_fields: [...setarr],
        },
      };

      try {
        Save_Flats(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);
              if (index === 2) {
                setActivecontactTab("2");
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const contact2_save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await ContactUs2form.validateFields();

      let setarr = ContactUs2Data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          imageUrl: item.imageUrl,
          contact_no: item.contact_no,
          address: item.address,
          country_code: item.country_code,
        };
      });
      const data = {
        data: {
          Contact_us2: ContactUs2,
          contact_us2_fields: [...setarr],
        },
      };

      try {
        Save_Flats(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              setFormEdited(false);
              if (index === 0) {
                setActivecontactTab("1");
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };
  const removecontact1 = (item_index) => {
    let newarr = ContactUs1Data.filter((_, index) => index !== item_index);
    setContactUs1Data(newarr);
  };
  const removecontact2 = (item_index) => {
    let newarr = ContactUs2Data.filter((_, index) => index !== item_index);
    setContactUs2Data(newarr);
  };
  const handleAddContact1FormField = () => {
    const maxKey = Math.max(...ContactUs1Data.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setContactUs1Data([
      ...ContactUs1Data,
      {
        key: newKey,
        name: "",
        role: "",
        email: "",
        imageUrl: "",
        logo: "",
        contact_no: "",
        address: "",
      },
    ]);
  };
  const handleAddContact2FormField = () => {
    const maxKey = Math.max(...ContactUs2Data.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setContactUs2Data([
      ...ContactUs2Data,
      {
        key: newKey,
        name: "",
        role: "",
        email: "",
        imageUrl: "",
        logo: "",
        contact_no: "",
        address: "",
      },
    ]);
  };

  useEffect(() => {
    fetchData();
    Search_Data();
  }, []);

  const Search_Data = async () => {
    const response = await get_Search_Data();
    const data = response.data.data;

    let barnsnrodsdata = data.filter(
      (item) => item.attributes.title === "Flats"
    );
    Setsearchdata(barnsnrodsdata);
  };

  const fetchData = async () => {
    try {
      const response = await get_Flats_Data();
      const response1 = await get_Flats_Subpages();
      const data = response.data;
      const subpage_data = response1.data.data;

      setSubpageData(subpage_data["0"].attributes.master_subpages.data);

      const seoData = {
        meta_keywords: response.data.data.attributes.seo.meta_keywords,
        meta_description: response.data.data.attributes.seo.meta_description,
        page_title: response.data.data.attributes.seo.page_title,
      };
      const Bannerdata = response.data.data.attributes.banner_section;
      const newBannerFormFields = Bannerdata.map((item) => {
        return {
          key: item.id,
          order_no: item.order_no,
          title: item.title,
          navigator_text: item.navigator_text,
          imageUrl: item.main_img.data.attributes.formats.thumbnail.url,
          main_img: item.main_img.data.id,
          existing_id: item.id,
          img_alt_title: item.img_alt_title,
        };
      });

      const Businessdata =
        response.data.data.attributes.businessline_block_item;
      const newBusinessFormFields = Businessdata.map((item) => {
        return {
          key: item.id,
          order_no: item.order_no,
          url: item.url,
          title_text: item.title_text,
          imageLink: item.bg_img.data.attributes.formats.thumbnail.url,
          bg_img: item.bg_img.data.id,
          existing_id: item.id,
          img_alt_title: item.img_alt_title,
        };
      });

      // for sustainability tab

      const sustainData = {
        sustainaibility_heading:
          response.data.data.attributes.sustainaibility.sustainaibility_heading,
        side_img:
          response.data.data.attributes.sustainaibility.side_img.data.id,
        existing_id: response.data.data.attributes.sustainaibility.id,
        imagesUrl:
          response.data.data.attributes.sustainaibility.side_img.data.attributes
            .formats.thumbnail.url,
        side_img_alt_txt:
          response.data.data.attributes.sustainaibility.side_img_alt_txt,
      };

      const Contactusdatas = response.data.data.attributes.contact_us;

      const contact1data = response.data.data.attributes.contact_us1_fields;
      setContactUs1(response.data.data.attributes.Contact_us1);
      const contact1Formfields = contact1data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          // imageUrl: item.logo.data.attributes.formats.thumbnail.url,
          // logo: item.logo.data.id,
          contact_no: item.contact_no,
          address: item.address,
          country_code: item.country_code,
        };
      });

      const contact2data = response.data.data.attributes.contact_us2_fields;
      setContactUs2(response.data.data.attributes.Contact_us2);
      const contact2Formfields = contact2data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          // imageUrl: item.logo.data.attributes.formats.thumbnail.url,
          // logo: item.logo.data.id,
          contact_no: item.contact_no,
          address: item.address,
          country_code: item.country_code,
        };
      });

      //------------Set Contact tab ---------------------------------------

      setContactUs1Data(contact1Formfields);
      setContactUs2Data(contact2Formfields);
      //------------set SEO data--------------
      setSeoFormData(seoData);
      form.setFieldsValue(seoData); // Set form fields value

      //-----------set Banner data----------------
      setBannerFormData(newBannerFormFields);
      Bannerform.setFieldValue(newBannerFormFields);

      //-----------set Business data----------------
      setBusinessFormData(newBusinessFormFields);
      Businessform.setFieldValue(newBusinessFormFields);

      //-----------set Sustainability data----------------
      setSustainabilityFormData(sustainData);
      Sustainaibilityform.setFieldValue(sustainData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSeoInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSeoFormData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const bannerinputChange = (arrayIndex, field, value) => {
    let newBannerformData = [...BannerformData];
    newBannerformData[arrayIndex][field] = value;
    setBannerFormData(newBannerformData);
  };

  const businessinputChange = (arrayIndex, field, value) => {
    let newBusinessformData = [...BusinessformData];
    newBusinessformData[arrayIndex][field] = value;
    setBusinessFormData(newBusinessformData);
  };

  const handleSustainaibilityInputChange = (changedValues, allValues) => {
    setSustainabilityFormData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };
  const RedirecttoSubpage = (url, subpage) => {
    localStorage.setItem("Subpage", subpage);

    navigate(url);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //

  const handleLinkClick = (title) => {
    localStorage.setItem("Subpage", title);
  };
  return (
    <Layout>
      <Headercomp />
      <div className="innerbody-pad">
        <div className="main-head">
          <h1>Flats</h1>

          <ul className="sub-pages">
            {SubpageData.map((item) => {
              if (item.attributes.page_url === "agriculture") {
                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "appliances") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "automotive") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "building") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "construction") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "energy") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "packaging") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "transportation") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              } else if (item.attributes.page_url === "sustainaibility") {
                localStorage.setItem("Subpage", item.attributes.page_title);

                return (
                  <li>
                    <Link
                      to={`/admin/flats/${item.attributes.page_url}`}
                      onClick={() =>
                        handleLinkClick(item.attributes.page_title)
                      }
                    >
                      <a href="">{item.attributes.page_title}</a>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <Row>
          <Col span={24}>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <Tabs.TabPane tab="SEO" key="1">
                <Form
                  name="seo"
                  layout="vertical"
                  form={form}
                  initialValues={SeoformData}
                  onValuesChange={handleSeoInputChange}
                  onFinish={onFinish}
                  ref={SeoformRef}
                  onChange={() => setFormEdited(true)}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Page Title"
                        name="page_title"
                        rules={[
                          {
                            required: true,
                            message: "Please input Page Title!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Meta Description"
                        name="meta_description"
                        rules={[
                          {
                            required: true,
                            message: "Please input Meta Description!",
                          },
                        ]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Meta Keyword"
                        name="meta_keywords"
                        rules={[
                          {
                            required: true,
                            message: "Please input Meta Keyword!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Existing id" name="existing_id" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button type="primary" onClick={() => seosave(0)}>
                          Save
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={() => seosave(1)}>
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Banner" key="2">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      For the <b>'Banner Title'</b> please maintain{" "}
                      <b>20 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> to avoid
                      design break.
                    </li>
                    <li>
                      For the <b>'Banner Description'</b> please maintain{" "}
                      <b>500 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> to avoid
                      design break. Use <b>"Normal"</b> text for this field.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Banner Image'</b> to exact <b>1600 x 670 px</b> to
                      avoid design break. Please use file types: <b>jpg/png</b>{" "}
                      . Image size less than <b>800 kb</b> is recommended for
                      better performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  ref={BannerformRef} // Reference to the form for accessing form values
                  name="Banner"
                  layout="vertical"
                  form={Bannerform}
                  initialValues={BannerformData}
                  onChange={() => setFormEdited(true)}
                >
                  {BannerformData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      // Each row represents a banner form field
                      <Row key={field.key}>
                        <Col span={6}>
                          <Form.Item
                            label="Banner title"
                            name={[field.key, "navigator_text"]}
                            initialValue={field.navigator_text}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner title!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                bannerinputChange(
                                  index,
                                  "navigator_text",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            label="Existing image id"
                            name={[field.key, "existing_img_id"]}
                            initialValue={field.existing_img_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[field.key, "existing_id"]}
                            initialValue={field.existing_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            label="Banner Description"
                            name={[field.key, "title"]}
                            initialValue={field.title}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner Description!",
                              },
                            ]}
                          >
                            <ReactQuill
                              modules={modules}
                              value={field.title}
                              onChange={(content) => {
                                if (content !== field.title) {
                                  setFormEdited(true);
                                }
                                handleQuillChangeba(
                                  content,
                                  field.key,
                                  "title"
                                );
                                bannerinputChange(index, "title", content);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Main image"
                            name={[field.key, "imageUrl"]}
                            initialValue={field.imageUrl}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Main image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {" "}
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {" "}
                                {/* Image preview container */}
                                {isUploading[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <ImagePreview // Component to render image preview
                                    key={BannerformData[index].imageUrl}
                                    imageUrl={BannerformData[index].imageUrl}
                                  />
                                )}
                              </div>
                              {
                                <Upload
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                  onChange={(info) => handleChange(info, index)} // Pass index to handleChange
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "img_alt_title"]} // Name attribute for form field
                            initialValue={field.img_alt_title} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...BannerformData];
                              newData[index].img_alt_title = event.target.value;
                              setBannerFormData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {index > 0 && ( // Render delete button for all fields except the first one
                            <div className="del-btn">
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  handleDeleteBannerFormField(field.key)
                                }
                              ></Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col className="col-pad" span={12}>
                      <Form.Item
                        label="Deleting image id"
                        name={"delete_img_id"}
                        initialValue=""
                        hidden
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Subpage Blocks" key="3">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      Please avoid using Same <b>'Order No'</b>.
                    </li>
                    <li>
                      <b>30 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Subpage Block Title'</b> field is recommended.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Main image'</b> to exact{" "}
                      <b>400 x 550 px ( Aspect Ratio 8:11 )</b> to avoid design
                      break. Please use file types: <b>jpg/png</b> . Image size
                      less than <b>500 kb</b> is recommended for better
                      performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  ref={BusinessformRef} // Reference to the form for accessing form values
                  name="Business"
                  layout="vertical"
                  form={Businessform}
                  initialValues={BusinessformData}
                  // onValuesChange={handleSeoInputChange}
                  // onFinish={onFinish}
                  onChange={() => setFormEdited(true)}
                >
                  {BusinessformData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      <Row key={field.key}>
                        <Col span={2}>
                          <Form.Item
                            label="Seq no."
                            name={[field.key, "order_no"]} // Name attribute for form field
                            initialValue={field.order_no} // Initial value for the field
                            rules={[
                              {
                                required: true,
                                message: "Please input Seq no.!",
                              },
                              // {
                              //   pattern: /^[0-9]+$/,
                              //   message: "Please enter only numerical values!",
                              // },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const item = BusinessformData.find(
                                    (item, idx) =>
                                      idx !== index &&
                                      item.order_no === Number(value)
                                  ); // ignore the current item during the check

                                  if (!value || !item) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("This Seq no. already exists!")
                                  );
                                },
                              }),
                            ]}
                            onChange={(event) => {
                              const newData = [...BusinessformData];
                              newData[index].order_no = event.target.value;
                              setBusinessFormData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Subpage Block title"
                            name={[field.key, "title_text"]}
                            initialValue={field.title_text}
                            rules={[
                              {
                                required: true,
                                message: "Please input Subpage Block title!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                businessinputChange(
                                  index,
                                  "title_text",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Main image"
                            name={[field.key, "imageLink"]}
                            initialValue={field.imageLink}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Main image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {" "}
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {" "}
                                {/* Image preview container */}
                                {islineUploading[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <ImagePreview // Component to render image preview
                                    key={BusinessformData[index].imageLink}
                                    imageUrl={BusinessformData[index].imageLink}
                                  />
                                )}
                              </div>
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  onChange={(info) =>
                                    handlebuChange(info, index)
                                  } // Handle image upload change
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "img_alt_title"]} // Name attribute for form field
                            initialValue={field.img_alt_title} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...BusinessformData];
                              newData[index].img_alt_title = event.target.value;
                              setBusinessFormData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {index > 0 && ( // Render delete button for all fields except the first one
                            <div className="del-btn">
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  handleDeleteBusinessFormField(field.key)
                                }
                              ></Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col className="col-pad" span={12}>
                      <Form.Item
                        label="Deleting image id"
                        name={"delete_img_id"}
                        initialValue=""
                        hidden
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBusinessfinish(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBusinessfinish(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBusinessfinish(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Sustainability" key="4">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      <b>30 Characters max</b> including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Title'</b> field is recommended.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  ref={SustainaibilityformRef}
                  name="sustainaibility"
                  layout="vertical"
                  form={Sustainaibilityform}
                  initialValues={SustainaibilityformData}
                  onValuesChange={handleSustainaibilityInputChange}
                  onFinish={onFinish}
                  onChange={() => setFormEdited(true)}
                >
                  <Row>
                    <Col span={16}>
                      <Form.Item
                        label="Title"
                        name="sustainaibility_heading"
                        rules={[
                          {
                            required: true,
                            message: "Please input Title!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Side image"
                        name="side_img"
                        rules={[
                          {
                            required: true,
                            message: "Please input Side image!",
                          },
                        ]}
                      >
                        <div className="up-del">
                          {" "}
                          {/* Container for image upload and delete */}
                          <div className="img-preview">
                            {" "}
                            {/* Image preview container */}
                            {issuUploading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            ) : (
                              <ImagePreview // Component to render image preview
                                key={SustainaibilityformData.imagesUrl}
                                imageUrl={SustainaibilityformData.imagesUrl}
                              />
                            )}
                          </div>
                          {
                            <Upload // Upload component for image
                              name="avatar"
                              className="avatar-uploader"
                              showUploadList={false}
                              // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                              onChange={(info) => handlesuChange(info)} // Handle image upload change
                            >
                              <Button icon={<UploadOutlined />} />
                            </Upload>
                          }
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Alt/Title Text"
                        name="side_img_alt_txt" // Name attribute for form field
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Existing image id"
                        name="existing_img_id"
                        hidden
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="Existing id" name="existing_id" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => sustainaibilitysave(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => sustainaibilitysave(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => sustainaibilitysave(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Contact us" key="5">
                <Row>
                  <Col span={24}>
                    <Tabs
                      activeKey={activecontactTab}
                      onChange={handlecontactTabChange}
                    >
                      <Tabs.TabPane tab={ContactUs1} key="1">
                        <Form
                          name="Banner"
                          layout="vertical"
                          form={ContactUs1form}
                          initialValues={SeoformData}
                        >
                          <Row>
                            <Col span={23}>
                              <Form.Item
                                label="Department Name"
                                name="Tab_Name1"
                                initialValue={ContactUs1}
                                onChange={(event) => {
                                  // const newData = [...TechnicalassessmentData];
                                  // newData[index].technical_assessment_desc =
                                  //   event.target.value;
                                  setContactUs1(event.target.value);
                                  setFormEdited(true);
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Department Name!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          {ContactUs1Data.map(
                            (
                              field,
                              index // Map through banner form fields array
                            ) => (
                              // Each row represents a banner form field
                              <Row key={field.key}>
                                <Col span={4}>
                                  <Form.Item
                                    label="Name"
                                    name={[field.key, "name"]}
                                    initialValue={field.name}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].name = event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Name!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={5}>
                                  <Form.Item
                                    label="Role"
                                    name={[field.key, "role"]}
                                    initialValue={field.role}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].role = event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Role!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={4}>
                                  <Form.Item
                                    label="Email"
                                    name={[field.key, "email"]}
                                    initialValue={field.email}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].email = event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    label="Contact No"
                                    name={[field.key, "contact_no"]}
                                    initialValue={field.contact_no}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].contact_no =
                                        event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={4}>
                                  <Form.Item
                                    label="Select Country"
                                    name={[field.key, ""]}
                                  >
                                    <ReactFlagsSelect
                                      selected={
                                        field.country_code
                                          ? field.country_code
                                          : ""
                                      }
                                      onSelect={(code) => {
                                        const newData = [...ContactUs1Data];
                                        newData[index].country_code = code;
                                        setContactUs1Data(newData);
                                        setFormEdited(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    label="Location"
                                    name={[field.key, "address"]}
                                    initialValue={field.address}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].address =
                                        event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <TextArea />
                                  </Form.Item>
                                </Col>

                                <Col span={1}>
                                  {index > 0 && ( // Render delete button for all fields except the first one
                                    <div className="del-btn">
                                      <Button
                                        danger
                                        icon={<DeleteOutlined />}
                                        onClick={() => removecontact1(index)}
                                      ></Button>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}

                          <Row>
                            <Col className="col-pad" span={12}>
                              <Button
                                type="primary"
                                className="add-fields"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={handleAddContact1FormField}
                              />
                            </Col>
                            <Col className="col-pad" span={12}>
                              <Form.Item
                                label="Deleting image id"
                                name={"delete_img_id"}
                                initialValue=""
                                hidden
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-pad" span={12}>
                              <Form.Item
                                label="Deleting image id"
                                name={"delete_img_id"}
                                initialValue=""
                                hidden
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className="savebtns">
                            <Space>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={() => contact1_save(1)}
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </Space>
                            <Space>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={() => contact1_save(2)}
                                >
                                  Save & continue
                                </Button>
                              </Form.Item>
                            </Space>
                          </div>
                        </Form>
                        {isModalVisible && (
                          <div className="spinner-loading">
                            {/* <Spin size="large" /> */}
                            <Spin
                              indicator={
                                <LoadingOutlined className="spin-icon" spin />
                              }
                            />
                          </div>
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={ContactUs2} key="2">
                        <Form
                          name="Banner"
                          layout="vertical"
                          form={ContactUs2form}
                        >
                          <Row>
                            <Col span={23}>
                              <Form.Item
                                label="Department Name"
                                name="Tab_Name2"
                                initialValue={ContactUs2}
                                onChange={(event) => {
                                  // const newData = [...ContactUs1Data];
                                  // newData[index].address =
                                  //   event.target.value;
                                  setContactUs2(event.target.value);
                                  setFormEdited(true);
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Department Name!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          {ContactUs2Data.map(
                            (
                              field,
                              index // Map through banner form fields array
                            ) => (
                              // Each row represents a banner form field
                              <Row key={field.key}>
                                <Col span={4}>
                                  <Form.Item
                                    label="Name"
                                    name={[field.key, "name"]}
                                    initialValue={field.name}
                                    onChange={(event) => {
                                      const newData = [...ContactUs2Data];
                                      newData[index].name = event.target.value;
                                      setContactUs2Data(newData);
                                      setFormEdited(true);
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Name!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={5}>
                                  <Form.Item
                                    label="Role"
                                    name={[field.key, "role"]}
                                    initialValue={field.role}
                                    onChange={(event) => {
                                      const newData = [...ContactUs2Data];
                                      newData[index].role = event.target.value;
                                      setContactUs2Data(newData);
                                      setFormEdited(true);
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Role!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={4}>
                                  <Form.Item
                                    label="Email"
                                    name={[field.key, "email"]}
                                    initialValue={field.email}
                                    onChange={(event) => {
                                      const newData = [...ContactUs2Data];
                                      newData[index].email = event.target.value;
                                      setContactUs2Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    label="Contact No"
                                    name={[field.key, "contact_no"]}
                                    initialValue={field.contact_no}
                                    onChange={(event) => {
                                      const newData = [...ContactUs2Data];
                                      newData[index].contact_no =
                                        event.target.value;
                                      setContactUs2Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item
                                    label="Select Country"
                                    name={[field.key, ""]}
                                  >
                                    <ReactFlagsSelect
                                      selected={
                                        field.country_code
                                          ? field.country_code
                                          : ""
                                      }
                                      onSelect={(code) => {
                                        const newData = [...ContactUs2Data];
                                        newData[index].country_code = code;
                                        setContactUs2Data(newData);
                                        setFormEdited(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    label="Location"
                                    name={[field.key, "address"]}
                                    onChange={(event) => {
                                      const newData = [...ContactUs2Data];
                                      newData[index].address =
                                        event.target.value;
                                      setContactUs2Data(newData);
                                      setFormEdited(true);
                                    }}
                                    initialValue={field.address}
                                  >
                                    <TextArea />
                                  </Form.Item>
                                </Col>

                                {/* <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "alttext"]} // Name attribute for form field
                            initialValue={field.alttext} // Initial value for the field
                            rules={[
                              // Validation rules for the field
                              {
                                required: true,
                                message: "Please input alt text!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col> */}
                                <Col span={1}>
                                  {index > 0 && ( // Render delete button for all fields except the first one
                                    <div className="del-btn">
                                      <Button
                                        danger
                                        icon={<DeleteOutlined />}
                                        onClick={() => removecontact2(index)}
                                      ></Button>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}
                          <Row>
                            <Col className="col-pad" span={12}>
                              <Button
                                type="primary"
                                className="add-fields"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={handleAddContact2FormField}
                              />
                            </Col>
                            <Col className="col-pad" span={12}>
                              <Form.Item
                                label="Deleting image id"
                                name={"delete_img_id"}
                                initialValue=""
                                hidden
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-pad" span={12}>
                              <Form.Item
                                label="Deleting image id"
                                name={"delete_img_id"}
                                initialValue=""
                                hidden
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className="savebtns">
                            <Space>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={() => contact2_save(0)}
                                >
                                  Save & go back
                                </Button>
                              </Form.Item>
                            </Space>
                            <Space>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={() => contact2_save(1)}
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </Space>
                          </div>
                        </Form>
                        {isModalVisible && (
                          <div className="spinner-loading">
                            {/* <Spin size="large" /> */}
                            <Spin
                              indicator={
                                <LoadingOutlined className="spin-icon" spin />
                              }
                            />
                          </div>
                        )}
                      </Tabs.TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>

      {/* <Footercomp /> */}
    </Layout>
  );
};

export default MyForm;
