import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Save_Social, get_Social_Data } from "../api/bannerApi";
import { Button, Form, Input, Space, Spin, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row } from "antd";
import { Upload, message } from "antd";
import UserData from "./UserData";

// // home
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { uploadImageAPI } from "../api/bannerApi";

const { Header, Content, Footer } = Layout;

const MyForm = () => {
  const [SocialformData, setSocialformData] = useState([]);
  const [Socialform] = Form.useForm(); // Using Ant Design Form

  const { TextArea } = Input;
  const [activeTab, setActiveTab] = useState("1");

  const userJwtToken = localStorage.getItem("Token");
  const SocialformRef = useRef();
  const [updatedIndices, setUpdatedIndices] = useState([]);

  //banner state variables
  const [SocialformFields, setSocialformFields] = useState([
    {
      key: 0,
      title_text: null,
      url: null,
    },
  ]);

  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSocialFinish = async (index) => {
    setIsModalVisible(true);

    try {
      await Socialform.validateFields();

      for (const index of updatedIndices) {
        const formData = new FormData();
        formData.append("files", SocialformData[index].file);

        try {
          // formData.append("files", info.file.originFileObj);
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);

          setSocialformData((prevFields) => {
            const updatedFields = [...prevFields];
            // updatedFields[index].imageUrl = url;
            updatedFields[index].main_img = uploadResponse[0].id;
            // updatedFields[index].file = info.file.originFileObj;

            return updatedFields;
          });
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }

      setTimeout(() => {
        let setarr = SocialformData.map((item) => {
          return {
            id: item.existing_id,
            title_text: item.title_text,
            url: item.url,
          };
        });
        const data = {
          data: {
            social_item: setarr,
          },
        };

        try {
          Save_Social(data, userJwtToken)
            .then((response) => {
              message.success("Form saved successfully!");
              localStorage.setItem("formEdited", "false");
              setTimeout(() => {
                // If index is 1, proceed to the prev tab
                // If index is 1, proceed to the next tab
                setFormEdited(false);
                if (index === 0) {
                  const prevTabIndex = parseInt(activeTab) - 1;
                  setActiveTab(prevTabIndex.toString());
                }
                // If index is 1, proceed to the next tab
                if (index === 2) {
                  const nextTabIndex = parseInt(activeTab) + 1;
                  setActiveTab(nextTabIndex.toString());
                }
              }, 500);
            })
            .catch((err) => {
            })
            .finally(() => {
              setIsModalVisible(false); 
            });
        } catch (error) {
          console.error("Failed to post data to the Strapi backend:", error);
          setIsModalVisible(false);
        }
      }, 4000);
    } catch (error) {
      // Handle form validation error
      setIsModalVisible(false);
      console.error("Form validation failed:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_Social_Data();
      const data = response.data;
      const Socialdata = response.data.data.attributes.social_item;
      const newSocialformFields = Socialdata.map((item) => {
        return {
          key: item.id,
          title_text: item.title_text,
          url: item.url,
          existing_id: item.id,
        };
      });
      //-----------set Banner data----------------
      setSocialformData(newSocialformFields);
      Socialform.setFieldValue(newSocialformFields);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  // -------------------------------- //
  useEffect(() => {
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //

  return (
    <Layout>
      <Headercomp />
      <div className="innerbody-pad">
        <div className="main-head">
          <h1>Social Media Links</h1>
        </div>
        <Row>
          <Col span={24}>
            <Tabs
              className="tab-none"
              activeKey={activeTab}
              onChange={handleTabChange}
            >
              <Tabs.TabPane tab="Social" key="1">
                <Form
                  //   name="banner"
                  //   layout="vertical"
                  //   // onFinish={handleSocialFinish} // Handle form submission
                  //   onChange={() => setFormEdited(true)} // Set formEdited state to true when any field changes
                  ref={SocialformRef} // Reference to the form for accessing form values
                  name="Social"
                  layout="vertical"
                  form={Socialform}
                  //   initialValues={SeoformData}
                  //   onValuesChange={handleSeoInputChange}
                  //   onFinish={onFinish}
                  //   onChange={() => setFormEdited(true)}
                >
                  {SocialformData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      // Each row represents a banner form field
                      <Row key={field.key}>
                        <Col span={8}>
                          <Form.Item
                            label={field.title_text + " url"}
                            name={[field.key, "url"]} // Name attribute for form field
                            initialValue={field.url} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...SocialformData];
                              newData[index].url = event.target.value;
                              setSocialformData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  )}
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleSocialFinish(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>

      {/* <Footercomp /> */}
    </Layout>
  );
};

export default MyForm;
