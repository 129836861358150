import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Save_Structural_sustainaibility,
  get_Structural_sustainaibility_Data,
} from "../../api/strucshapesApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message, Spin } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Tooltip, Col, Row } from "antd";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import { useNavigate } from "react-router-dom";

import "../CustomLinkBlot";
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
const { Header, Content, Footer } = Layout;

function Sustainaibilitysubpage() {
  // -------------section 1 data------------------- //
  const [SustformData, setSustformData] = useState({});
  const [sustform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const sustformRef = useRef();
  // -----------section 2 data--------------------------------- //
  const [Xcarb1formData, setXcarb1formData] = useState({});
  const [xcarb1form] = Form.useForm(); // Using Ant Design Form seo
  const xcarb1formRef = useRef();
  // -----------section 3 data--------------------------------- //
  const [Xcarb2formData, setXcarb2formData] = useState({});
  const [xcarb2form] = Form.useForm(); // Using Ant Design Form seo
  const xcarb2formRef = useRef();
  // ------------section 4 data---------------------------------- //
  const [Xcarb4formData, setXcarb4formData] = useState({});
  const [xcarb4form] = Form.useForm(); // Using Ant Design Form seo
  const xcarb4formRef = useRef();
  // -------------section 5 data------------------- //
  const [Section5formData, setSection5formData] = useState({});
  const [section5form] = Form.useForm(); // Using Ant Design Form seo
  const section5formRef = useRef();

  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");
  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };
  const [isUploading, setIsUploading] = useState(false);

  // function for handling image change for section 1 tab
  const handleasustChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setSustformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          sustformRef.current.setFieldsValue({
            [SustformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  // ------------------------------------------------------- //
  // function for handling image change for section 2 tab
  const handlexcarb1Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setXcarb1formData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagexcarb1Url = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          xcarb1formRef.current.setFieldsValue({
            [Xcarb1formData.key]: { imagexcarb1Url: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  // function for handling image change for section 3 tab

  const handlexcarb2Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setXcarb2formData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagexcarb2Url = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  // function for handling image change for section 4 tab
  const handlexcarb4Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setXcarb4formData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagexcarb4Url = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          xcarb4formRef.current.setFieldsValue({
            [Xcarb4formData.key]: { imagexcarb4Url: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  // function for handling image change for section 5 tab
  const handleasection5Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setSection5formData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          section5formRef.current.setFieldsValue({
            [Section5formData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  // ------------pdf upload logic---------------------- //
  const [File, setFile] = useState(null);
  const [isPdfUploading, setIsPdfUploading] = useState(false);
  const handleFileChange = async (e) => {
    setIsPdfUploading(true);
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      setXcarb1formData((prevFields) => {
        const updatedFields = { ...prevFields };
        // updatedFields[index].imageUrl = url;
        updatedFields.pdfname = uploadResponse[0].name;
        updatedFields.attachment = [
          { title: "pdf item", pdf: uploadResponse[0].id },
        ];
        // setxcarb1File("");
        // ispdf_upload = 1;
        //
        return updatedFields;
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsPdfUploading(false); // Set PDF uploading status to false after API call is finished
    }
  };

  const handleFileChange1 = async (e) => {
    setIsPdfUploading(true);
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      setXcarb2formData((prevFields) => {
        const updatedFields = { ...prevFields };
        // updatedFields[index].imageUrl = url;
        updatedFields.pdfname = uploadResponse[0].name;
        updatedFields.attachment = [
          { title: "pdf item", pdf: uploadResponse[0].id },
        ];
        return updatedFields;
      });

      setTimeout(() => {
      }, 2000);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsPdfUploading(false); // Set PDF uploading status to false after API call is finished
    }
  };
  const handleFileChange2 = async (e) => {
    setIsPdfUploading(true);
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      setXcarb4formData((prevFields) => {
        const updatedFields = { ...prevFields };
        // updatedFields[index].imageUrl = url;
        updatedFields.pdfname = uploadResponse[0].name;
        updatedFields.attachment = [
          { title: "pdf item", pdf: uploadResponse[0].id },
        ];
        return updatedFields;
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsPdfUploading(false); // Set PDF uploading status to false after API call is finished
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", File);
    const uploadResponse = await uploadPdfAPI(formData, userJwtToken);
  };
  // ------------------------------------------------------- //

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);

    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt="avatar"
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_Structural_sustainaibility_Data();
      const data = response.data;
      // ---------section 1 data ------------------- //
      const sustData = {
        title: response.data.data.attributes.section1_details.title,
        description: response.data.data.attributes.section1_details.description,
        page_url: response.data.data.attributes.section1_details.page_url,
        image: response.data.data.attributes.section1_details.image.data.id,
        existing_id: response.data.data.attributes.section1_details.id,
        imagesUrl:
          response.data.data.attributes.section1_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.section1_details.img_alt_txt,
      };
      // ------------------------------section 2 data---------------------------- //
      const xcarb1Data = {
        title: response.data.data.attributes.section2_details.title,
        description: response.data.data.attributes.section2_details.description,
        page_url: response.data.data.attributes.section2_details.page_url,
        image: response.data.data.attributes.section2_details.image.data.id,
        existing_id: response.data.data.attributes.section2_details.id,
        imagexcarb1Url:
          response.data.data.attributes.section2_details.image.data.attributes
            .formats.thumbnail.url,
        xcarb_pdf:
          response.data.data.attributes.section2_details.pdf_items[0].id,
        pdfname:
          response.data.data.attributes.section2_details.pdf_items[0].pdf.data
            .attributes.name,
        attachment: response.data.data.attributes.section2_details.pdf_items,
        img_alt_txt: response.data.data.attributes.section2_details.img_alt_txt,
      };
      // ------------------------------section 3 data---------------------------- //
      const xcarb2Data = {
        title: response.data.data.attributes.section3_details.title,
        description: response.data.data.attributes.section3_details.description,
        page_url: response.data.data.attributes.section3_details.page_url,
        image: response.data.data.attributes.section3_details.image.data.id,
        existing_id: response.data.data.attributes.section3_details.id,
        imagexcarb2Url:
          response.data.data.attributes.section3_details.image.data.attributes
            .formats.thumbnail.url,
        xcarb_pdf:
          response.data.data.attributes.section3_details.pdf_items[0].id,
        pdfname:
          response.data.data.attributes.section3_details.pdf_items[0].pdf.data
            .attributes.name,
        attachment: response.data.data.attributes.section3_details.pdf_items,
        img_alt_txt: response.data.data.attributes.section3_details.img_alt_txt,
      };
      // -----------------------------section 4----------------------------- //
      const xcarb4Data = {
        title: response.data.data.attributes.section4_details.title,
        description: response.data.data.attributes.section4_details.description,
        page_url: response.data.data.attributes.section4_details.page_url,
        image: response.data.data.attributes.section4_details.image.data.id,
        existing_id: response.data.data.attributes.section4_details.id,
        imagexcarb4Url:
          response.data.data.attributes.section4_details.image.data.attributes
            .formats.thumbnail.url,
        xcarb_pdf:
          response.data.data.attributes.section4_details.pdf_items[0].id,
        pdfname:
          response.data.data.attributes.section4_details.pdf_items[0].pdf.data
            .attributes.name,
        attachment: response.data.data.attributes.section4_details.pdf_items,
        img_alt_txt: response.data.data.attributes.section4_details.img_alt_txt,
      };
      // ---------section 5 data ------------------- //
      const section5Data = {
        title: response.data.data.attributes.section5_details.title,
        description: response.data.data.attributes.section5_details.description,
        page_url: response.data.data.attributes.section5_details.page_url,
        image: response.data.data.attributes.section5_details.image.data.id,
        existing_id: response.data.data.attributes.section1_details.id,
        imagesUrl:
          response.data.data.attributes.section5_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.section5_details.img_alt_txt,
      };
      //------------set section 1 data--------------
      setSustformData(sustData);
      sustform.setFieldsValue(sustData); // Set form fields value
      //------------set section 2 data--------------
      setXcarb1formData(xcarb1Data);
      xcarb1form.setFieldsValue(xcarb1Data); // Set form fields value
      //------------set section 3 data--------------
      setXcarb2formData(xcarb2Data);
      xcarb2form.setFieldsValue(xcarb2Data); // Set form fields value
      //------------set section 4 data--------------
      setXcarb4formData(xcarb4Data);
      xcarb4form.setFieldsValue(xcarb4Data); // Set form fields value
      //------------set section 5 data--------------
      setSection5formData(section5Data);
      section5form.setFieldsValue(section5Data); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ---------section 1 --------------//
  const handleSustInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSustformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  // ----------------section 2------------------- //
  const handleXcarb1InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setXcarb1formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  // ----------------section 3------------------- //
  const handleXcarb2InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setXcarb2formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  // ----------------section 4------------------- //
  const handleXcarb4InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setXcarb4formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  // ---------section 5 --------------//
  const handleSection5InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSection5formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  // ------------section 1 save button  ------------------- //
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const sustsave = async (index) => {
    setIsModalVisible(true);

    try {
      await sustform.validateFields();
      const data = {
        data: {
          section1_details: {
            description: SustformData.description,
            page_url: SustformData.page_url,
            title: SustformData.title,
            image: SustformData.image,
            existing_id: SustformData.existing_id,
            imagesUrl: SustformData.imagesUrl,
            img_alt_txt: SustformData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Structural_sustainaibility(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  // ----------------------section 2----------------------------- //
  const xcarb1save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await xcarb1form.validateFields();

      let pdfarr = Xcarb1formData.attachment.map((item) => {
        item.pdf = item.pdf.data ? item.pdf.data.id : item.pdf;
        return item;
      });
      const data = {
        data: {
          section2_details: {
            description: Xcarb1formData.description,
            page_url: Xcarb1formData.page_url,
            title: Xcarb1formData.title,
            image: Xcarb1formData.image,
            existing_id: Xcarb1formData.existing_id,
            imagexcarb1Url: Xcarb1formData.imagexcarb1Url,
            pdf_items: pdfarr,
            img_alt_txt: Xcarb1formData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };
      try {
        Save_Structural_sustainaibility(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);

            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };
  // -----------------------------------section 3----------------------------- //
  const xcarb2save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await xcarb2form.validateFields();

      let pdfarr = Xcarb2formData.attachment.map((item) => {
        item.pdf = item.pdf.data ? item.pdf.data.id : item.pdf;
        return item;
      });
      const data = {
        data: {
          section3_details: {
            description: Xcarb2formData.description,
            page_url: Xcarb2formData.page_url,
            title: Xcarb2formData.title,
            image: Xcarb2formData.image,
            existing_id: Xcarb2formData.existing_id,
            imagexcarb2Url: Xcarb2formData.imagexcarb2Url,
            pdf_items: pdfarr,
            img_alt_txt: Xcarb2formData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Structural_sustainaibility(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);

            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };

  // -----------------------section 4---------------------------- //
  const xcarb4save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await xcarb4form.validateFields();

      let pdfarr = Xcarb4formData.attachment.map((item) => {
        item.pdf = item.pdf.data ? item.pdf.data.id : item.pdf;
        return item;
      });
      const data = {
        data: {
          section4_details: {
            description: Xcarb4formData.description,
            page_url: Xcarb4formData.page_url,
            title: Xcarb4formData.title,
            image: Xcarb4formData.image,
            existing_id: Xcarb4formData.existing_id,
            imagexcarb4Url: Xcarb4formData.imagexcarb4Url,
            pdf_items: pdfarr,
            img_alt_txt: Xcarb4formData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Structural_sustainaibility(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };
  // ------------section 5 save button  ------------------- //
  const section5save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await section5form.validateFields();
      const data = {
        data: {
          section5_details: {
            description: Section5formData.description,
            page_url: Section5formData.page_url,
            title: Section5formData.title,
            image: Section5formData.image,
            existing_id: Section5formData.existing_id,
            imagesUrl: Section5formData.imagesUrl,
            img_alt_txt: Section5formData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Structural_sustainaibility(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);

            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };

  const onFinish = async () => {
    try {
      await sustform.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            {/* <h1>Sutainaibility subpage</h1> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/structuralshapes")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>Sutainability subpage</h1>
            </div>
          </div>
          <Row>
            <Col span={24}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="Sustainability details" key="1">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters max</b> including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="sustainaibility"
                    layout="vertical"
                    form={sustform}
                    initialValues={SustformData}
                    onValuesChange={handleSustInputChange}
                    onFinish={onFinish}
                    ref={sustformRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={SustformData.description}
                            onChange={(content, delta, source, editor) =>
                              handleSustInputChange({
                                description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={SustformData.imagesUrl}
                                  imageUrl={SustformData.imagesUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handleasustChange(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => sustsave(0)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => sustsave(1)}>
                            Save & continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Section 2 details" key="2">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> .Image size
                        less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Only <b>pdf file</b> type is allowed for the <b>PDF</b>{" "}
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="xcarb1"
                    layout="vertical"
                    form={xcarb1form}
                    initialValues={Xcarb1formData}
                    onValuesChange={handleXcarb1InputChange}
                    onFinish={onFinish}
                    ref={xcarb1formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={4}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Xcarb1formData.description}
                            onChange={(content, delta, source, editor) =>
                              handleXcarb1InputChange({
                                description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={Xcarb1formData.imagexcarb1Url}
                                  imageUrl={Xcarb1formData.imagexcarb1Url}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handlexcarb1Change(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Pdf"
                          name="xcarb_pdf"
                          // initialValue={field.pdfid} // initialValue={}
                          rules={[
                            {
                              required: true,
                              message: "Please upload Attachment!",
                            },
                          ]}
                        >
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange(e)}
                              style={{ display: "none" }}
                              id="xcarb1pdfupload"
                            />
                            <label>
                              <Button
                                className="btn btn-primary"
                                onClick={() =>
                                  // triggerFileInput(index)
                                  document
                                    .getElementById("xcarb1pdfupload")
                                    .click()
                                }
                              >
                                Upload PDF
                              </Button>
                            </label>
                            {isPdfUploading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    className="spin-icon-pdf"
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <div>
                                {Xcarb1formData.pdfname ? (
                                  <Tooltip title={Xcarb1formData.pdfname}>
                                    <p>File: {Xcarb1formData.pdfname}</p>
                                  </Tooltip>
                                ) : (
                                  "No File Selected"
                                )}
                              </div>
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb1save(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb1save(1)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb1save(2)}>
                            Save & continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Section 3 details" key="3">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Only <b>pdf file</b> type is allowed for the <b>PDF</b>{" "}
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="xcarb2"
                    layout="vertical"
                    form={xcarb2form}
                    initialValues={Xcarb2formData}
                    onValuesChange={handleXcarb2InputChange}
                    onFinish={onFinish}
                    ref={xcarb2formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={4}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Xcarb2formData.description}
                            onChange={(content, delta, source, editor) =>
                              handleXcarb2InputChange({
                                description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={Xcarb2formData.imagexcarb2Url}
                                  imageUrl={Xcarb2formData.imagexcarb2Url}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handlexcarb2Change(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Pdf"
                          name="xcarb_pdf"
                          // initialValue={field.pdfid} // initialValue={}
                          rules={[
                            {
                              required: true,
                              message: "Please upload Attachment!",
                            },
                          ]}
                        >
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange1(e)}
                              style={{ display: "none" }}
                              id="xcarb2pdfupload"
                            />
                            <label>
                              <Button
                                className="btn btn-primary"
                                onClick={() =>
                                  // triggerFileInput(index)
                                  document
                                    .getElementById("xcarb2pdfupload")
                                    .click()
                                }
                              >
                                Upload PDF
                              </Button>
                            </label>
                            {isPdfUploading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    className="spin-icon-pdf"
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <div>
                                {Xcarb2formData.pdfname ? (
                                  <Tooltip title={Xcarb2formData.pdfname}>
                                    <p>File: {Xcarb2formData.pdfname}</p>
                                  </Tooltip>
                                ) : (
                                  "No File Selected"
                                )}
                              </div>
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb2save(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb2save(1)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb2save(2)}>
                            Save & continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Section 4 details" key="4">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Only <b>pdf file</b> type is allowed for the <b>PDF</b>{" "}
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="xcarb4"
                    layout="vertical"
                    form={xcarb4form}
                    initialValues={Xcarb4formData}
                    onValuesChange={handleXcarb4InputChange}
                    onFinish={onFinish}
                    ref={xcarb4formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={4}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Xcarb4formData.description}
                            onChange={(content, delta, source, editor) =>
                              handleXcarb4InputChange({
                                description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={Xcarb4formData.imagexcarb4Url}
                                  imageUrl={Xcarb4formData.imagexcarb4Url}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handlexcarb4Change(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Pdf"
                          name="xcarb_pdf"
                          // initialValue={field.pdfid} // initialValue={}
                          rules={[
                            {
                              required: true,
                              message: "Please upload Attachment!",
                            },
                          ]}
                        >
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange2(e)}
                              style={{ display: "none" }}
                              id="xcarb3pdfupload"
                            />
                            <label>
                              <Button
                                className="btn btn-primary"
                                onClick={() =>
                                  // triggerFileInput(index)
                                  document
                                    .getElementById("xcarb3pdfupload")
                                    .click()
                                }
                              >
                                Upload PDF
                              </Button>
                            </label>
                            {isPdfUploading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    className="spin-icon-pdf"
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <div>
                                {Xcarb4formData.pdfname ? (
                                  <Tooltip title={Xcarb4formData.pdfname}>
                                    <p>File: {Xcarb4formData.pdfname}</p>
                                  </Tooltip>
                                ) : (
                                  "No File Selected"
                                )}
                              </div>
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb4save(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb4save(1)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb4save(2)}>
                            Save & continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Section 5 details" key="5">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="section5"
                    layout="vertical"
                    form={section5form}
                    initialValues={Section5formData}
                    onValuesChange={handleSection5InputChange}
                    onFinish={onFinish}
                    ref={section5formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Section5formData.description}
                            onChange={(content, delta, source, editor) =>
                              handleSection5InputChange({
                                description: editor.getHTML(),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={Section5formData.imagesUrl}
                                  imageUrl={Section5formData.imagesUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handleasection5Change(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => section5save(0)}
                          >
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => section5save(1)}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Sustainaibilitysubpage;
