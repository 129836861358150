import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Spin } from "antd";
import { Card } from "antd";
import logo from "../assets/images/arcelor-mittal-logo.png";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import UserData from "./UserData";
import { loginAPI } from "../api/bannerApi";

function Login() {
  UserData.setUserId("");
  UserData.setEmail("");
  UserData.setJwtToken("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsModalVisible(true);

    try {
      const loginresponse = await loginAPI(email, password);

      if (loginresponse.ok) {
        const data = await loginresponse.json();
        UserData.setUserId(data.user.id);
        UserData.setEmail(data.user.email);
        UserData.setJwtToken(data.jwt);
        localStorage.setItem("UserDataInfo", JSON.stringify(data.user));
        localStorage.setItem("Token", data.jwt);
        localStorage.setItem("lastLoginTimestamp", "1");

        navigate("/admin/dashboard");
      } else {
        const errorData = await loginresponse.json();
        throw new Error(errorData.message[0].messages[0].message);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch {
      message.warning("Invalid credentials");
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        {/* <img src={logo} alt="ArcelorMittal Logo" title="ArcelorMittal Logo" style = {{marginBottom:"20px"}} /> */}

        <Card
          title="Admin Login"
          bordered={true}
          style={{
            width: "30%",
          }}
          headStyle={{ border: "none" }}
        >
          <Form
            layout="vertical"
            name="basic"
            labelCol={{
              span: 20,
            }}
            wrapperCol={{
              span: 40,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Form.Item
              label="User ID"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 1,
              }}
            >
              <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form.Item>
          </Form>
          {isModalVisible && (
            <div className="spinner-loading">
              {/* <Spin size="large" /> */}
              <Spin
                indicator={<LoadingOutlined className="spin-icon" spin />}
              />
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default Login;
