import axios from "axios";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Dev API URL
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getBannerData = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-home?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getBusinessData = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-home?populate[1]=banner_block.banner_item.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getNewsData = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-home?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=newsletter_section`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getSEOData = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/page-home?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=newsletter_section&populate[5]=seo`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadImageAPI = async (formData, userJwtToken) => {
  const uploadResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: formData,
    }
  );
  return uploadResponse.json();
};

export const postresponseAPI = async (postformdata, userJwtToken) => {
  const postresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/page-home`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: postformdata,
    }
  );
  return postresponse;
};

export const deleteimageAPI = async (imageId, userJwtToken) => {
  const deleteresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload/files/${imageId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
    }
  );
  return deleteresponse;
};

// login.js
export const loginAPI = async (email, password) => {
  const loginresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/local`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    }
  );
  return loginresponse;
};

export const uploadPdfAPI = async (formData, userJwtToken, file) => {
  try {
    formData.append("file", file);
    const response = await axiosInstance.post(`/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userJwtToken}`,
      },
    });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

export const verifyExistingPassword = async (
  userEmail,
  givenPassword,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.post(
      `/api/auth/local`,
      {
        identifier: userEmail,
        password: givenPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${userJwtToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (
  newPassword,
  existingPassword,
  confirmPassword,
  userJwtToken
) => {
  try {
    const response = await axiosInstance.post(
      `/api/auth/change-password`,
      {
        password: newPassword,
        currentPassword: existingPassword,
        passwordConfirmation: confirmPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${userJwtToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
