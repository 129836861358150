
import axios from "axios";
// Create Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Dev API URL
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const uploadImageAPI = async (formData, userJwtToken) => {
  const uploadResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: formData,
    }
  );
  return uploadResponse;
};

export const postresponseAPI = async (postformdata, userJwtToken) => {
  const postresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/page-home`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userJwtToken}`,
      },
      body: postformdata, // Convert data to JSON string and send in the request body
    }
  );
  return postresponse;
};

export const deleteimageAPI = async (imageId, userJwtToken) => {
  const deleteresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/upload/files/${imageId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userJwtToken}`,
      },
    }
  );
  return deleteresponse;
};

// login.js
export const loginAPI = async (email, password) => {
  const loginresponse = await fetch(
    `${process.env.REACT_APP_API_URL}/api/auth/local`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    }
  );
  return loginresponse;
};

// -------------------------------------------- //
export const get_News_Data = async () => {
  try {
    const response = await axiosInstance.get(`/api/news-items?populate=*`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const Save_Newsdata = async (postformdata, userJwtToken) => {
  try {
    const response = await axiosInstance.post("/api/news-items", postformdata);
    return response.data;
  } catch (error) {}
};

export const Update_Newsdata = async (postformdata, id, userJwtToken) => {
  try {
    const response = await axiosInstance.put(
      `/api/news-items/${id}`,
      postformdata
    );
    return response.data;
  } catch (error) {}
};

export const Delete_Newsdata = async (id, userJwtToken) => {
  try {
    const response = await axiosInstance.delete(`/api/news-items/${id}`);
    return response.data;
  } catch (error) {}
};
// -------------------------------------------- //

export const Delete_Subscriberdata = async (id, userJwtToken) => {
  try {
    const response = await axiosInstance.delete(`/api/subscribers/${id}`);
    return response.data;
  } catch (error) {}
};
