import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import logo from "../assets/images/arcelor-mittal-logo.png";
import { Breadcrumb, Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined, LinkOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { uploadImageAPI } from "../api/bannerApi";
import { Modal, Col, Row } from "antd";
import UserData from "./UserData";
import { Button, Form, Input, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Upload, message, Spin } from "antd";
const items = [
  { key: 0, label: "Dashboard", route: "/admin/dashboard" },
  { key: 1, label: "Home", route: "/admin/home" },
  { key: 2, label: "Structural shapes", route: "/admin/structuralshapes" },
  { key: 3, label: "Bars and Rods", route: "/admin/barsnrods" },
  { key: 4, label: "Flats", route: "/admin/flats" },
  { key: 5, label: "Logistics", route: "/admin/logistics" },
  { key: 6, label: "Wire solutions", route: "/admin/wiresolns" },
  { key: 7, label: "News", route: "/admin/news" },
  { key: 8, label: "Subscribers", route: "/admin/subscribers" },
  { key: 9, label: "Social Media Links", route: "/admin/social" },
  // {key: 10, label: "Demo ", route: "/admin/demo-react-quill" },

  {
    key: 11,
    label: <UserOutlined />,
    submenu: [
      { key: 101, label: "Change password", route: "/admin/changep" },
      { key: 102, label: "Log out", route: "/admin" },
    ],
  },
];
const { Header, Content, Footer } = Layout;

function Headercomp() {
  const handleOk = async () => {
    setisloadervisible(true);
    const formData = new FormData();
    const userJwtToken = localStorage.getItem("Token");

    formData.append("files", uploadedFile);
    const uploadResponse = await uploadImageAPI(formData, userJwtToken);
    setuploadedUrl(uploadResponse[0].url);
    setisloadervisible(false);
  };
  const handleuploadChange = async (info) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      setuploadedFile(info.file.originFileObj);
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  const copyToClipboard = () => {
    copy(uploadedUrl);
    alert(`You have copied "${uploadedUrl}"`);
  };

  // ------------------------------------------------------- //
  const [allowNavigation, setAllowNavigation] = useState(true);
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isloadervisible, setisloadervisible] = useState(false);

  const [uploadedFile, setuploadedFile] = useState("");
  const [uploadedUrl, setuploadedUrl] = useState("");

  useEffect(() => {
    const formEdited = localStorage.getItem("formEdited");
    if (formEdited === "false") {
      setAllowNavigation(true);
    }
  }, []);

  // Get the current key based on the location
  const currentKey = items
    .find((item) => item.route === location.pathname)
    ?.key?.toString();

  const handleTabClick = ({ item, key, keyPath }) => {
    // Get formEdited state from local storage
    const formEdited = localStorage.getItem("formEdited");
    if (formEdited === "true") {
      message.warning("Please save your changes before switching pages.");
      setAllowNavigation(true);
      return;
    }
    setAllowNavigation(true);
  };
  // ------------------------------------------------------- //
  const [activePage, setActivePage] = useState("1");
  const handlePageChange = (key) => {
    const formEdited = localStorage.getItem("formEdited");
    if (formEdited === "true") {
      message.warning("Please save the form first!");
      return;
    } else {
      setActivePage(key);
    }
  };

  return (
    <>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <div className="demo-logo">
          <img src={logo} alt="Logo" style={{ marginRight: "0" }} />
        </div>
        <Modal
          title="Upload & Generate Link"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={() => {
            setIsModalVisible(false);
            setuploadedFile("");
            setuploadedUrl("");
          }}
          okText="Upload"
          footer={null}
        >
          {isloadervisible && (
            <div className="spinner-loading">
              {/* <Spin size="large" /> */}
              <Spin
                indicator={<LoadingOutlined className="spin-icon" spin />}
              />
            </div>
          )}
          {uploadedFile === "" && uploadedUrl === "" && (
            <Form
              // form={form}
              // initialValues={editingRecord}
              layout="vertical"
              // onValuesChange={handleInputChange}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    width: 200,
                    height: 200,
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Upload // Upload component for image
                    name="avatar"
                    className="avatar-uploader"
                    showUploadList={false}
                    style={{ width: "100px", height: "100px" }}
                    // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                    onChange={(info) => handleuploadChange(info)} // Handle image upload change
                  >
                    <Button
                      style={{
                        width: 100,
                        height: 100,
                        marginTop: 20,
                      }}
                      icon={<UploadOutlined />}
                    />
                  </Upload>
                </Col>
              </Row>
            </Form>
          )}

          {uploadedFile !== "" && uploadedUrl === "" && (
            <div>
              <div>
                <h4>File name:</h4>
                <p>{uploadedFile.name}</p>
              </div>
              <div>
                <Button onClick={() => handleOk()}>Upload</Button>
              </div>
            </div>
          )}

          {uploadedUrl !== "" && (
            <div>
              <Input
                type="text"
                disabled
                value={uploadedUrl}
                placeholder="Enter the text you want to copy"
              />

              <Button onClick={copyToClipboard}>Copy to Clipboard</Button>
            </div>
          )}
        </Modal>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={currentKey ? [currentKey] : []}
          style={{ flex: 1, minWidth: 0 }}
          onClick={handleTabClick}
          activeKey={activePage}
          onChange={handlePageChange}
        >
          {items.map((item) =>
            item.submenu ? (
              <>
                <Menu.Item onClick={() => setIsModalVisible(true)}>
                  <Link to="#" className="Linkgenerator">
                    <LinkOutlined />
                    Upload & Generate Link
                  </Link>
                </Menu.Item>

                <Menu.SubMenu key={item.key} title={item.label}>
                  {item.submenu.map((subItem) =>
                    subItem.label === "Log out" ? (
                      <Menu.Item
                        key={subItem.key}
                        onClick={() => {
                          localStorage.setItem("Token", "");
                          localStorage.setItem("lastLoginTimestamp", 0);
                          localStorage.setItem("lastUpdateTimestamp", "0");
                        }}
                      >
                        <Link to={subItem.route}>{subItem.label}</Link>
                      </Menu.Item>
                    ) : (
                      <Menu.Item key={subItem.key}>
                        <Link to={subItem.route}>{subItem.label}</Link>
                      </Menu.Item>
                    )
                  )}
                </Menu.SubMenu>
              </>
            ) : (
              <>
                <Menu.Item key={item.key}>
                  {/* <Link to={item.route}>{item.label}</Link> */}
                  {allowNavigation ? (
                    <Link
                      to={item.route}
                      onClick={(e) => {
                        const formEdited = localStorage.getItem("formEdited");
                        if (formEdited === "true") {
                          e.preventDefault();
                        }
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <div>{item.label}</div>
                  )}
                </Menu.Item>
              </>
            )
          )}
        </Menu>
      </Header>
    </>
  );
}

export default Headercomp;
