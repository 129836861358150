import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Save_Wire_fencing,
  get_Wire_fencing_Data,
} from "../../api/wiresolnsApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message, Spin } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Modal, Col, Row } from "antd";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import "../CustomLinkBlot";

import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
import { Save_Search_ByID, get_Search_Data } from "../../api/strucshapesApi";
const { Header, Content, Footer } = Layout;

function Fencing() {
  const [FencingformData, setFencingformData] = useState({});
  const [fencingform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const fencingformRef = useRef();
  // ------------------------------------------------------ //
  const [Section2formData, setSection2formData] = useState({});
  const [section2form] = Form.useForm(); // Using Ant Design Form seo
  const section2formRef = useRef();

  const [Section3formData, setSection3formData] = useState({});
  const [section3form] = Form.useForm(); // Using Ant Design Form seo
  const section3formRef = useRef();
  const [Searchdata, Setsearchdata] = useState([]);

  // ------------------------------------------------------- //

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isspinVisible, setisspinVisible] = useState(false);

  const [uploadedFile, setuploadedFile] = useState("");
  const [uploadedUrl, setuploadedUrl] = useState("");

  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };
  const [isUploading, setIsUploading] = useState(false);

  // function for handling image change for sustainaibility tab
  const handleafencingChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setFencingformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          fencingformRef.current.setFieldsValue({
            [FencingformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handleuploadChange = async (info) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      setuploadedFile(info.file.originFileObj);
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };
  // ------------------------------------------------------- //
  // function for handling image change for xcarb tab
  const handlesection2Change = async (info, index) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setSection2formData((prevFields) => {
        const updatedFields = { ...prevFields };
        updatedFields.imagexcarb1Url = url;
        updatedFields.image = uploadResponse[0].id;
        return updatedFields;
      });
      // Update the form values with the new imageUrl
      section2formRef.current.setFieldsValue({
        [Section2formData.key]: { imagexcarb1Url: url }, // Update the existing_img_id field in the form
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  // ------------------------------------------------------- //

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
    Search_Data();
  }, []);

  const Search_Data = async () => {
    const response = await get_Search_Data();
    const data = response.data.data;

    let fenchingdata = data.filter(
      (item) => item.attributes.title === "Fencing and Vineyard Wire"
    );
    Setsearchdata(fenchingdata);
  };
  const fetchData = async () => {
    try {
      const response = await get_Wire_fencing_Data();
      const data = response.data;

      const fencingData = {
        title: response.data.data.attributes.fencing_details.title,
        description: response.data.data.attributes.fencing_details.description,
        page_url: response.data.data.attributes.fencing_details.page_url,
        image: response.data.data.attributes.fencing_details.image.data.id,
        existing_id: response.data.data.attributes.fencing_details.id,
        imagesUrl:
          response.data.data.attributes.fencing_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.fencing_details.img_alt_txt,
      };
      // ------------------------------xcarb1 data---------------------------- //
      const section2Data = {
        left_description:
          response.data.data.attributes.horizontal_block_fencing
            .left_description,
        right_description:
          response.data.data.attributes.horizontal_block_fencing
            .right_description,
      };

      // ---------------------------------------------------------- //
      //------------set agriculture sustainaibility data--------------
      setFencingformData(fencingData);
      fencingform.setFieldsValue(fencingData); // Set form fields value
      //------------set agriculture xcarb1 data--------------
      setSection2formData(section2Data);
      section2form.setFieldsValue(section2Data); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFencingInputChange = (changedValues, allValues) => {
    // setFormEdited(true);
    setFencingformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const section2ipchange = (changedValues, allValues) => {
    // setFormEdited(true);
    setSection2formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  // const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const fencingsave = async (index) => {
    setisspinVisible(true);

    try {
      await fencingform.validateFields();
      const data = {
        data: {
          fencing_details: {
            description: FencingformData.description,
            page_url: FencingformData.page_url,
            title: FencingformData.title,
            image: FencingformData.image,
            existing_id: FencingformData.existing_id,
            imagesUrl: FencingformData.imagesUrl,
            img_alt_txt: FencingformData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[0].text =
        FencingformData.title + FencingformData.description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Wire_fencing(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            localStorage.setItem("formEdited", "false");
            setFormEdited(false);
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setisspinVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setisspinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setisspinVisible(false);
    }
  };
  // --------------------------------------------------- //
  const horiblocksave = async (index) => {
    setisspinVisible(true); // Stop loading when API call is finished

    try {
      await section2form.validateFields();
      const data = {
        data: {
          horizontal_block_fencing: {
            left_description: Section2formData.left_description,
            right_description: Section2formData.right_description,
          },
        },
      };
      let search_data = Searchdata;
      search_data[0].attributes.desc[1].text =
        Section2formData.left_description;
      search_data[0].attributes.desc[2].text =
        Section2formData.right_description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});

        Save_Wire_fencing(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);

            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setisspinVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setisspinVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setisspinVisible(false); // Stop loading when API call is finished
    }
  };

  // --------------------------------------------------- //

  const onFinish = async () => {
    try {
      await fencingform.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const handleOk = async () => {
    const formData = new FormData();

    formData.append("files", uploadedFile);
    const uploadResponse = await uploadImageAPI(formData, userJwtToken);
    setuploadedUrl(uploadResponse[0].url);
  };

  const copyToClipboard = () => {
    copy(uploadedUrl);
    alert(`You have copied "${uploadedUrl}"`);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            {/* <h1>Fencing and Vineyard Wire</h1> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/wiresolns")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>Fencing and Vineyard Wire</h1>
            </div>
          </div>
          <Row>
            {/* <Modal
              title="Upload & Generate Link"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={() => {
                setIsModalVisible(false);
                setuploadedFile("");
                setuploadedUrl("");
              }}
              okText="Upload"
              footer={null}
            >
              {uploadedFile === "" && uploadedUrl === "" && (
                <Form
                  // form={form}
                  // initialValues={editingRecord}
                  layout="vertical"
                  // onValuesChange={handleInputChange}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        width: 200,
                        height: 200,
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Upload // Upload component for image
                        name="avatar"
                        className="avatar-uploader"
                        showUploadList={false}
                        style={{ width: "100px", height: "100px" }}
                        // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                        onChange={(info) => handleuploadChange(info)} // Handle image upload change
                      >
                        <Button
                          style={{
                            width: 100,
                            height: 100,
                            marginTop: 20,
                          }}
                          icon={<UploadOutlined />}
                        />
                      </Upload>
                    </Col>
                  </Row>
                </Form>
              )}

              {uploadedFile !== "" && uploadedUrl === "" && (
                <div>
                  <div>
                    <h4>File name:</h4>
                    <p>{uploadedFile.name}</p>
                  </div>
                  <div>
                    <Button onClick={() => handleOk()}>Upload</Button>
                  </div>
                </div>
              )}

              {uploadedUrl !== "" && (
                <div>
                  <Input
                    type="text"
                    disabled
                    value={uploadedUrl}
                    placeholder="Enter the text you want to copy"
                  />

                  <Button onClick={copyToClipboard}>Copy to Clipboard</Button>
                </div>
              )}
            </Modal> */}
            <Col span={24}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="Fencing details" key="1">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Only <b>pdf file</b> type is allowed for the Upload
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  {/* <Button
                    type="primary"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Upload & Generate Link
                  </Button> */}
                  <Form
                    style={{ marginTop: 20 }}
                    name="section1fencing"
                    layout="vertical"
                    form={fencingform}
                    initialValues={FencingformData}
                    // onValuesChange={handleFencingInputChange}
                    onFinish={onFinish}
                    ref={fencingformRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          label="Title"
                          name="title"
                          onChange={(e) => {
                            setFormEdited(true);
                            setFencingformData((prv) => {
                              prv.title = e.target.value;
                              return prv;
                            });
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={FencingformData.description}
                            onChange={(content, delta, source, editor) => {
                              if (FencingformData.description !== undefined) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(FencingformData.description)
                                ) {
                                  setFormEdited(true);
                                  setFencingformData((prv) => {
                                    prv.description = cleanHTML(content);
                                    return prv;
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={FencingformData.imagesUrl}
                                  imageUrl={FencingformData.imagesUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handleafencingChange(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                          onChange={(e) => {
                            setFormEdited(true);
                            setFencingformData((prv) => {
                              prv.img_alt_txt = e.target.value;
                              return prv;
                            });
                          }}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => fencingsave(0)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => fencingsave(1)}>
                            Save & Continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isspinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Horizontal block details" key="2">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        <b>400 Characters</b> max, including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                        <b>'Left Description'</b> and <b>'Right Description'</b>{" "}
                        fields is recommended.
                      </li>
                      <li>
                        Only <b>pdf</b> file type is allowed for the Upload
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                    </ul>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Upload & Generate Link
                  </Button>

                  <Form
                    style={{ marginTop: 20 }}
                    name="xcarb1"
                    layout="vertical"
                    form={section2form}
                    initialValues={Section2formData}
                    // onValuesChange={section2ipchange}
                    onFinish={onFinish}
                    ref={section2formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label=" Left Description"
                          name="left_description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Left Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Section2formData.left_description}
                            onChange={(content, delta, source, editor) => {
                              if (
                                Section2formData.left_description !== undefined
                              ) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(Section2formData.left_description)
                                ) {
                                  setFormEdited(true);
                                  setSection2formData((prv) => {
                                    prv.left_description = cleanHTML(content);
                                    return prv;
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label=" Right Description"
                          name="right_description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Right Description",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Section2formData.right_description}
                            onChange={(content, delta, source, editor) => {
                              if (
                                Section2formData.right_description !== undefined
                              ) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(Section2formData.right_description)
                                ) {
                                  setFormEdited(true);
                                  setSection2formData((prv) => {
                                    prv.right_description = cleanHTML(content);
                                    return prv;
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => horiblocksave(0)}
                          >
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => horiblocksave(1)}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isspinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Fencing;
