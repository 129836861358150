import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Save_Structural_projectgallery, //projectgallery
  get_Structural_projectgallery_Data,
  deleteimageAPI,
} from "../../api/strucshapesApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs, Spin } from "antd";
import {
  LoadingOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { Col, Row, Carousel } from "antd";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import { useNavigate } from "react-router-dom";

import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
const { Header, Content, Footer } = Layout;

function Project() {
  // -------------section 1 data------------------- //
  const [ProjectformData, setProjectformData] = useState([]);
  const [projectform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const projectformRef = useRef();

  // ------------testing ------------------- //
  const [headData, setheadformData] = useState([]);

  // ------------testing -------------------- //

  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");
  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const [projectFormFields, setprojectFormFields] = useState([
    {
      key: 0,
      order: null,
      title: null,
      indicatorTitle: null,
      imageUrl: null,
      imageId: null,
      existing_img_id: null,
      alttext: null,
      // main_title: null,
      Description: null,
      carousel_data: null,
    },
  ]);

  const handleDeleteProjectFormField = (key) => {
    const index = ProjectformData.findIndex((field) => field.key === key);
    if (index > -1) {
      const existingImgId = ProjectformData[index].existing_img_id;
      const currentDeleteImgId =
        projectformRef.current.getFieldValue("delete_img_id");
      projectformRef.current.setFieldsValue({
        delete_img_id: currentDeleteImgId
          ? `${currentDeleteImgId}, ${existingImgId}`
          : `${existingImgId},`,
      });
      const newProjectformData = [...ProjectformData];
      newProjectformData.splice(index, 1);
      // setProjectformData(newProjectformData);
      setProjectformData(newProjectformData);
    }
  };

  const handleAddProjectFormField = () => {
    const maxKey = Math.max(...projectFormFields.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setprojectFormFields([
      ...projectFormFields,
      {
        key: newKey,
        order: "",
        title: "",
        indicatorTitle: "",
        imageUrl: "",
        // existing_img_id: "",
        // alttext: "",
        Description: "",
        carousel_data: [],
      },
    ]);
    setProjectformData([
      ...ProjectformData,
      {
        key: newKey,
        order: "",
        title: "",
        indicatorTitle: "",
        imageUrl: "",
        existing_img_id: "",
        alttext: "",
        Description: "",
        carousel_data: [],
      },
    ]);
  };

  // ------------pdf upload logic---------------------- //
  const [File, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", File);
    const uploadResponse = await uploadPdfAPI(formData, userJwtToken);
  };
  // ------------------------------------------------------- //

  const handleaprojChange = async (info, index) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setProjectformData((prevFields) => {
        const updatedFields = { ...prevFields };
        updatedFields.imagesUrl = url;
        updatedFields.image = uploadResponse[0].id;
        return updatedFields;
      });
      // Update the form values with the new imageUrl
      projectformRef.current.setFieldsValue({
        [ProjectformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);

    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  // ------------------------------------ //
  const [isUploading, setIsUploading] = useState(
    ProjectformData.map(() => false)
  );

  const Submit_multiple_img = async (info) => {
    let data = [];
    let rmdumplicate = [];

    const uploadPromises = info.fileList.map((item) => {
      if (!rmdumplicate.includes(item.uid)) {
        const formData = new FormData();
        formData.append("files", item.originFileObj);

        return uploadImageAPI(formData, userJwtToken)
          .then((uploadResponse) => {
            // const url = URL.createObjectURL(info.file.originFileObj);
            const url = URL.createObjectURL(item.originFileObj);

            let obj = {
              id: uploadResponse[0].id,
              name: uploadResponse[0].name,
              imgurl: uploadResponse[0].formats.thumbnail.url,
            };
            data.push(obj);
            rmdumplicate.push(item.uid);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    });

    await Promise.all(uploadPromises);
    return data;
  };
  const handle_multiple_upload = async (info, index) => {
    let data = [];
    // setIsUploading(true);
    setIsUploading((prevState) => {
      // Start loading when the upload starts
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    // Filter out duplicate files
    const uniqueFiles = info.fileList.filter(
      (file, i, self) => i === self.findIndex((t) => t.uid === file.uid)
    );

    // test test test
    // for (let file of uniqueFiles) {
    //   const fileType = file.originFileObj.type;
    //   if (fileType !== "image/jpeg" && fileType !== "image/png") {
    //     alert("Please upload only images");
    //     return;
    //   }
    // }

    const lastFile = {
      ...info,
      fileList: [uniqueFiles[uniqueFiles.length - 1]],
    };
    Submit_multiple_img(lastFile)
      .then((data) => {
        setProjectformData((prevFields) => {
          const updatedFields = [...prevFields];
          // updatedFields[index].carousel_data = data;
          updatedFields[index].carousel_data = [
            ...updatedFields[index].carousel_data,
            ...data,
          ];
          return updatedFields;
        });
        // setIsUploading(false);
        setIsUploading((prevState) => {
          // Stop loading when the upload is done
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      })
      .catch((err) => {
        // setIsUploading(false);
        setIsUploading((prevState) => {
          // Stop loading when the upload is done
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      });
  };
  // ------------------------------------- //

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_Structural_projectgallery_Data();
      const data = response.data;
      // ------------------main title----------------------- //
      const projhData = {
        main_title: response.data.data.attributes.main_title,
      };
      // ----------------------------------------- //
      // --------- featured project ------------------- //
      const projData = response.data.data.attributes.projectgallery_details;
      const newProjData = projData.map((item) => {
        let data = [];
        if (item.image.data.length > 0) {
          data = item.image.data.map((car) => {
            return {
              id: car.id,
              name: car.attributes.name,
              imgurl: car.attributes.formats.thumbnail.url,
            };
          });
        }
        return {
          key: item.id,
          title: item.title,
          Description: item.Description,
          // image_2: item.image_2.data.id,
          // imageUrl: item.image.data.attributes.formats.thumbnail.url,
          carousel_data: data,
          existing_id: item.id,
          img_alt_txt: item.img_alt_txt,
        };
      });
      // ------------main title data-------------- //
      setheadformData(projhData);
      projectform.setFieldsValue(projhData); // Set form fields value
      //------------set featured project data--------------
      // setProjectformData(projData);
      // projectform.setFieldsValue(projData); // Set form fields value
      setProjectformData(newProjData);
      // projectform.setFieldValue(newProjData); // Set form fields value
      setTimeout(() => {}, 2000);
      projectform.setFieldValue(newProjData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ------------------ testing ------------------- //
  // useEffect(() => {
  //   fetchheadData();
  // }, []);

  // const fetchheadData = async () => {
  //   try {
  //     const response = await get_Structural_projectgallery_Data();
  //     const data = response.data;
  //     // ---------section 1 data ------------------- //
  //     const projhData = {
  //       main_title: response.data.data.attributes.main_title,
  //     };
  //     //------------set section 1 data--------------
  //     setheadformData(projhData);
  //     projectform.setFieldsValue(projhData); // Set form fields value
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handle_lineitem_upload = async (info, index) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setProjectformData((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].imageUrl = url;
        updatedFields[index].main_img = uploadResponse[0].id;

        return updatedFields;
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  // ---------section 1 --------------//
  const handleProjInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setProjectformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const projsave = async (index) => {
    setIsModalVisible(true);

    try {
      await projectform.validateFields();
      let setarr = ProjectformData.map((item) => {
        return {
          // id: item.key,
          id: item.existing_id,
          title: item.title,
          Description: item.Description,
          image: item.carousel_data,
          img_alt_txt: item.img_alt_txt,
          // image_2: item.main_img,
        };
      });
      const mainTitle = projectform.getFieldValue("main_title");
      const data = {
        data: {
          main_title: mainTitle,
          projectgallery_details: [...setarr],
        },
      };
      try {
        Save_Structural_projectgallery(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              if (index === 0) {
                navigate("/admin/structuralshapes");
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  // -------------------------------------------- //

  const onFinish = async () => {
    try {
      await projectform.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  // -------------------------------

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
  };

  const carouselRefs = useRef([]);
  const [isDeleting, setIsDeleting] = useState(
    ProjectformData.map(() => false)
  );
  const handleImageDelete = async (carouselIndex, imgIndex) => {
    setIsDeleting((prev) => {
      const newDeleting = [...prev];
      newDeleting[carouselIndex] = true;
      return newDeleting;
    });
    const imageId = ProjectformData[carouselIndex].carousel_data[imgIndex].id;
    try {
      const response = await deleteimageAPI(imageId, userJwtToken); // Call the API
      if (response.ok) {
        // If the response is successful
        const newData = [...ProjectformData];
        newData[carouselIndex].carousel_data.splice(imgIndex, 1);
        setProjectformData(newData);
      } else {
        console.error("Failed to delete image:", response);
      }
    } catch (error) {
      console.error("An error occurred while deleting the image:", error);
    } finally {
      setIsDeleting((prev) => {
        const newDeleting = [...prev];
        newDeleting[carouselIndex] = false;
        return newDeleting;
      });
    }
  };

  const Submit_carousel_img = async (info) => {
    let data = [];
    let rmdumplicate = [];

    const uploadPromises = info.fileList.map((item) => {
      if (!rmdumplicate.includes(item.uid)) {
        const formData = new FormData();
        formData.append("files", item.originFileObj);

        return uploadImageAPI(formData, userJwtToken)
          .then((uploadResponse) => {
            const url = URL.createObjectURL(info.file.originFileObj);

            let obj = {
              id: uploadResponse[0].id,
              name: uploadResponse[0].name,
              imgurl: uploadResponse[0].formats.thumbnail.url,
            };
            data.push(obj);
            rmdumplicate.push(item.uid);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    });

    await Promise.all(uploadPromises);

    return data;
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [iscarouselUploading, setIscarouselUploading] = useState(
    ProjectformData.map(() => false)
  );
  const handle_carousel_upload = async (info, index) => {
    let data = [];
    setIscarouselUploading((prevState) => {
      // Start loading when the upload starts
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    Submit_carousel_img(info)
      .then((newImageData) => {
        setProjectformData((prevFields) => {
          const updatedFields = [...prevFields];
          // Replace the specific image in the carousel_data array
          updatedFields[index].carousel_data = updatedFields[
            index
          ].carousel_data.map((item, idx) => {
            // If the index matches the current image index, replace it with the new image data
            if (idx === currentImageIndex) {
              return newImageData[0];
            }
            // Otherwise, return the existing image data
            return item;
          });

          return updatedFields;
        });
        setIscarouselUploading((prevState) => {
          // Stop loading when the upload is done
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      })
      .catch((err) => {
        setIscarouselUploading((prevState) => {
          // Stop loading when the upload is done
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/structuralshapes")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>Project gallery subpage</h1>
            </div>
          </div>
          <Row>
            <Col span={24}>
              <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                className="tab-none"
              >
                <Tabs.TabPane tab="Project gallery section" key="1">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Project Title'</b> please maintain{" "}
                        <b>60 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Accordion Title'</b> please maintain{" "}
                        <b>50 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Accordion Description'</b> please maintain{" "}
                        <b>1200 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Carousel images'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="project"
                    layout="vertical"
                    form={projectform}
                    // initialValues={ProjectformData}
                    // initialValues={{ fields: ProjectformData }}
                    // onValuesChange={handleProjInputChange}
                    // onFinish={onFinish}
                    ref={projectformRef}
                    // onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Project Title"
                          name="main_title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Project Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    {ProjectformData.map(
                      (
                        field,
                        index // Map through banner form fields array
                      ) => (
                        <Row key={field.key}>
                          <Col span={4}>
                            <Form.Item
                              label="Accordion Title"
                              name={[field.key, "title"]}
                              // name="title"
                              initialValue={field.title}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Accordion Title",
                                },
                              ]}
                              onChange={(event) => {
                                const newData = [...ProjectformData];
                                newData[index].title = event.target.value;
                                setProjectformData(newData);
                                setFormEdited(true);
                              }}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name={[field.key, "existing_id"]}
                              initialValue={field.existing_id}
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="Accordion Description"
                              name={[field.key, "Description"]}
                              // name="Description"
                              initialValue={field.Description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Accordion Description",
                                },
                              ]}
                            >
                              <ReactQuill
                                modules={modules}
                                value={field.Description}
                                onChange={(content) => {
                                  if (
                                    cleanHTML(content) !==
                                    cleanHTML(field.Description)
                                  ) {
                                    const newData = [...ProjectformData];
                                    newData[index].Description =
                                      cleanHTML(content);
                                    setProjectformData(newData);
                                    setFormEdited(true);
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              label="Carousel image"
                              name={[field.key, "imageUrl"]}
                              // initialValue={field.imageUrl}
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value ||
                                    ProjectformData[index].carousel_data
                                      .length > 0
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error(
                                            "Please upload Carousel images!"
                                          )
                                        ),
                                },
                              ]}
                            >
                              <div className="banner-cl">
                                {isUploading[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <Carousel
                                    {...settings}
                                    ref={(ref) =>
                                      (carouselRefs.current[index] = ref)
                                    }
                                  >
                                    {ProjectformData[index].carousel_data.map(
                                      (item, imgIndex) => {
                                        return (
                                          <div className="car-cont">
                                            {iscarouselUploading[index] ? (
                                              <Spin
                                                indicator={
                                                  <LoadingOutlined
                                                    className="spin-icon"
                                                    spin
                                                  />
                                                }
                                              />
                                            ) : (
                                              <img
                                                src={`${item.imgurl}`}
                                                alt="carousel-item"
                                                // style={contentStyle}
                                                style={settings}
                                              />
                                            )}

                                            <Button
                                              // icon={<DeleteOutlined />}
                                              icon={
                                                isDeleting[index] ? (
                                                  <LoadingOutlined spin />
                                                ) : (
                                                  <DeleteOutlined />
                                                )
                                              }
                                              onClick={() =>
                                                handleImageDelete(
                                                  index,
                                                  imgIndex
                                                )
                                              }
                                              disabled={imgIndex === 0}
                                            ></Button>
                                            <Upload // Upload component for image
                                              name="avatar"
                                              className="avatar-uploader"
                                              showUploadList={false}
                                              multiple={false}
                                              // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                              onChange={(info) =>
                                                handle_carousel_upload(
                                                  info,
                                                  index
                                                )
                                              } // Handle image upload change
                                            >
                                              <Button
                                                className="edit-button"
                                                icon={<EditOutlined />}
                                                onClick={() =>
                                                  setCurrentImageIndex(imgIndex)
                                                }
                                              />
                                            </Upload>
                                          </div>
                                        );
                                      }
                                    )}
                                  </Carousel>
                                )}

                                {
                                  <Upload // Upload component for image
                                    name="avatar"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    multiple={true}
                                    beforeUpload={() => false}
                                    // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                    onChange={(info) =>
                                      handle_multiple_upload(info, index)
                                    } // Handle image upload change
                                  >
                                    <Button icon={<UploadOutlined />} />
                                  </Upload>
                                }
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label="Alt/Title Text"
                              name={[field.key, "img_alt_txt"]} // Name attribute for form field
                              initialValue={field.img_alt_txt} // Initial value for the field
                              onChange={(event) => {
                                const newData = [...ProjectformData];
                                newData[index].img_alt_txt = event.target.value;
                                setProjectformData(newData);
                                setFormEdited(true);
                              }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={1}>
                            {index > 0 && ( // Render delete button for all fields except the first one
                              <div className="del-btn">
                                <Button
                                  danger
                                  icon={<DeleteOutlined />}
                                  onClick={() =>
                                    handleDeleteProjectFormField(field.key)
                                  }
                                ></Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                      )
                    )}
                    <Row>
                      <Col className="col-pad" span={12}>
                        <Button
                          type="primary"
                          className="add-fields"
                          shape="circle"
                          icon={<PlusOutlined />}
                          onClick={handleAddProjectFormField}
                        />
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => projsave(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                      </Space>
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => projsave(1)}>
                            Save
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Project;
