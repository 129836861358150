import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Save_Homedata,
  get_Banner_Data,
  get_Newshome_Data,
} from "../api/strucshapesApi";
import { Button, Form, Input, Space, Spin, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row } from "antd";
import { Upload, message } from "antd";
import UserData from "./UserData";
import { debounce } from "lodash";

// // home
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { uploadImageAPI } from "../api/bannerApi";

const { Header, Content, Footer } = Layout;

const MyForm = () => {
  const [SeoformData, setSeoFormData] = useState({});
  const [BannerformData, setBannerFormData] = useState([]);
  const [BusineslinesData, setBusineslinesData] = useState([]);
  const [NewsletterData, setNewsletterData] = useState([]);

  const [form] = Form.useForm(); // Using Ant Design Form
  const [Bannerform] = Form.useForm(); // Using Ant Design Form
  const [Businessform] = Form.useForm(); // Using Ant Design Form

  // ---------------newly added for newletter by Sahil -------------- //
  const [Newsletterform] = Form.useForm(); // Using Ant Design Form
  const [NewslettertableData, setNewslettertableData] = useState([]);
  // ---------------newly added for newletter by Sahil -------------- //

  const { TextArea } = Input;
  const [activeTab, setActiveTab] = useState("1");

  const SeoformRef = useRef();
  const userJwtToken = localStorage.getItem("Token");
  const BannerformRef = useRef();
  const [updatedIndices, setUpdatedIndices] = useState([]);

  //banner state variables
  const [bannerFormFields, setBannerFormFields] = useState([
    {
      key: 0,
      order: null,
      title: null,
      indicatorTitle: null,
      imageUrl: null,
      imageId: null,
      existing_img_id: null,
      alttext: null,
    },
  ]);

  const [formSaved, setFormSaved] = useState(false); // State to track whether form has been saved
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  //editor for banner tab
  function handleQuillChangeba(content, key, field) {
    setBannerFormData((prevFields) => {
      // Find the field that needs to be updated
      const fieldIndex = prevFields.findIndex((f) => f.key === key);

      // Make a copy of the previous fields
      const newFields = [...prevFields];

      // Update the specific field
      newFields[fieldIndex] = {
        ...newFields[fieldIndex],
        [field]: content,
      };

      // Return the updated fields
      return newFields;
    });
  }

  // function stripHtml(html) {
  //   const doc = new DOMParser().parseFromString(html, 'text/html');
  //   return doc.body.textContent || "";
  // }

  function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let text = doc.body.textContent || "";
    text = text.replace(/\u00a0/g, " "); // replace non-breaking spaces with regular spaces
    return text;
  }

  const handleAddBannerFormField = () => {
    const maxKey = Math.max(...bannerFormFields.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setBannerFormFields([
      ...bannerFormFields,
      {
        key: newKey,
        order: "",
        title: "",
        indicatorTitle: "",
        imageUrl: "",
        // existing_img_id: "",
        // alttext: "",
      },
    ]);
    setBannerFormData([
      ...BannerformData,
      {
        key: newKey,
        order: "",
        title: "",
        indicatorTitle: "",
        imageUrl: "",
        existing_img_id: "",
        alttext: "",
      },
    ]);
  };
  const [isUploadingbusiness, setIsUploadingbusiness] = useState(
    BusineslinesData.map(() => false)
  );

  const handle_lineitem_upload = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        return;
      }
      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        setIsUploadingbusiness((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBusineslinesData((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].imageUrl = url;
            updatedFields[index].main_img = uploadResponse[0].id;

            return updatedFields;
          });
          if (!updatedIndices.includes(index)) {
            setUpdatedIndices([...updatedIndices, index]);
          }
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          setIsUploadingbusiness((prevState) => {
            // Stop loading when the upload is done
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handleChange = async (info, index) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setBannerFormData((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].imageUrl = url;
        updatedFields[index].main_img = uploadResponse[0].id;

        return updatedFields;
      });
      // Update the form values with the new imageUrl
      BannerformRef.current.setFieldsValue({
        [BannerformData[index].key]: { imageUrl: url }, // Update the existing_img_id field in the form
      });
      // Update uploadStatus for the current index
      //   setUploadStatus((prevStatus) => {
      //     const updatedStatus = [...prevStatus];
      //     updatedStatus[index] = 1;
      //     return updatedStatus;
      //   });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };
  // const [isUploading, setIsUploading] = useState(false);
  const [isUploading, setIsUploading] = useState(
    BannerformData.map(() => false)
  );
  const handleChange1 = async (info, index, e) => {
    // const file = e.target.files[0];
    // const fileType = file.type;
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        // setIsUploading(true); // Start loading when the upload starts
        setIsUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBannerFormData((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].imageUrl = url;
            // updatedFields[index].main_img = uploadResponse[0].id;
            updatedFields[index].file = info.file.originFileObj;

            return updatedFields;
          });
          if (!updatedIndices.includes(index)) {
            setUpdatedIndices([...updatedIndices, index]);
          }
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // setIsUploading(false); // Stop loading when the upload is done
          setIsUploading((prevState) => {
            // Stop loading when the upload is done
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handleDeleteBannerFormField = (key) => {
    const index = BannerformData.findIndex((field) => field.key === key);
    if (index > -1) {
      const existingImgId = BannerformData[index].existing_img_id;
      const currentDeleteImgId =
        BannerformRef.current.getFieldValue("delete_img_id");
      BannerformRef.current.setFieldsValue({
        delete_img_id: currentDeleteImgId
          ? `${currentDeleteImgId}, ${existingImgId}`
          : `${existingImgId},`,
      });
      const newBannerformData = [...BannerformData];
      newBannerformData.splice(index, 1);
      setBannerFormData(newBannerformData);
    }
  };
  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);

    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));

    //   let SiteUrl = ``
    // Render image if 'src' is available, otherwise render a text indicating no image
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const seosave = async (index) => {
    setIsModalVisible(true);
    try {
      await form.validateFields();

      const data = {
        data: {
          seo: {
            meta_description: SeoformData.meta_description,
            meta_keywords: SeoformData.meta_keywords,
            page_title: SeoformData.page_title,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Homedata(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  // save button for banner
  const handleBannerFinish = async (index) => {
    setIsModalVisible(true);

    try {
      await Bannerform.validateFields();

      for (const index of updatedIndices) {
        const formData = new FormData();
        formData.append("files", BannerformData[index].file);

        try {
          // formData.append("files", info.file.originFileObj);
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);

          setBannerFormData((prevFields) => {
            const updatedFields = [...prevFields];
            // updatedFields[index].imageUrl = url;
            updatedFields[index].main_img = uploadResponse[0].id;
            // updatedFields[index].file = info.file.originFileObj;

            return updatedFields;
          });
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }

      setTimeout(() => {

        // setUpdatedIndices([]); // Clear the array after submission

        let setarr = BannerformData.map((item) => {
          return {
            id: item.existing_id,
            title: item.title,
            navigator_text: item.navigator_text,
            main_img: item.main_img,
            // main_img: item.main_img,
            order_no: item.order_no,
            img_alt_title: item.alttext,
          };
        });
        const data = {
          data: {
            banner_section: setarr,
          },
        };

        try {
          Save_Homedata(data, userJwtToken)
            .then((response) => {
              message.success("Form saved successfully!");
              setTimeout(() => {
                // If index is 1, proceed to the prev tab
                // If index is 1, proceed to the next tab
                setFormEdited(false);
                if (index === 0) {
                  const prevTabIndex = parseInt(activeTab) - 1;
                  setActiveTab(prevTabIndex.toString());
                }
                // If index is 1, proceed to the next tab
                if (index === 2) {
                  const nextTabIndex = parseInt(activeTab) + 1;
                  setActiveTab(nextTabIndex.toString());
                }
              }, 500);
            })
            .catch((err) => {
            })
            .finally(() => {
              setIsModalVisible(false); 
            });
        } catch (error) {
          console.error("Failed to post data to the Strapi backend:", error);
          setIsModalVisible(false);
        }
      }, 4000);
    } catch (error) {
      // Handle form validation error
      setIsModalVisible(false);
      console.error("Form validation failed:", error);
    }
  };

  const BusinesslinesSave = async (index) => {
    setIsModalVisible(true);

    try {
      await Businessform.validateFields();

      let setarr = BusineslinesData.map((item) => {
        return {
          id: item.key,
          title_text: item.title_text,
          order_no: item.order_no,
          bg_img: item.main_img,
          img_alt_title: item.alttext,
        };
      });
      const data = {
        data: {
          businessline_block_item: [...setarr],
        },
      };

      try {
        Save_Homedata(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              // If index is 1, proceed to the next tab
              setFormEdited(false);
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the next tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      setIsModalVisible(false);
      console.error("Form validation failed:", error);
    }
  };

  // ---------------newly added for newletter by Sahil------------------- //
  const Newsletter_Save = async (index) => {
    setIsModalVisible(true);

    try {
      await form.validateFields();

      const data = {
        data: {
          newsletter_section: {
            news_head: NewsletterData.news_head,
            news_desc: NewsletterData.news_desc,
          },
        },
      };

      try {
        Save_Homedata(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      setIsModalVisible(false);

      // Handle form validation error
      console.error("Form validation failed:", error);
    }
  };
  // ---------------newly added for newletter by Sahil------------------- //

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_Banner_Data();
      const data = response.data;

      const seoData = {
        meta_keywords: response.data.data.attributes.seo.meta_keywords,
        meta_description: response.data.data.attributes.seo.meta_description,
        page_title: response.data.data.attributes.seo.page_title,
      };

      const Bannerdata = response.data.data.attributes.banner_section;
      const newBannerFormFields = Bannerdata.map((item) => {
        return {
          key: item.id,
          order_no: item.order_no,
          title: item.title,
          navigator_text: item.navigator_text,
          imageUrl: item.main_img.data.attributes.formats.thumbnail.url,
          main_img: item.main_img.data.id,
          existing_id: item.id,
          file: "",
          alttext: item.img_alt_title,
        };
      });

      const Businesslinesdata =
        response.data.data.attributes.businessline_block_item;
      const newBusinessFormFields = Businesslinesdata.map((item) => {
        return {
          key: item.id,
          title_text: item.title_text,
          order_no: item.order_no,
          bg_img: item.bg_img.data.id,
          imageUrl: item.bg_img.data.attributes.formats.thumbnail.url,
          alttext: item.img_alt_title,
        };
      });
      
      const newsletterData = {
        news_head: response.data.data.attributes.newsletter_section.news_head,
        news_desc: response.data.data.attributes.newsletter_section.news_desc,
      };
      //------------set newsletter data--------------
      setNewsletterData(newsletterData);
      Newsletterform.setFieldsValue(newsletterData); // Set form fields value

      //------------set SEO data--------------
      setSeoFormData(seoData);
      form.setFieldsValue(seoData); // Set form fields value

      //-----------set Banner data----------------
      setBannerFormData(newBannerFormFields);
      Bannerform.setFieldValue(newBannerFormFields);

      //---------- set Businesslines data---------

      setBusineslinesData(newBusinessFormFields);
      Businessform.setFieldValue(newBusinessFormFields);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // -----newly added for newletter by Sahil  --------//
  useEffect(() => {
    fetchDataNews();
  }, []);

  const fetchDataNews = async () => {
    try {
      const response = await get_Newshome_Data();

      if (
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        console.error("No data received from API");
        return;
      }

      const data = response.data.data;

      const newsData = data.map((item) => ({
        name: item.attributes.name,
        email: item.attributes.email,
        phone_no: item.attributes.phone_no,
        organization_name: item.attributes.organization_name,
        interest: item.attributes.interest,
      }));

      setNewslettertableData(newsData);
      Newsletterform.setFieldsValue(newsData); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // -----newly added for newletter by Sahil --------//

  const handleSeoInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSeoFormData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleNewsletterInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setNewsletterData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };
  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      const newData = [...BusineslinesData];
      newData[index].order_no = value;
      setBusineslinesData(newData);
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //

  return (
    <Layout>
      <Headercomp />
      <div className="innerbody-pad">
        <div className="main-head">
          <h1>Home</h1>
        </div>
        <Row>
          <Col span={24}>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <Tabs.TabPane tab="SEO" key="1">
                <Form
                  name="seo"
                  layout="vertical"
                  form={form}
                  initialValues={SeoformData}
                  onValuesChange={handleSeoInputChange}
                  onFinish={onFinish}
                  ref={SeoformRef}
                  onChange={() => setFormEdited(true)}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Page Title"
                        name="page_title"
                        rules={[
                          {
                            required: true,
                            message: "Please input Page Title!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Meta Description"
                        name="meta_description"
                        rules={[
                          {
                            required: true,
                            message: "Please input Meta Description!",
                          },
                        ]}
                      >
                        <TextArea rows={4} />
                        {/* <Input  /> */}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Meta Keyword"
                        name="meta_keywords"
                        rules={[
                          {
                            required: true,
                            message: "Please input Meta Keyword!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Existing id" name="existing_id" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button type="primary" onClick={() => seosave(0)}>
                          Save
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={() => seosave(1)}>
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Banner" key="2">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      For the <b>'Banner Title'</b> please maintain{" "}
                      <b>15 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> to avoid
                      text overflow.
                    </li>
                    <li>
                      Please avoid using Same <b>'Banner Order No'</b>.
                    </li>
                    <li>
                      For the <b>'Banner Description'</b> please maintain{" "}
                      <b>60</b> Characters max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> to avoid
                      design break. There should be only one{" "}
                      <b>'heading1 i.e. h1'</b> tag here for SEO.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Banner Image'</b> to exact <b>1600 x 670 px</b> to
                      avoid design break. Please use file types: <b>jpg/png</b>{" "}
                      . Image size less than <b>800 kb</b> is recommended for
                      better performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>

                <Form
                  //   name="banner"
                  //   layout="vertical"
                  //   // onFinish={handleBannerFinish} // Handle form submission
                  //   onChange={() => setFormEdited(true)} // Set formEdited state to true when any field changes
                  ref={BannerformRef} // Reference to the form for accessing form values
                  name="Banner"
                  layout="vertical"
                  form={Bannerform}
                  initialValues={SeoformData}
                  //   onValuesChange={handleSeoInputChange}
                  //   onFinish={onFinish}
                  //   onChange={() => setFormEdited(true)}
                >
                  {BannerformData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      // Each row represents a banner form field
                      <Row key={field.key}>
                        <Col span={4}>
                          <Form.Item
                            label="Banner Title"
                            name={[field.key, "navigator_text"]}
                            initialValue={field.navigator_text}
                            onChange={(event) => {
                              const newData = [...BannerformData];
                              newData[index].navigator_text =
                                event.target.value;
                              setBannerFormData(newData);
                              setFormEdited(true);
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner Title!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label="Existing image id"
                            name={[field.key, "existing_img_id"]}
                            initialValue={field.existing_img_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[field.key, "existing_id"]}
                            initialValue={field.existing_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item
                            label="Banner Order No"
                            name={[field.key, "order_no"]}
                            initialValue={field.order_no}
                            onChange={(event) => {
                              const newData = [...BannerformData];
                              newData[index].order_no = event.target.value;
                              setBannerFormData(newData);
                              setFormEdited(true);
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner Order No!",
                              },
                              // ------------ validation code for unique order number by Sahil------------
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const item = BannerformData.find(
                                    (item, idx) =>
                                      idx !== index &&
                                      item.order_no === Number(value)
                                  ); // ignore the current item during the check
                                  // const item = BannerformData.find((item) => item.order_no === Number(value));
                                  if (!value || !item) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "This Order Number already exists!"
                                    )
                                  );
                                },
                              }),
                              // -------------------------------------------------------------------------
                            ]}
                          >
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Existing image id"
                            name={[field.key, "existing_img_id"]}
                            initialValue={field.existing_img_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[field.key, "existing_id"]}
                            initialValue={field.existing_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={7}>
                          <Form.Item
                            label="Banner Description"
                            name={[field.key, "title"]}
                            initialValue={field.title}
                            style={{ fontWeight: "600", fontSize: "11px" }}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner Description!",
                              },
                              // {
                              //   max: 60,
                              //   message:
                              //     "Banner Description cannot be longer than 60 characters!",
                              // },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (stripHtml(value).length <= 50) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Banner Description cannot be longer than 50 characters!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <ReactQuill
                              modules={modules}
                              value={field.title}
                              onChange={(content) => {
                                if (
                                  cleanHTML(content) !== cleanHTML(field.title)
                                ) {
                                  handleQuillChangeba(
                                    cleanHTML(content),
                                    field.key,
                                    "title"
                                  );
                                  const newData = [...BannerformData];
                                  newData[index].title = cleanHTML(content);
                                  setBannerFormData(newData);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Banner Image"
                            name={[field.key, "imageUrl"]}
                            initialValue={field.imageUrl}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Banner Image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {/* Image preview container */}
                                {isUploading[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <ImagePreview // Component to render image preview
                                    key={BannerformData[index].imageUrl}
                                    imageUrl={BannerformData[index].imageUrl}
                                  />
                                )}
                              </div>
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  // onChange={(info, e) =>
                                  //   handleChange1(info, index, e)
                                  // }
                                  onChange={(info) =>
                                    handleChange1(info, index)
                                  }
                                >
                                  <Button icon={<UploadOutlined />} />
                                  {/* {isUploading[index] ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          className="spin-icon"
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    <Button icon={<UploadOutlined />} />
                                  )} */}
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "alttext"]} // Name attribute for form field
                            initialValue={field.alttext} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...BannerformData];
                              newData[index].alttext = event.target.value;
                              setBannerFormData(newData);
                              setFormEdited(true);
                            }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input Alt/Title Text!",
                            //   }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          {index > 0 && ( // Render delete button for all fields except the first one
                            <div className="del-btn">
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  handleDeleteBannerFormField(field.key)
                                }
                              ></Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col className="col-pad" span={12}>
                      <Button
                        type="primary"
                        className="add-fields"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={handleAddBannerFormField}
                      />
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Our Business Lines" key="3">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      Please avoid using Same <b>'Order No'</b>.
                    </li>
                    <li>
                      <b>30 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Businessline Title'</b> field is recommended.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Main image'</b> to exact{" "}
                      <b>320 x 480 px ( Aspect Ratio 2:3 )</b> to avoid design
                      break. Please use file types: <b>jpg/png</b> .Image size
                      less than <b>500 kb</b> is recommended for better
                      performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  name="Banner"
                  layout="vertical"
                  form={Businessform}
                  initialValues={SeoformData}
                >
                  {BusineslinesData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      // Each row represents a banner form field
                      <Row key={field.key}>
                        <Col span={4}>
                          <Form.Item
                            label="Order No"
                            name={[field.key, "order_no"]}
                            initialValue={field.order_no}
                            rules={[
                              {
                                required: true,
                                message: "Please input Order No!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const item = BusineslinesData.find(
                                    (item, idx) =>
                                      idx !== index &&
                                      item.order_no === Number(value)
                                  );

                                  if (!value || !item) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "This Order Number already exists!"
                                    )
                                  );
                                },
                              }),
                            ]}
                            onChange={(event) => {
                              const newData = [...BusineslinesData];
                              newData[index].order_no = event.target.value;
                              setBusineslinesData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input
                              value={field.order_no}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Businessline Title"
                            name={[field.key, "title_text"]}
                            initialValue={field.title_text}
                            rules={[
                              {
                                required: true,
                                message: "Please input Businessline Title!",
                              },
                            ]}
                            onChange={(event) => {
                              const newData = [...BusineslinesData];
                              newData[index].title_text = event.target.value;
                              setBusineslinesData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            label="Main image"
                            name={[field.key, "imageUrl"]}
                            initialValue={field.imageUrl}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Main image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {/* Image preview container */}
                                {isUploadingbusiness[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <ImagePreview // Component to render image preview
                                    key={BusineslinesData[index].imageUrl}
                                    imageUrl={BusineslinesData[index].imageUrl}
                                  />
                                )}
                              </div>
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  onChange={(info) =>
                                    handle_lineitem_upload(info, index)
                                  } // Handle image upload change
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "alttext"]} // Name attribute for form field
                            initialValue={field.alttext} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...BusineslinesData];
                              newData[index].alttext = event.target.value;
                              setBusineslinesData(newData);
                              setFormEdited(true);
                            }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input Alt/Title Text!",
                            //   }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col className="col-pad" span={12}>
                      <Form.Item
                        label="Deleting image id"
                        name={"delete_img_id"}
                        initialValue=""
                        hidden
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => BusinesslinesSave(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => BusinesslinesSave(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => BusinesslinesSave(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Subscription Block" key="4">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      <b>30 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Title'</b> field is recommended. Use
                      <b>"heading3"</b> for this field.
                    </li>
                    <li>
                      <b>60 Characters</b> max including
                      <b> alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Description'</b> field is recommended. Use
                      <b>"Normal"</b> for this field.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  name="Newsletter"
                  layout="vertical"
                  // form={form}
                  initialValues={NewsletterData}
                  onValuesChange={handleNewsletterInputChange}
                  // onFinish={onFinish}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Title"
                        name="news_head"
                        rules={[
                          {
                            required: true,
                            message: "Please input Title!",
                          },
                        ]}
                      >
                        {/* <Input /> */}
                        <ReactQuill
                          modules={modules}
                          value={NewsletterData.news_head}
                          onChange={(content) => {
                            if (
                              cleanHTML(content) !==
                              cleanHTML(NewsletterData.news_head)
                            ) {
                              setFormEdited(true);
                              handleQuillChangeba(
                                cleanHTML(content),
                                NewsletterData.key,
                                "title"
                              );
                              const newData = NewsletterData;
                              newData.news_head = cleanHTML(content);
                              setNewsletterData(newData);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Description"
                        name="news_desc"
                        rules={[
                          {
                            required: true,
                            message: "Please input Description!",
                          },
                        ]}
                      >
                        {/* <Input /> */}
                        <ReactQuill
                          modules={modules}
                          value={NewsletterData.news_desc}
                          onChange={(content) => {
                            if (
                              cleanHTML(content) !==
                              cleanHTML(NewsletterData.news_desc)
                            ) {
                              setFormEdited(true);
                              handleQuillChangeba(
                                cleanHTML(content),
                                NewsletterData.key,
                                "title"
                              );
                              const newData = NewsletterData;
                              newData.news_desc = cleanHTML(content);
                              setNewsletterData(newData);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* ------newly added for newletter by Sahil ----------------*/}
                  {/* <h4>List of subscribers</h4>
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone no.</th>
                      <th>Organization name</th>
                    </tr>
                    {NewslettertableData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone_no}</td>
                        <td>{item.organization_name}</td>
                      </tr>
                    ))}
                  </table> */}
                  {/* ------newly added for newletter by Sahil ----------------*/}
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => Newsletter_Save(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => Newsletter_Save(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>

      {/* <Footercomp /> */}
    </Layout>
  );
};

export default MyForm;
