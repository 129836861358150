import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import { Save_Flats_buil, get_Flats_buil_Data } from "../../api/flatsApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message, Spin } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row } from "antd";
import UserData from "../UserData";
import { useNavigate } from "react-router-dom";
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
import { Save_Search_ByID, get_Search_Data } from "../../api/strucshapesApi";
const { Header, Content, Footer } = Layout;

function Building() {
  const [BuilformData, setBuilformData] = useState({});
  const [builform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const builformRef = useRef();
  const userJwtToken = localStorage.getItem("Token");
  const [Subpage, SetSubpage] = useState(localStorage.getItem("Subpage"));
  const [Searchdata, Setsearchdata] = useState([]);

  // ----------------------------------------------------- //
  const [isUploading, setIsUploading] = useState(false);

  // function for handling image change for sustainaibility tab
  const handlebuilChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBuilformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          builformRef.current.setFieldsValue({
            [BuilformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  // ----------------------------------------------------- //

  useEffect(() => {
    fetchData();
    Search_Data();
  }, []);

  const Search_Data = async () => {
    const response = await get_Search_Data();
    const data = response.data.data;

    let buildingdata = data.filter(
      (item) => item.attributes.title === "Building"
    );
    Setsearchdata(buildingdata);
  };

  const fetchData = async () => {
    try {
      const response = await get_Flats_buil_Data();
      const data = response.data;

      const builData = {
        title: response.data.data.attributes.building_details.title,
        description: response.data.data.attributes.building_details.description,
        page_url: response.data.data.attributes.building_details.page_url,
        image: response.data.data.attributes.building_details.image.data.id,
        existing_id: response.data.data.attributes.building_details.id,
        imagesUrl:
          response.data.data.attributes.building_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.building_details.img_alt_txt,
      };

      //------------set agriculture data--------------
      setBuilformData(builData);
      builform.setFieldsValue(builData); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBuilInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setBuilformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const builsave = async (index) => {
    setIsModalVisible(true);

    try {
      await builform.validateFields();
      const data = {
        data: {
          building_details: {
            description: BuilformData.description,
            page_url: BuilformData.page_url,
            title: BuilformData.title,
            image: BuilformData.image,
            existing_id: BuilformData.existing_id,
            imagesUrl: BuilformData.imagesUrl,
            img_alt_txt: BuilformData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };
      let search_data = Searchdata;
      search_data[0].attributes.desc[0].text =
        BuilformData.title + BuilformData.description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Flats_buil(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              if (index === 0) {
                navigate("/admin/flats");
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const onFinish = async () => {
    try {
      await builform.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };
  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/flats")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>{Subpage}</h1>
            </div>
          </div>

          <Tabs className="tab-none">
            <Tabs.TabPane tab={Subpage + " Details"} key="1">
              <div className="note-info">
                <h5>NOTE*</h5>
                <ul className="m-0 p-0">
                  <li>
                    For the <b>'Title'</b> please maintain <b>20 Characters</b>{" "}
                    max including <b>alphabets, spaces, symbols and numbers</b>{" "}
                    to avoid line break.
                  </li>
                  <li>
                    For the <b>'Description'</b> please maintain{" "}
                    <b>1000 Characters</b> max including alphabets, spaces,
                    symbols and numbers.
                  </li>
                  <li>
                    Please maintain the image resolution of the <b>'Image'</b>{" "}
                    to exact <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid
                    design break. Please use file types: <b>jpg/png</b> . Image
                    size less than <b>500 kb</b> is recommended for better
                    performance.
                  </li>
                  <li>
                    Please remember to click <b>'Save'</b> after uploading an{" "}
                    <b>image or PDF</b> to ensure your changes are updated.
                  </li>
                </ul>
              </div>
              <Form
                name="building"
                layout="vertical"
                form={builform}
                initialValues={BuilformData}
                onValuesChange={handleBuilInputChange}
                onFinish={onFinish}
                ref={builformRef}
                onChange={() => setFormEdited(true)}
              >
                <Row>
                  <Col span={4}>
                    <Form.Item
                      label="Title"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please input Title!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input Description!",
                        },
                      ]}
                    >
                      {/* <TextArea rows={4} /> */}
                      {/* <Input/> */}
                      <ReactQuill
                        modules={modules}
                        value={BuilformData.description}
                        onChange={(content, delta, source, editor) => {
                          if (BuilformData.description !== undefined) {
                            if (
                              cleanHTML(content) !==
                              cleanHTML(BuilformData.description)
                            ) {
                              handleBuilInputChange({
                                description: editor.getHTML(),
                              });
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="Image"
                      name="image"
                      rules={[
                        {
                          required: true,
                          message: "Please upload an image!",
                        },
                      ]}
                    >
                      <div className="up-del">
                        {/* Container for image upload and delete */}
                        <div className="img-preview">
                          {/* Image preview container */}
                          {isUploading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined className="spin-icon" spin />
                              }
                            />
                          ) : (
                            <ImagePreview // Component to render image preview
                              key={BuilformData.imagesUrl}
                              imageUrl={BuilformData.imagesUrl}
                            />
                          )}
                        </div>
                        {
                          <Upload // Upload component for image
                            name="avatar"
                            className="avatar-uploader"
                            showUploadList={false}
                            // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                            onChange={(info) => handlebuilChange(info)} // Handle image upload change
                          >
                            <Button icon={<UploadOutlined />} />
                          </Upload>
                        }
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="Alt/Title Text"
                      name="img_alt_txt" // Name attribute for form field
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {/* <Col span={6}>
                    <Form.Item
                        label="Page url"
                        name="page_url"
                        rules={[
                          {
                            required: true,
                            message: "Please page url",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      </Col> */}
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Existing image id"
                      name="existing_img_id"
                      hidden
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Existing id" name="existing_id" hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="savebtns">
                  <Space>
                    <Form.Item>
                      <Button type="primary" onClick={() => builsave(0)}>
                        Save & go back
                      </Button>
                    </Form.Item>
                  </Space>
                  <Space>
                    <Form.Item>
                      <Button type="primary" onClick={() => builsave(1)}>
                        Save
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              </Form>
              {isModalVisible && (
                <div className="spinner-loading">
                  {/* <Spin size="large" /> */}
                  <Spin
                    indicator={<LoadingOutlined className="spin-icon" spin />}
                  />
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Building;
