var UserData = (function() {
    var email = "";
    var userId = "";
    var userJwtToken = "";
  
    var getEmail = function() {
        return email;
    };
  
    var getUserId = function() {
        return userId;
    };

    var getJwtToken = function() {
        return userJwtToken;
    };

    var setEmail = function(newEmail) {
        email = newEmail;
    };
  
    var setUserId = function(newUserId) {
        userId = newUserId;
    };
    var setJwtToken = function(newJwtToken) {
        userJwtToken = newJwtToken;
    };

    return {
      getEmail: getEmail,
      getUserId: getUserId,
      getJwtToken: getJwtToken,
      setEmail: setEmail,
      setUserId: setUserId,
      setJwtToken: setJwtToken
    }
  })();
  
  export default UserData;