import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { get_Subscribers_Data } from "../api/bannerApi";
import { Button, Form, Input, Space, Modal } from "antd";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row, Table } from "antd";
import { Upload, message } from "antd";
import UserData from "./UserData";
import { Excel } from "antd-table-saveas-excel";
import { Delete_Subscriberdata } from "../api/newsApi";
// // home

import "react-quill/dist/quill.snow.css"; // import the styles

const { Header, Content, Footer } = Layout;

const Subscribers = () => {
  const [SubscriberData, setSubscriberData] = useState([]);

  const [Subscriberform] = Form.useForm(); // Using Ant Design Form
  const [SubscribertableData, setSubscribertableData] = useState([]);

  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");

  const userJwtToken = localStorage.getItem("Token");

  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  useEffect(() => {
    fetchDataSubscribers();
  }, []);

  const fetchDataSubscribers = async () => {
    try {
      const response = await get_Subscribers_Data();

      if (
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        console.error("No data received from API");
        return;
      }

      const data = response.data.data;

      const newsData = data.map((item) => {
        const interests = item.attributes.interests.map((i) => i.interested_in);

        const interestColumns = {};
        for (let i = 0; i < 6; i++) {
          interestColumns[`Interest${i + 1}`] = interests[i] || "";
        }

        return {
          key: item.id,
          name: item.attributes.name,
          email: item.attributes.email,
          phone: item.attributes.phone,
          org: item.attributes.org,
          ...interestColumns,
        };
      });

      setSubscribertableData(newsData);
      Subscriberform.setFieldsValue(newsData); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = (key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk: async () => {
        setSubscribertableData((prev) =>
          prev.filter((item) => item.key !== key)
        );
        await Delete_Subscriberdata(key, userJwtToken);
        message.success("Record deleted successfully");
      },
      onCancel: () => {
        message.info("Delete operation cancelled");
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Phone no.",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
      title: "Organization name",
      dataIndex: "org",
      key: "org",
      sorter: (a, b) => a.org.localeCompare(b.org),
    },
    {
      title: "Structural Shapes",
      dataIndex: "Interest1",
      key: "Interest1",
      render: (text, record) => {
        return (
          <>
            {record.Interest1 === "Structural Shapes" ||
            record.Interest2 === "Structural Shapes" ||
            record.Interest3 === "Structural Shapes" ||
            record.Interest4 === "Structural Shapes" ||
            record.Interest5 === "Structural Shapes" ||
            record.Interest6 === "Structural Shapes"
              ? "Yes"
              : "No"}
          </>
        );
      },
    },
    {
      title: "Bars and Rods",
      dataIndex: "Interest2",
      key: "Interest2",
      render: (text, record) => {
        return (
          <>
            {record.Interest1 === "Bars and Rods" ||
            record.Interest2 === "Bars and Rods" ||
            record.Interest3 === "Bars and Rods" ||
            record.Interest4 === "Bars and Rods" ||
            record.Interest5 === "Bars and Rods" ||
            record.Interest6 === "Bars and Rods"
              ? "Yes"
              : "No"}
          </>
        );
      },
    },
    {
      title: "Flat Products",
      dataIndex: "Interest3",
      key: "Interest3",
      render: (text, record) => {
        return (
          <>
            {record.Interest1 === "Flat Products" ||
            record.Interest2 === "Flat Products" ||
            record.Interest3 === "Flat Products" ||
            record.Interest4 === "Flat Products" ||
            record.Interest5 === "Flat Products" ||
            record.Interest6 === "Flat Products"
              ? "Yes"
              : "No"}
          </>
        );
      },
    },
    {
      title: "Wire Solutions",
      dataIndex: "Interest5",
      key: "Interest5",
      render: (text, record) => {
        return (
          <>
            {record.Interest1 === "Wire Solutions" ||
            record.Interest2 === "Wire Solutions" ||
            record.Interest3 === "Wire Solutions" ||
            record.Interest4 === "Wire Solutions" ||
            record.Interest5 === "Wire Solutions" ||
            record.Interest6 === "Wire Solutions"
              ? "Yes"
              : "No"}
          </>
        );
      },
    },
    {
      title: "Logistics",
      dataIndex: "Interest6",
      key: "Interest6",
      render: (text, record) => {
        return (
          <>
            {record.Interest1 === "Logistics" ||
            record.Interest2 === "Logistics" ||
            record.Interest3 === "Logistics" ||
            record.Interest4 === "Logistics" ||
            record.Interest5 === "Logistics" ||
            record.Interest6 === "Logistics"
              ? "Yes"
              : "No"}
          </>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "Interest7",
    //   key: "Interest7",
    //   render: (text, record) => {
    //     return(
    //     <>
    //       <Button type="link" danger onClick={() => handleDelete(record.key)}>
    //         Delete
    //       </Button>
    //     </>
    //     );
    //   },
    // },
  ];

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("subscribers_details")
      .addColumns(columns)
      .addDataSource(SubscribertableData, {
        str2Percent: true,
      })
      .saveAs("Subscriber_details.xlsx");
  };

  return (
    <Layout>
      <Headercomp />
      <div className="innerbody-pad">
        <div className="main-head">
          <h1>Subscribers</h1>
        </div>
        <Row>
          <Col span={24}>
            <Tabs
              className="tab-none"
              activeKey={activeTab}
              onChange={handleTabChange}
            >
              <Tabs.TabPane tab="Subscriber Details" key="1">
                <div className="App">
                  <Button
                    type="primary"
                    style={{ float: "inline-start" }}
                    onClick={handleClick}
                  >
                    Export
                  </Button>
                  <Table dataSource={SubscribertableData} columns={columns} />
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>

      {/* <Footercomp /> */}
    </Layout>
  );
};

export default Subscribers;
