import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Save_Wire_wirerope,
  get_Wire_wirerope_Data,
} from "../../api/wiresolnsApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message, Spin } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import React, { useState, useRef, useEffect } from "react";
import { Modal, Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row } from "antd";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import { useNavigate } from "react-router-dom";
import "../CustomLinkBlot";

import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
import copy from "copy-to-clipboard";
import { Save_Search_ByID, get_Search_Data } from "../../api/strucshapesApi";

const { Header, Content, Footer } = Layout;

function Wire() {
  const [WireformData, setWireformData] = useState({});
  const [wireform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const wireformRef = useRef();
  // ---------------------------horizontal--------------------------- //
  const [Section2formData, setSection2formData] = useState({});
  const [section2form] = Form.useForm(); // Using Ant Design Form seo
  const section2formRef = useRef();

  const [Section3formData, setSection3formData] = useState({});
  const [section3form] = Form.useForm(); // Using Ant Design Form seo
  const section3formRef = useRef();
  const ProductformRef = useRef();
  const [Searchdata, Setsearchdata] = useState([]);

  // ------------------------------------------------------- //

  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadedFile, setuploadedFile] = useState("");
  const [uploadedUrl, setuploadedUrl] = useState("");

  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const handleOk = async () => {
    const formData = new FormData();

    formData.append("files", uploadedFile);
    const uploadResponse = await uploadImageAPI(formData, userJwtToken);
    setuploadedUrl(uploadResponse[0].url);
  };

  const copyToClipboard = () => {
    copy(uploadedUrl);
    alert(`You have copied "${uploadedUrl}"`);
  };

  const handleuploadChange = async (info) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      setuploadedFile(info.file.originFileObj);
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };
  // --------------------rope wire-------------------------- //
  const [RopeformData, setRopeformData] = useState({});
  const [ropeform] = Form.useForm(); // Using Ant Design Form seo
  const ropeformRef = useRef();
  const [isUploading, setIsUploading] = useState(false);

  const handlearopeChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setRopeformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          ropeformRef.current.setFieldsValue({
            [RopeformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handleRopeInputChange = (changedValues, allValues) => {
    // setFormEdited(true);
    setRopeformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isspinVisible, setIsspinVisible] = useState(false);

  const navigate = useNavigate();

  const ropesave = async (index) => {
    setIsspinVisible(true);

    try {
      await ropeform.validateFields();
      const data = {
        data: {
          section2_details: {
            description: RopeformData.description,
            page_url: RopeformData.page_url,
            title: RopeformData.title,
            image: RopeformData.image,
            existing_id: RopeformData.existing_id,
            imagesUrl: RopeformData.imagesUrl,
            img_alt_txt: RopeformData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[4].text =
        RopeformData.title + RopeformData.description;

      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Wire_wirerope(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsspinVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsspinVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsspinVisible(false);
    }
  };
  // ---------------------------------------------- //
  // --------------------------product----------------------------- //
  const [ProductformData, setProductFormData] = useState([]);
  const [Productform] = Form.useForm(); // Using Ant Design Form

  // function for deleting business line form field
  const handleDeleteProductFormField = (key) => {
    const index = ProductformData.findIndex((field) => field.key === key);
    if (index > -1) {
      const existingImgId = ProductformData[index].existing_img_id;
      const currentDeleteImgId =
        ProductformRef.current.getFieldValue("delete_img_id");
      ProductformRef.current.setFieldsValue({
        delete_img_id: currentDeleteImgId
          ? `${currentDeleteImgId}, ${existingImgId}`
          : `${existingImgId},`,
      });
      const newProductformData = [...ProductformData];
      newProductformData.splice(index, 1);
      setProductFormData(newProductformData);
    }
  };

  function handleQuillChangepr(content, key, field) {
    // Assuming BannerformData is part of your component's state
    setProductFormData((prevFields) => {
      // Find the field that needs to be updated
      const fieldIndex = prevFields.findIndex((f) => f.key === key);

      // Make a copy of the previous fields
      const newFields = [...prevFields];

      // Update the specific field
      newFields[fieldIndex] = {
        ...newFields[fieldIndex],
        [field]: content,
      };

      // Return the updated fields
      return newFields;
    });
  }

  // save button for business line handleBusinessfinish
  const handleProductfinish = async (index) => {
    setIsspinVisible(true);
    try {
      await Productform.validateFields();
      const data = {
        data: {
          wiresol_accord: ProductformData.map((item) => ({
            title: item.title,
            description: item.description,
          })),
        },
      };

      try {
        Save_Wire_wirerope(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsspinVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsspinVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsspinVisible(false); // Stop loading when API call is finished
    }
  };

  const productinputChange = (arrayIndex, field, value) => {
    let newProductformData = [...ProductformData];
    newProductformData[arrayIndex][field] = value;
    setProductFormData(newProductformData);
  };

  const handleAddProductFormField = () => {
    const maxKey = Math.max(...ProductformData.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setProductFormData([
      ...ProductformData,
      {
        key: newKey,
        title: "",
        description: "",
      },
    ]);
  };
  // ------------------------------------------------------- //
  const [iswireUploading, setIswireUploading] = useState(false);

  // function for handling image change for sustainaibility tab
  const handleawireChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIswireUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setWireformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imagesUrl = url;
            updatedFields.image = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          wireformRef.current.setFieldsValue({
            [WireformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIswireUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
    Search_Data();
  }, []);

  const Search_Data = async () => {
    const response = await get_Search_Data();
    const data = response.data.data;

    let fenchingdata = data.filter(
      (item) => item.attributes.title === "Wire Ropes"
    );
    Setsearchdata(fenchingdata);
  };
  const fetchData = async () => {
    try {
      const response = await get_Wire_wirerope_Data();
      const data = response.data;

      const wireData = {
        title: response.data.data.attributes.section1_details.title,
        description: response.data.data.attributes.section1_details.description,
        page_url: response.data.data.attributes.section1_details.page_url,
        image: response.data.data.attributes.section1_details.image.data.id,
        existing_id: response.data.data.attributes.section1_details.id,
        imagesUrl:
          response.data.data.attributes.section1_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.section1_details.img_alt_txt,
      };
      // ------------------------------horizontal data---------------------------- //
      const section2Data = {
        left_description:
          response.data.data.attributes.horizontal_content.left_description,
        right_description:
          response.data.data.attributes.horizontal_content.right_description,
      };

      // ----------------------------rope------------------------------ //
      const ropeData = {
        title: response.data.data.attributes.section2_details.title,
        description: response.data.data.attributes.section2_details.description,
        page_url: response.data.data.attributes.section2_details.page_url,
        image: response.data.data.attributes.section2_details.image.data.id,
        existing_id: response.data.data.attributes.section2_details.id,
        imagesUrl:
          response.data.data.attributes.section2_details.image.data.attributes
            .formats.thumbnail.url,
        img_alt_txt: response.data.data.attributes.section2_details.img_alt_txt,
      };
      // ---------------------------------------------------------- //
      // ----------------------------product------------------------------ //
      const Productdata = response.data.data.attributes.wiresol_accord;
      const newProductFormFields = Productdata.map((item) => {
        return {
          key: item.id,
          title: item.title,
          description: item.description,
        };
      });
      // ----------------------------------------------------------------- //
      //------------set  wire data--------------
      setWireformData(wireData);
      wireform.setFieldsValue(wireData); // Set form fields value
      //------------set horizontal data--------------
      setSection2formData(section2Data);
      section2form.setFieldsValue(section2Data); // Set form fields value
      //------------set rope data--------------
      setRopeformData(ropeData);
      ropeform.setFieldsValue(ropeData); // Set form fields value
      //-----------set Product data----------------
      setProductFormData(newProductFormFields);
      Productform.setFieldValue(newProductFormFields);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleWireInputChange = (changedValues, allValues) => {
    // setFormEdited(true);
    setWireformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const horiipchange = (changedValues, allValues) => {
    // setFormEdited(true);
    setSection2formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const wiresave = async (index) => {
    setIsspinVisible(true); // Stop loading when API call is finished

    try {
      await wireform.validateFields();
      const data = {
        data: {
          section1_details: {
            description: WireformData.description,
            page_url: WireformData.page_url,
            title: WireformData.title,
            image: WireformData.image,
            existing_id: WireformData.existing_id,
            imagesUrl: WireformData.imagesUrl,
            img_alt_txt: WireformData.img_alt_txt,
            // id: values.existing_id,
          },
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[0].text =
        WireformData.title + WireformData.description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Wire_wirerope(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            localStorage.setItem("formEdited", "false");
            setFormEdited(false);
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsspinVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsspinVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsspinVisible(false); // Stop loading when API call is finished
    }
  };
  // --------------------------------------------------- //
  const horisave = async (index) => {
    setIsspinVisible(true); // Stop loading when API call is finished

    try {
      await section2form.validateFields();
      const data = {
        data: {
          horizontal_content: {
            left_description: Section2formData.left_description,
            right_description: Section2formData.right_description,
          },
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[2].text =
        Section2formData.left_description;
      search_data[0].attributes.desc[3].text =
        Section2formData.right_description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };
      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Wire_wirerope(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsspinVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsspinVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsspinVisible(false); // Stop loading when API call is finished
    }
  };

  // --------------------------------------------------- //

  const onFinish = async () => {
    try {
      await wireform.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            {/* <h1>Wire Ropes</h1> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/wiresolns")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>Wire Ropes</h1>
            </div>
          </div>
          <Row>
            {/* <Modal
              title="Upload & Generate Link"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={() => {
                setIsModalVisible(false);
                setuploadedFile("");
                setuploadedUrl("");
              }}
              okText="Upload"
              footer={null}
            >
              {uploadedFile === "" && uploadedUrl === "" && (
                <Form
                  // form={form}
                  // initialValues={editingRecord}
                  layout="vertical"
                  // onValuesChange={handleInputChange}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        width: 200,
                        height: 200,
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Upload // Upload component for image
                        name="avatar"
                        className="avatar-uploader"
                        showUploadList={false}
                        style={{ width: "100px", height: "100px" }}
                        // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                        onChange={(info) => handleuploadChange(info)} // Handle image upload change
                      >
                        <Button
                          style={{
                            width: 100,
                            height: 100,
                            marginTop: 20,
                          }}
                          icon={<UploadOutlined />}
                        />
                      </Upload>
                    </Col>
                  </Row>
                </Form>
              )}

              {uploadedFile !== "" && uploadedUrl === "" && (
                <div>
                  <div>
                    <h4>File name:</h4>
                    <p>{uploadedFile.name}</p>
                  </div>
                  <div>
                    <Button onClick={() => handleOk()}>Upload</Button>
                  </div>
                </div>
              )}

              {uploadedUrl !== "" && (
                <div>
                  <Input
                    type="text"
                    disabled
                    value={uploadedUrl}
                    placeholder="Enter the text you want to copy"
                  />

                  <Button onClick={copyToClipboard}>Copy to Clipboard</Button>
                </div>
              )}
            </Modal> */}
            <Col span={24}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="Wire ropes content" key="1">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="section1wireropes"
                    layout="vertical"
                    form={wireform}
                    initialValues={WireformData}
                    onValuesChange={handleWireInputChange}
                    onFinish={onFinish}
                    ref={wireformRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={WireformData.description}
                            onChange={(content, delta, source, editor) => {
                              if (WireformData.description !== undefined) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(WireformData.description)
                                ) {
                                  handleWireInputChange({
                                    description: editor.getHTML(),
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {iswireUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={WireformData.imagesUrl}
                                  imageUrl={WireformData.imagesUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handleawireChange(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => wiresave(0)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => wiresave(1)}>
                            Save & Continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isspinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Product Applications" key="2">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                    </ul>
                  </div>
                  <Form
                    ref={ProductformRef} // Reference to the form for accessing form values
                    name="Product"
                    layout="vertical"
                    form={Productform}
                    initialValues={ProductformData}
                    // onValuesChange={handleSeoInputChange}
                    // onFinish={onFinish}
                    onChange={() => setFormEdited(true)}
                  >
                    {ProductformData.map(
                      (
                        field,
                        index // Map through banner form fields array
                      ) => (
                        <Row key={field.key}>
                          <Col span={4}>
                            <Form.Item
                              label="Title"
                              name={[field.key, "title"]}
                              initialValue={field.title}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Title!",
                                },
                              ]}
                            >
                              <Input
                                onChange={(e) =>
                                  productinputChange(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              label="Existing image id"
                              name={[field.key, "existing_img_id"]}
                              initialValue={field.existing_img_id}
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name={[field.key, "existing_id"]}
                              initialValue={field.existing_id}
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={18}>
                            <Form.Item
                              label="Description"
                              name={[field.key, "description"]}
                              initialValue={field.description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Description!",
                                },
                              ]}
                            >
                              {/* <Input
                              onChange={(e) =>
                                productinputChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                            /> */}
                              <ReactQuill
                                modules={modules}
                                value={field.description}
                                onChange={(content) => {
                                  if (
                                    cleanHTML(content) !==
                                    cleanHTML(field.description)
                                  ) {
                                    setFormEdited(true);
                                    productinputChange(
                                      index,
                                      "description",
                                      cleanHTML(content)
                                    );
                                  }
                                  // handleQuillChangepr(
                                  //   content,
                                  //   field.key,
                                  //   "description"
                                  // );
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={2}>
                            {index > 0 && ( // Render delete button for all fields except the first one
                              <div className="del-btn">
                                <Button
                                  danger
                                  icon={<DeleteOutlined />}
                                  onClick={() =>
                                    handleDeleteProductFormField(field.key)
                                  }
                                ></Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                      )
                    )}
                    <Row>
                      <Col className="col-pad" span={12}>
                        <Button
                          type="primary"
                          className="add-fields"
                          shape="circle"
                          icon={<PlusOutlined />}
                          onClick={handleAddProductFormField}
                        />
                      </Col>
                      <Col className="col-pad" span={12}>
                        <Form.Item
                          label="Deleting image id"
                          name={"delete_img_id"}
                          initialValue=""
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => handleProductfinish(0)}
                          >
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => handleProductfinish(1)}
                          >
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => handleProductfinish(2)}
                          >
                            Save & Continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isspinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Horizontal content" key="3">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        <b>400 Characters</b> max, including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                        <b>'Left Description'</b> and <b>'Right Description'</b>{" "}
                        fields is recommended.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="xcarb1"
                    layout="vertical"
                    form={section2form}
                    initialValues={Section2formData}
                    onValuesChange={horiipchange}
                    onFinish={onFinish}
                    ref={section2formRef}
                    // onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label=" Left Description"
                          name="left_description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Left Description!",
                            },
                          ]}
                        >
                          <ReactQuill
                            modules={modules}
                            value={Section2formData.left_description}
                            onChange={(content, delta, source, editor) => {
                              if (
                                Section2formData.left_description !== undefined
                              ) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(Section2formData.left_description)
                                ) {
                                  setFormEdited(true);
                                  setSection2formData((prv) => {
                                    prv.left_description = cleanHTML(content);
                                    return prv;
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label=" Right Description"
                          name="right_description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Right Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Section2formData.right_description}
                            onChange={(content, delta, source, editor) => {
                              if (
                                Section2formData.right_description !== undefined
                              ) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(Section2formData.right_description)
                                ) {
                                  setFormEdited(true);
                                  setSection2formData((prv) => {
                                    prv.right_description = cleanHTML(content);
                                    return prv;
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => horisave(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => horisave(1)}>
                            Save
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => horisave(2)}>
                            Save & Continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isspinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane tab="Ropes brochure" key="4">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1200 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Only <b>pdf file</b> type is allowed for the Upload
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                    </ul>
                  </div>

                  <Form
                    name="ropebrochure"
                    layout="vertical"
                    style={{ marginTop: 20 }}
                    form={ropeform}
                    initialValues={RopeformData}
                    onValuesChange={handleRopeInputChange}
                    onFinish={onFinish}
                    ref={ropeformRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={RopeformData.description}
                            onChange={(content, delta, source, editor) => {
                              if (RopeformData.description !== undefined) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(RopeformData.description)
                                ) {
                                  setFormEdited(true);
                                  handleRopeInputChange({
                                    description: cleanHTML(content),
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="image"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            <div className="img-preview">
                              {isUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={RopeformData.imagesUrl}
                                  imageUrl={RopeformData.imagesUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handlearopeChange(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => ropesave(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => ropesave(1)}>
                            Save
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isspinVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Wire;
