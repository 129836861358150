import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import { Save_Flats_sust, get_Flats_sust_Data } from "../../api/flatsApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Tooltip, Col, Row, Spin } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import { useNavigate } from "react-router-dom";
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
import { Save_Search_ByID, get_Search_Data } from "../../api/strucshapesApi";
const { Header, Content, Footer } = Layout;

function Sustainaibilitysubpage() {
  const [SustformData, setSustformData] = useState({});
  const [sustform] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const sustformRef = useRef();
  // ------------------------------------------------------ //
  const [Xcarb1formData, setXcarb1formData] = useState({});
  const [xcarb1form] = Form.useForm(); // Using Ant Design Form seo
  const xcarb1formRef = useRef();

  const [Xcarb2formData, setXcarb2formData] = useState({});
  const [Xcarb2PdfData, setXcarb2PdfData] = useState([]);
  const [xcarb2form] = Form.useForm(); // Using Ant Design Form seo
  const [xcarb2pdfform] = Form.useForm();
  const xcarb2formRef = useRef();
  const [Subpage, SetSubpage] = useState(localStorage.getItem("Subpage"));

  const [xcarb1File, setxcarb1File] = useState("");
  const [xcarb2Files, setxcarb2Files] = useState([]);

  // ------------------------------------------------------- //
  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");
  const [Searchdata, Setsearchdata] = useState([]);

  const handlecontactTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActivecontactTab(key);
    }
  };

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const handleAddattachmentFormField = () => {
    // let attch = Xcarb2formData;
    let data = [...Xcarb2PdfData];
    let obj = { title: "", pdf: "", pdfid: null };

    setXcarb2PdfData((prv) => [...prv, obj]);
  };

  const handleDeleteattachmentFormField = (field, index) => {
    const newpdfData = [...Xcarb2PdfData];
    newpdfData.splice(index, 1);
    // setBannerformData(newBannerformData);
    setXcarb2PdfData(newpdfData);
  };
  const [issustUploading, setIssustUploading] = useState(false);

  // function for handling image change for sustainaibility tab
  const handlesustChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIssustUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setSustformData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.file = info.file.originFileObj;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
          sustformRef.current.setFieldsValue({
            [SustformData.key]: { imagesUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIssustUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  // ------------------------------------------------------- //

  const [isxcarb1Uploading, setIsxcarb1Uploading] = useState(false);

  // function for handling image change for xcarb tab
  const handlexcarb1Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        return;
      }
      setIsxcarb1Uploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setXcarb1formData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.file = info.file.originFileObj;
            return updatedFields;
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsxcarb1Uploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  const [isxcarb2Uploading, setIsxcarb2Uploading] = useState(false);

  const handlexcarb2Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        return;
      }
      setIsxcarb2Uploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setXcarb2formData((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.file = info.file.originFileObj;
            return updatedFields;
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsxcarb2Uploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };
  const [isPdfUploading, setIsPdfUploading] = useState(false);
  const handleFileChange = (e) => {
    setxcarb1File(e.target.files[0]);
    setXcarb1formData((prevFields) => {
      const updatedFields = { ...prevFields };
      updatedFields.pdfname = e.target.files[0].name;
      return updatedFields;
    });
  };

  const handleFileChange_xcarb2 = (e, index) => {
    let obj = { index: index, file: e.target.files[0] };
    setxcarb2Files((prv) => [...prv, obj]);
    setXcarb2PdfData((prv) => {
      prv[index].pdfid = 0;
      prv[index].pdf.data.attributes.name = e.target.files[0].name;

      return prv;
    });
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", File);
    const uploadResponse = await uploadPdfAPI(formData, userJwtToken);
  };
  // ------------------------------------------------------- //

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);

    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
    Search_Data();
  }, []);

  const Search_Data = async () => {
    const response = await get_Search_Data();
    const data = response.data.data;

    let flat_sustaindata = data.filter(
      (item) => item.attributes.title === "Sustainability(Flats)"
    );
    Setsearchdata(flat_sustaindata);
  };

  const fetchData = async () => {
    try {
      const response = await get_Flats_sust_Data();
      const data = response.data;

      let Section1_data = response.data.data.attributes.sustainaibility_details;
      let Section2_data = response.data.data.attributes.xcarbs_details_1;
      let Section3_data = response.data.data.attributes.xcarbs_details_2;

      let sustData = {
        title: Section1_data.title,
        img_alt_txt: Section1_data.img_alt_txt,
        description: Section1_data.Description,
        imageUrl: Section1_data.image.data.attributes.formats.thumbnail.url,
        main_img: Section1_data.image.data.id,
        file: "",
      };

      let xcarb1Data = {
        title: Section2_data.title,
        description: Section2_data.description,
        imageUrl: Section2_data.image.data.attributes.formats.thumbnail.url,
        main_img: Section2_data.image.data.id,
        file: "",
        pdfUrl: "",
        pdfname: Section2_data.pdf_items["0"].pdf.data.attributes.name,
        pdfFile: "",
        attachment: Section2_data.pdf_items,
        img_alt_txt: Section2_data.img_alt_txt,
      };
      let xcarb2Data = {
        title: Section3_data.title,
        description: Section3_data.description,
        imageUrl: Section3_data.image.data.attributes.formats.thumbnail.url,
        main_img: Section3_data.image.data.id,
        file: "",
        attachment: Section3_data.pdf_items,
        img_alt_txt: Section3_data.img_alt_txt,
      };

      let sec3pdf = Section3_data.pdf_items.map((item) => {
        let obj = {
          id: item.id,
          title: item.title,
          pdf: item.pdf,
          pdfid: JSON.stringify(item.pdf.data.id),
        };
        return obj;
      });

      setXcarb2PdfData(sec3pdf);
      setTimeout(() => {}, 3000);

      //------------set agriculture sustainaibility data--------------
      setSustformData(sustData);
      sustform.setFieldsValue(sustData); // Set form fields value
      //------------set agriculture xcarb1 data--------------
      setXcarb1formData(xcarb1Data);
      xcarb1form.setFieldsValue(xcarb1Data); // Set form fields value
      //------------set agriculture xcarb2 data--------------
      setXcarb2formData(xcarb2Data);
      xcarb2form.setFieldsValue(xcarb2Data); // Set form fields value
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSustInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSustformData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleXcarb1InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setXcarb1formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleXcarb2InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setXcarb2formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const sustsave = async (index) => {
    setIsModalVisible(true);

    try {
      await sustform.validateFields();
      let data = [];

      if (SustformData.file !== "") {
        const formData = new FormData();
        formData.append("files", SustformData.file);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          setSustformData((prevFields) => {
            // const updatedFields = [...prevFields];
            // updatedFields[index].imageUrl = url;
            prevFields.main_img = uploadResponse[0].id;
            prevFields.file = "";

            return prevFields;
          });
          data = {
            data: {
              sustainaibility_details: {
                Description: SustformData.description,
                // page_url: SustformData.page_url,
                title: SustformData.title,
                image: SustformData.main_img,
                existing_id: SustformData.existing_id,
                imagesUrl: SustformData.imagesUrl,
                img_alt_txt: SustformData.img_alt_txt,
                // id: values.existing_id,
              },
            },
          };
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      } else {
        data = {
          data: {
            sustainaibility_details: {
              Description: SustformData.description,
              // page_url: SustformData.page_url,
              title: SustformData.title,
              image: SustformData.main_img,
              existing_id: SustformData.existing_id,
              imagesUrl: SustformData.imagesUrl,
              img_alt_txt: SustformData.img_alt_txt,
              // id: values.existing_id,
            },
          },
        };
      }

      let search_data = Searchdata;
      search_data[0].attributes.desc[0].text =
        SustformData.title + SustformData.description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };

      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Flats_sust(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            if (index === 2) {
              const nextTabIndex = parseInt(activeTab) + 1;
              setActiveTab(nextTabIndex.toString());
            }
            setTimeout(() => {}, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  // --------------------------------------------------- //
  const xcarb1save = async (index) => {
    setIsModalVisible(true);

    try {
      await xcarb1form.validateFields();

      let data = [];
      let ispdf_upload = 0;

      if (Xcarb1formData.file !== "" || xcarb1File !== "") {
        if (Xcarb1formData.file !== "") {
          const formData = new FormData();
          formData.append("files", Xcarb1formData.file);
          try {
            const uploadResponse = await uploadImageAPI(formData, userJwtToken);
            setXcarb1formData((prevFields) => {
              // const updatedFields = [...prevFields];
              // updatedFields[index].imageUrl = url;
              prevFields.main_img = uploadResponse[0].id;
              prevFields.file = "";

              return prevFields;
            });
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }

        if (xcarb1File !== "") {
          const formData = new FormData();
          formData.append("files", xcarb1File);
          try {
            const uploadResponse = await uploadImageAPI(formData, userJwtToken);
            setXcarb1formData((prevFields) => {
              // const updatedFields = [...prevFields];
              // updatedFields[index].imageUrl = url;
              prevFields.attachment = [
                { title: "pdf item", pdf: uploadResponse[0].id },
              ];
              setxcarb1File("");
              ispdf_upload = 1;

              return prevFields;
            });
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }

        data = {
          data: {
            xcarbs_details_1: {
              description: Xcarb1formData.description,
              title: Xcarb1formData.title,
              image: Xcarb1formData.main_img,
              img_alt_txt: Xcarb1formData.img_alt_txt,
              // existing_id: Xcarb1formData.existing_id,
              // imagexcarb1Url: Xcarb1formData.imagexcarb1Url,
              pdf_items:
                ispdf_upload === 0
                  ? Xcarb1formData.attachment.map((item) => {
                      return { title: item.title, pdf: item.pdf.data.id };
                    })
                  : Xcarb1formData.attachment,
            },
          },
        };
      } else {
        let arr_pdf_item = Xcarb1formData.attachment.map((item) => {
          return { title: item.title, pdf: item.pdf.data.id };
        });

        data = {
          data: {
            xcarbs_details_1: {
              description: Xcarb1formData.description,
              title: Xcarb1formData.title,
              image: Xcarb1formData.main_img,
              img_alt_txt: Xcarb1formData.img_alt_txt,
              // existing_id: Xcarb1formData.existing_id,
              // imagexcarb1Url: Xcarb1formData.imagexcarb1Url,
              pdf_items: arr_pdf_item,
            },
          },
        };
      }

      let search_data = Searchdata;

      search_data[0].attributes.desc[1].text =
        Xcarb1formData.title + Xcarb1formData.description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };

      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Flats_sust(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            if (index === 0) {
              const prevTabIndex = parseInt(activeTab) - 1;
              setActiveTab(prevTabIndex.toString());
            }
            if (index === 2) {
              const nextTabIndex = parseInt(activeTab) + 1;
              setActiveTab(nextTabIndex.toString());
            }
            setTimeout(() => {}, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const xcarb2save = async (type, index) => {
    setIsModalVisible(true);

    let data = [];
    let pdfarr = Xcarb2PdfData.map((item) => {
      item.pdf = item.pdf.data ? item.pdf.data.id : item.pdf;
      return item;
    });
    if (type === 1) {
      try {
        await xcarb2form.validateFields();

        if (Xcarb2formData.file !== "") {
          if (Xcarb2formData.file !== "") {
            const formData = new FormData();
            formData.append("files", Xcarb2formData.file);
            try {
              const uploadResponse = await uploadImageAPI(
                formData,
                userJwtToken
              );
              setXcarb2formData((prevFields) => {
                // const updatedFields = [...prevFields];
                // updatedFields[index].imageUrl = url;
                prevFields.main_img = uploadResponse[0].id;
                prevFields.file = "";

                return prevFields;
              });
            } catch (error) {
              console.error("Error uploading image:", error);
              setIsModalVisible(false);
            }
          }
        }
        data = {
          data: {
            xcarbs_details_2: {
              description: Xcarb2formData.description,
              // page_url: Xcarb2formData.page_url,
              title: Xcarb2formData.title,
              image: Xcarb2formData.main_img,
              img_alt_txt: Xcarb2formData.img_alt_txt,
              // existing_id: Xcarb2formData.existing_id,
              // imagexcarb2Url: Xcarb2formData.imagexcarb2Url,
              pdf_items: Xcarb2PdfData.map((item) => {
                item.pdf =
                  item.pdf.data === undefined ? item.pdf : item.pdf.data.id;
                return item;
              }),
              // id: values.existing_id,
            },
          },
        };
      } catch (error) {
        setIsModalVisible(false);

        return;
      }
    } else if (type === 2) {
      try {
        await xcarb2pdfform.validateFields();
        if (xcarb2Files.length > 0) {
          for (const item of xcarb2Files) {
            const formData = new FormData();
            formData.append("files", item.file);

            try {
              const uploadResponse = await uploadImageAPI(
                formData,
                userJwtToken
              );

              pdfarr[item.index].pdf = uploadResponse[0].id;
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        }
        data = {
          data: {
            xcarbs_details_2: {
              description: Xcarb2formData.description,
              title: Xcarb2formData.title,
              image: Xcarb2formData.main_img,
              img_alt_txt: Xcarb2formData.img_alt_txt,
              pdf_items: pdfarr,
              // id: values.existing_id,
            },
          },
        };
      } catch (error) {
        return;
      }
    }
    setTimeout(() => {
      try {
        let search_data = Searchdata;
        search_data[0].attributes.desc[2].text =
          Xcarb2formData.title + Xcarb2formData.description;
        const searchparams = {
          data: {
            desc:
              search_data[0].attributes.title + search_data[0].attributes.desc,
          },
        };
        try {
          Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
            .then((response) => {})
            .catch((err) => {})
            .finally(() => {});
          Save_Flats_sust(data, userJwtToken)
            .then((response) => {
              setFormEdited(false);

              message.success("Form saved successfully!");
              setFormEdited(false);
              fetchData();
              setTimeout(() => {
                setFormEdited(false);
                if (type === 1 && index === 1) {
                  // const prevTabIndex = parseInt(activeTab) - 1;
                  setActivecontactTab("2");
                } else if (type === 2 && index === 0) {
                  // const prevTabIndex = parseInt(activeTab) - 1;
                  setActivecontactTab("1");
                }
              }, 500);
            })
            .catch((err) => {})
            .finally(() => {
              setIsModalVisible(false);
            });
        } catch (error) {
          console.error("Failed to post data to the Strapi backend:", error);
          setIsModalVisible(false);
        }
      } catch (error) {
        // Handle form validation error
        console.error("Form validation failed:", error);
        setIsModalVisible(false);
      }
    }, 3000);
  };
  // --------------------------------------------------- //

  const onFinish = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const triggerFileInput = (index) => {
    document.getElementById(index + "pdfupload").click();
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };
  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/flats")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>{Subpage}</h1>
            </div>
          </div>
          <Row>
            <Col span={24}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane
                  tab={Subpage + " Details"}
                  // tab="Sustainability details"
                  key="1"
                >
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1000 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png </b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="sustainaibility"
                    layout="vertical"
                    form={sustform}
                    initialValues={SustformData}
                    onValuesChange={handleSustInputChange}
                    onFinish={onFinish}
                    ref={sustformRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={4}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={SustformData.description}
                            onChange={(content, delta, source, editor) => {
                              if (SustformData.description !== undefined) {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(SustformData.description)
                                ) {
                                  handleSustInputChange({
                                    description: editor.getHTML(),
                                  });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          label="Image"
                          name="main_img"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {" "}
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {" "}
                              {/* Image preview container */}
                              {issustUploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={SustformData.imageUrl}
                                  imageUrl={SustformData.imageUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handlesustChange(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}
                    </Row>

                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => sustsave(1)}>
                            Save
                          </Button>
                        </Form.Item>
                      </Space>

                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => sustsave(2)}>
                            Save & continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="xcarb 1 details" key="2">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        For the <b>'Title'</b> please maintain{" "}
                        <b>20 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> to avoid
                        line break.
                      </li>
                      <li>
                        For the <b>'Description'</b> please maintain{" "}
                        <b>1000 Characters</b>
                        max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b>.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Image'</b> to exact{" "}
                        <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Only <b>pdf file</b> type is allowed for the <b>PDF</b>{" "}
                        field . Max file should be less than <b>50 mb</b> .
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  <Form
                    name="xcarb1"
                    layout="vertical"
                    form={xcarb1form}
                    initialValues={Xcarb1formData}
                    onValuesChange={handleXcarb1InputChange}
                    onFinish={onFinish}
                    ref={xcarb1formRef}
                    onChange={() => setFormEdited(true)}
                  >
                    <Row>
                      <Col span={3}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please input Title!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Please input Description!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <ReactQuill
                            modules={modules}
                            value={Xcarb1formData.description}
                            onChange={(content, delta, source, editor) => {
                              if (
                                cleanHTML(content) !==
                                cleanHTML(Xcarb1formData.description)
                              ) {
                                handleXcarb1InputChange({
                                  description: editor.getHTML(),
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={3}>
                        <Form.Item
                          label="Image"
                          name="main_img"
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <div className="up-del">
                            {/* Container for image upload and delete */}
                            <div className="img-preview">
                              {/* Image preview container isxcarb1Uploading */}
                              {isxcarb1Uploading ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <ImagePreview // Component to render image preview
                                  key={Xcarb1formData.imageUrl}
                                  imageUrl={Xcarb1formData.imageUrl}
                                />
                              )}
                            </div>
                            {
                              <Upload // Upload component for image
                                name="avatar"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                onChange={(info) => handlexcarb1Change(info)} // Handle image upload change
                              >
                                <Button icon={<UploadOutlined />} />
                              </Upload>
                            }
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          label="Alt/Title Text"
                          name="img_alt_txt" // Name attribute for form field
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                        <Form.Item
                          label="Page url"
                          name="page_url"
                          rules={[
                            {
                              required: true,
                              message: "Please page url",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col> */}

                      <Col span={6}>
                        <Form.Item
                          label="Pdf"
                          name="pdfname"
                          // initialValue={field.pdfid} // initialValue={}
                          rules={[
                            {
                              required: true,
                              message: "Please upload Attachment!",
                            },
                          ]}
                        >
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange(e)}
                              // onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="xcarb1pdfupload"
                            />
                            <label>
                              <Button
                                className="btn btn-primary"
                                onClick={() =>
                                  // triggerFileInput(index)
                                  document
                                    .getElementById("xcarb1pdfupload")
                                    .click()
                                }
                              >
                                Upload PDF
                              </Button>
                            </label>

                            <div>
                              {Xcarb1formData.pdfname ? (
                                <Tooltip title={Xcarb1formData.pdfname}>
                                  <p>File: {Xcarb1formData.pdfname}</p>
                                </Tooltip>
                              ) : (
                                "No File Selected"
                              )}
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Existing image id"
                          name="existing_img_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Existing id"
                          name="existing_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="savebtns">
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb1save(0)}>
                            Save & go back
                          </Button>
                        </Form.Item>
                      </Space>
                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb1save(1)}>
                            Save
                          </Button>
                        </Form.Item>
                      </Space>

                      <Space>
                        <Form.Item>
                          <Button type="primary" onClick={() => xcarb1save(2)}>
                            Save & continue
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                  {isModalVisible && (
                    <div className="spinner-loading">
                      {/* <Spin size="large" /> */}
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="xcarb 2 details" key="3">
                  <Row>
                    <Col span={24}>
                      <Tabs
                        activeKey={activecontactTab}
                        onChange={handlecontactTabChange}
                      >
                        <Tabs.TabPane tab="Detail" key="1">
                          <div className="note-info">
                            <h5>NOTE*</h5>
                            <ul className="m-0 p-0">
                              <li>
                                For the <b>'Title'</b> please maintain{" "}
                                <b>20 Characters</b>
                                max including{" "}
                                <b>alphabets, spaces, symbols and numbers</b> to
                                avoid line break.
                              </li>
                              <li>
                                For the <b>'Description'</b> please maintain{" "}
                                <b>1000 Characters</b> max including{" "}
                                <b>alphabets, spaces, symbols and numbers</b>.
                              </li>
                              <li>
                                Please maintain the image resolution of the
                                <b>'Image'</b> to exact
                                <b> 600 x 450 px ( Aspect Ratio 4:3 )</b> to
                                avoid design break. Please use file types:
                                <b>jpg/png</b> . Image size less than{" "}
                                <b>500 kb</b> is recommended for better
                                performance.
                              </li>
                              <li>
                                Please remember to click <b>'Save'</b> after
                                uploading an <b>image or PDF</b> to ensure your
                                changes are updated.
                              </li>
                            </ul>
                          </div>
                          <Form
                            name="xcarb2"
                            layout="vertical"
                            form={xcarb2form}
                            initialValues={Xcarb2formData}
                            onValuesChange={handleXcarb2InputChange}
                            onFinish={onFinish}
                            // ref={xcarb2formRef}
                            onChange={() => setFormEdited(true)}
                          >
                            <Row>
                              <Col span={4}>
                                <Form.Item
                                  label="Title"
                                  name="title"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Title!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col span={10}>
                                <Form.Item
                                  label="Description"
                                  name="description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Description",
                                    },
                                  ]}
                                >
                                  {/* <Input /> */}
                                  <ReactQuill
                                    modules={modules}
                                    value={Xcarb2formData.description}
                                    onChange={(
                                      content,
                                      delta,
                                      source,
                                      editor
                                    ) => {
                                      if (
                                        cleanHTML(content) !==
                                        cleanHTML(Xcarb2formData.description)
                                      ) {
                                        handleXcarb2InputChange({
                                          description: editor.getHTML(),
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={4}>
                                <Form.Item
                                  label="Image"
                                  name="main_img"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please upload an image!",
                                    },
                                  ]}
                                >
                                  <div className="up-del">
                                    {" "}
                                    {/* Container for image upload and delete */}
                                    <div className="img-preview">
                                      {" "}
                                      {/* Image preview container */}
                                      {isxcarb2Uploading ? (
                                        <Spin
                                          indicator={
                                            <LoadingOutlined
                                              className="spin-icon"
                                              spin
                                            />
                                          }
                                        />
                                      ) : (
                                        <ImagePreview // Component to render image preview
                                          key={Xcarb2formData.imageUrl}
                                          imageUrl={Xcarb2formData.imageUrl}
                                        />
                                      )}
                                    </div>
                                    {
                                      <Upload // Upload component for image
                                        name="avatar"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                        onChange={(info) =>
                                          handlexcarb2Change(info)
                                        } // Handle image upload change
                                      >
                                        <Button icon={<UploadOutlined />} />
                                      </Upload>
                                    }
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  label="Alt/Title Text"
                                  name="img_alt_txt" // Name attribute for form field
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>

                            <div className="savebtns">
                              {/* <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => xcarb2save(2, 0)}
                                  >
                                    Save & go back
                                  </Button>
                                </Form.Item>
                              </Space> */}
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => xcarb2save(1, 0)}
                                  >
                                    Save
                                  </Button>
                                </Form.Item>
                              </Space>
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => xcarb2save(1, 1)}
                                  >
                                    Save & continue
                                  </Button>
                                </Form.Item>
                              </Space>
                            </div>
                          </Form>
                          {isModalVisible && (
                            <div className="spinner-loading">
                              {/* <Spin size="large" /> */}
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            </div>
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Attachment" key="2">
                          <div className="note-info">
                            <h5>NOTE*</h5>
                            <ul className="m-0 p-0">
                              <li>
                                Only <b>pdf file</b> type is allowed for the{" "}
                                <b>PDF</b> field . Max file should be less than
                                50 mb .
                              </li>
                            </ul>
                          </div>
                          <Form
                            name="Banner"
                            layout="vertical"
                            form={xcarb2pdfform}
                            onFinish={onFinish}
                          >
                            <Row>
                              {Xcarb2PdfData.map((field, index) => (
                                <>
                                  <Col span={8}>
                                    <Form.Item
                                      label="title"
                                      name={[field.id, "title"]}
                                      initialValue={field.title}
                                      onChange={(event) => {
                                        const newData = [...Xcarb2PdfData];
                                        newData[index].title =
                                          event.target.value;
                                        setXcarb2PdfData(newData);
                                        setFormEdited(true);
                                      }}
                                      // initialValue={ContactUs2}
                                      // onChange={(event) => {
                                      //   // const newData = [...ContactUs1Data];
                                      //   // newData[index].address =
                                      //   //   event.target.value;
                                      //   setContactUs2(event.target.value);
                                      //   setFormEdited(true);
                                      // }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Tab 2 name!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label="Attachment"
                                      name={[field.id, "pdfid"]}
                                      initialValue={field.pdfid} // initialValue={}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please upload Attachment!",
                                        },
                                      ]}
                                    >
                                      <div>
                                        <input
                                          type="file"
                                          accept="application/pdf"
                                          onChange={(e) =>
                                            handleFileChange_xcarb2(e, index)
                                          }
                                          style={{ display: "none" }}
                                          id={index + "pdfupload"}
                                        />
                                        <label>
                                          <Button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              triggerFileInput(index)
                                            }
                                          >
                                            Upload PDF
                                          </Button>
                                        </label>
                                        <div>
                                          {/* {field.pdf ? (
                                            <p>
                                              File:{" "}
                                              {field.pdf.data.attributes.name}
                                            </p>
                                          ) : (
                                            // <p>No file available</p>
                                            xcarb2Files.find(obj => obj.index === index) ? let val=  xcarb2Files.find(obj => obj.index === index)
                                              <p>  File:{" "}
                                            {field.pdf.data.attributes.name}</p>
                                          )} */}
                                          {field.pdf ? (
                                            <p>
                                              File:{" "}
                                              {field.pdf.data
                                                ? field.pdf.data.attributes.name
                                                : ""}
                                            </p>
                                          ) : (
                                            (() => {
                                              const matchedObj =
                                                xcarb2Files.find(
                                                  (obj) => obj.index === index
                                                );
                                              return matchedObj ? (
                                                <p>
                                                  File: {matchedObj.file.name}
                                                </p>
                                              ) : (
                                                <p>No file available</p>
                                              );
                                            })()
                                          )}
                                        </div>
                                      </div>
                                    </Form.Item>
                                  </Col>
                                  <Col span={1}>
                                    {index > 0 && ( // Render delete button for all fields except the first one
                                      <div className="del-btn">
                                        <Button
                                          danger
                                          icon={<DeleteOutlined />}
                                          onClick={() =>
                                            handleDeleteattachmentFormField(
                                              field,
                                              index
                                            )
                                          }
                                        ></Button>
                                      </div>
                                    )}
                                  </Col>
                                </>
                              ))}
                            </Row>
                            <Row>
                              <Col className="col-pad" span={12}>
                                <Button
                                  type="primary"
                                  className="add-fields"
                                  shape="circle"
                                  icon={<PlusOutlined />}
                                  onClick={handleAddattachmentFormField}
                                />
                              </Col>
                            </Row>

                            <div className="savebtns">
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => xcarb2save(2, 0)}
                                  >
                                    Save & go back
                                  </Button>
                                </Form.Item>
                              </Space>
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => xcarb2save(2, 1)}
                                  >
                                    Save
                                  </Button>
                                </Form.Item>
                              </Space>
                            </div>
                          </Form>
                          {isModalVisible && (
                            <div className="spinner-loading">
                              {/* <Spin size="large" /> */}
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            </div>
                          )}
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        {/* <Footercomp /> */}
      </Layout>
    </>
  );
}

export default Sustainaibilitysubpage;
