import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Save_Barsnrods,
  get_Structuralshapes_Data,
  get_barsnrods_Data,
  deleteimageAPI,
  editImageAPI,
  Save_Search_ByID,
  get_Search_Data,
} from "../api/strucshapesApi";
import { Button, Form, Input, Space, Select } from "antd";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row, Spin, Carousel } from "antd";
import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Upload, message } from "antd";
import UserData from "./UserData";
// // home
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { uploadImageAPI } from "../api/bannerApi";
import ReactFlagsSelect from "react-flags-select";
const { Header, Content, Footer } = Layout;

const MyForm = () => {
  const [SeoformData, setSeoFormData] = useState({});
  const [BannerformData, setBannerFormData] = useState([]);
  const [BusineslinesData, setBusineslinesData] = useState([]);
  const [TechnicalassessmentData, setTechnicalassessmentData] = useState([]);
  const [ContactUs1Data, setContactUs1Data] = useState([]);
  const [ContactUs2Data, setContactUs2Data] = useState([]);
  const [ContactUs1, setContactUs1] = useState("");
  const [ContactUs2, setContactUs2] = useState("");

  const [form] = Form.useForm(); // Using Ant Design Form
  const [Bannerform] = Form.useForm(); // Using Ant Design Form
  const [Businessform] = Form.useForm(); // Using Ant Design Form
  const [Technicalassessmentform] = Form.useForm(); // Using Ant Design Form
  const [ContactUs1form] = Form.useForm(); // Using Ant Design Form
  const [ContactUs2form] = Form.useForm(); // Using Ant Design Form
  const { TextArea } = Input;
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");
  const [pendingTab, setPendingTab] = useState(null);
  const SEOformRef = useRef();
  // const userJwtToken = UserData.getJwtToken();
  const HomeSeoformRef = useRef();
  const formRef = useRef();
  const userJwtToken = localStorage.getItem("Token");
  const BannerformRef = useRef();

  const [formSaved, setFormSaved] = useState(false); // State to track whether form has been saved
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const [Searchdata, Setsearchdata] = useState([]);

  const { Option } = Select;

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const handlecontactTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActivecontactTab(key);
    }
  };
  //editor for banner tab
  function handleQuillChangeba(content, key, field) {
    // Assuming BannerformData is part of your component's state
    setBannerFormData((prevFields) => {
      // Find the field that needs to be updated
      const fieldIndex = prevFields.findIndex((f) => f.key === key);

      // Make a copy of the previous fields
      const newFields = [...prevFields];

      // Update the specific field
      newFields[fieldIndex] = {
        ...newFields[fieldIndex],
        [field]: content,
      };

      // Return the updated fields
      return newFields;
    });
  }

  const handle_upload_img_for_contact1 = async (info, index) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setContactUs1Data((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].imageUrl = url;
        updatedFields[index].logo = uploadResponse[0].id;

        return updatedFields;
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  const handle_upload_img_for_contact2 = async (info, index) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setContactUs2Data((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].imageUrl = url;
        updatedFields[index].logo = uploadResponse[0].id;

        return updatedFields;
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  const [ismultipleUploading, setIsmultipleUploading] = useState(
    BusineslinesData.map(() => false)
  );
  const Submit_multiple_img = async (info) => {
    let data = [];
    let rmdumplicate = [];

    const uploadPromises = info.fileList.map((item) => {
      if (!rmdumplicate.includes(item.uid)) {
        const formData = new FormData();
        formData.append("files", item.originFileObj);

        return uploadImageAPI(formData, userJwtToken)
          .then((uploadResponse) => {
            // const url = URL.createObjectURL(info.file.originFileObj);
            const url = URL.createObjectURL(item.originFileObj);

            let obj = {
              id: uploadResponse[0].id,
              name: uploadResponse[0].name,
              imgurl: uploadResponse[0].formats.thumbnail.url,
            };
            data.push(obj);
            rmdumplicate.push(item.uid);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    });

    await Promise.all(uploadPromises);
    return data;
  };
  const handle_multiple_upload = async (info, index) => {
    let data = [];
    setIsmultipleUploading((prevState) => {
      // Start loading when the upload starts
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    // Filter out duplicate files
    const uniqueFiles = info.fileList.filter(
      (file, i, self) => i === self.findIndex((t) => t.uid === file.uid)
    );

    // test test test
    // for (let file of uniqueFiles) {
    //   const fileType = file.originFileObj.type;
    //   if (fileType !== "image/jpeg" && fileType !== "image/png") {
    //     alert("Please upload only images");
    //     return;
    //   }
    // }

    const lastFile = {
      ...info,
      fileList: [uniqueFiles[uniqueFiles.length - 1]],
    };
    Submit_multiple_img(lastFile)
      .then((data) => {
        setBusineslinesData((prevFields) => {
          const updatedFields = [...prevFields];
          // updatedFields[index].carousel_data = data;
          updatedFields[index].carousel_data = [
            ...updatedFields[index].carousel_data,
            ...data,
          ];
          return updatedFields;
        });
        setIsmultipleUploading((prevState) => {
          // Stop loading when the upload is done
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      })
      .catch((err) => {
        setIsmultipleUploading((prevState) => {
          // Stop loading when the upload is done
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      });
  };
  const [islineUploading, setIslineUploading] = useState(
    BusineslinesData.map(() => false)
  );
  const handle_lineitem_upload = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        return;
      }

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        setIslineUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBusineslinesData((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].imageUrl = url;
            updatedFields[index].main_img = uploadResponse[0].id;

            return updatedFields;
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIslineUploading((prevState) => {
            // Stop loading when the upload is done
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handle_upload_technicalassement_img = async (info, index) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const uploadResponse = await uploadImageAPI(formData, userJwtToken);
      // Get the URL of the uploaded file
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      setTechnicalassessmentData((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].imageUrl = url;
        updatedFields[index].technical_assessment_img = uploadResponse[0].id;

        return updatedFields;
      });
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };
  const [isUploading, setIsUploading] = useState(
    BannerformData.map(() => false)
  );

  const handleChange = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        setIsUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setBannerFormData((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].imageUrl = url;
            updatedFields[index].main_img = uploadResponse[0].id;

            return updatedFields;
          });
          // Update the form values with the new imageUrl
          BannerformRef.current.setFieldsValue({
            [BannerformData[index].key]: { imageUrl: url }, // Update the existing_img_id field in the form
          });
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading((prevState) => {
            // Stop loading when the upload is done
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload image");
    }
  };

  const handleDeleteBannerFormField = (key) => {
    const index = BannerformData.findIndex((field) => field.key === key);
    if (index > -1) {
      const existingImgId = BannerformData[index].existing_img_id;
      const currentDeleteImgId =
        BannerformRef.current.getFieldValue("delete_img_id");
      BannerformRef.current.setFieldsValue({
        delete_img_id: currentDeleteImgId
          ? `${currentDeleteImgId}, ${existingImgId}`
          : `${existingImgId},`,
      });
      const newBannerformData = [...BannerformData];
      newBannerformData.splice(index, 1);
    }
  };
  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const seosave = async (index) => {
    setIsModalVisible(true);

    try {
      await form.validateFields();
      const data = {
        data: {
          seo: {
            meta_description: SeoformData.meta_description,
            meta_keywords: SeoformData.meta_keywords,
            page_title: SeoformData.page_title,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Barsnrods(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  // save button for banner
  const handleBannerFinish = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await Bannerform.validateFields();
      const data = {
        data: {
          banner_section: [
            {
              id: BannerformData[0].existing_id,
              title: BannerformData[0].title,
              navigator_text: BannerformData[0].navigator_text,
              main_img: BannerformData[0].main_img,
              img_alt_title: BannerformData[0].img_alt_title,
            },
          ],
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[0].text = BannerformData[0].title;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };

      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});

        Save_Barsnrods(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the next tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };

  const BusinesslinesSave = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await Businessform.validateFields();
      let setarr = BusineslinesData.map((item) => {
        return {
          id: item.key,
          title_text: item.title_text,
          order_no: item.order_no,
          bg_img: item.main_img,
          description: item.description,
          image_carousel: item.carousel_data,
          img_alt_title: item.img_alt_title,
        };
      });
      const data = {
        data: {
          businessline_block_item: [...setarr],
        },
      };

      let search_data = Searchdata;
      search_data[0].attributes.desc[1].text =
        BusineslinesData[0].title_text + BusineslinesData[0].description;
      search_data[0].attributes.desc[2].text =
        BusineslinesData[0].title_text + BusineslinesData[0].description;
      search_data[0].attributes.desc[3].text =
        BusineslinesData[0].title_text + BusineslinesData[0].description;
      const searchparams = {
        data: {
          desc:
            search_data[0].attributes.title + search_data[0].attributes.desc,
        },
      };

      try {
        Save_Search_ByID(searchparams, search_data[0].id, userJwtToken)
          .then((response) => {})
          .catch((err) => {})
          .finally(() => {});
        Save_Barsnrods(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the next tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      setIsModalVisible(false); // Stop loading when API call is finished

      console.error("Form validation failed:", error);
    }
  };

  const Technical_assessment_Save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await Technicalassessmentform.validateFields();

      const data = {
        data: {
          technical_assessment: [
            {
              id: TechnicalassessmentData[0].key,
              technical_assessment_title:
                TechnicalassessmentData[0].technical_assessment_title,
              technical_assessment_description:
                TechnicalassessmentData[0].technical_assessment_description,
              technical_assessment_img:
                TechnicalassessmentData[0].technical_assessment_img,
            },
          ],
        },
      };

      try {
        Save_Barsnrods(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              // If index is 1, proceed to the next tab
              setFormEdited(false);
              if (index === 0) {
                const prevTabIndex = parseInt(activeTab) - 1;
                setActiveTab(prevTabIndex.toString());
              }
              // If index is 1, proceed to the next tab
              if (index === 2) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error

      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };
  const contact1_save = async (index) => {
    setIsModalVisible(true); // Stop loading when API call is finished

    try {
      await ContactUs1form.validateFields();
      let setarr = ContactUs1Data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          //   imageUrl: item.logo.data.attributes.formats.thumbnail.url,
          logo: item.logo,
          contact_no: item.contact_no,
          address: item.address,
          country_code: item.country_code,
        };
      });
      const data = {
        data: {
          Contact_us1: ContactUs1,
          contact_us1_fields: [...setarr],
        },
      };

      try {
        Save_Barsnrods(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);
              if (index === 2) {
                setActivecontactTab("2");
              }
            }, 500);
          })
          .catch((err) => {})
          .finally(() => {
            setIsModalVisible(false);
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false); // Stop loading when API call is finished
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false); // Stop loading when API call is finished
    }
  };
  const contact2_save = async (index) => {
    try {
      let setarr = ContactUs2Data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          //   imageUrl: item.logo.data.attributes.formats.thumbnail.url,
          logo: item.logo,
          contact_no: item.contact_no,
          address: item.address,
        };
      });
      const data = {
        data: {
          Contact_us2: ContactUs2,
          contact_us2_fields: [...setarr],
        },
      };

      try {
        Save_Barsnrods(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setFormEdited(false);
            setTimeout(() => {
              setFormEdited(false);
              if (index === 0) {
                setActivecontactTab("1");
              }
            }, 500);
          })
          .catch((err) => {});
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
    }
  };
  const removecontact1 = (item_index) => {
    let newarr = ContactUs1Data.filter((_, index) => index !== item_index);
    setContactUs1Data(newarr);
  };
  const removecontact2 = (item_index) => {
    let newarr = ContactUs2Data.filter((_, index) => index !== item_index);
    setContactUs2Data(newarr);
  };
  const handleAddContact1FormField = () => {
    const maxKey = Math.max(...ContactUs1Data.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setContactUs1Data([
      ...ContactUs1Data,
      {
        key: newKey,
        name: "",
        role: "",
        email: "",
        // imageUrl: "",
        // logo: "",
        contact_no: "",
        address: "",
        country_code: "",
      },
    ]);
  };

  const handleAddContact2FormField = () => {
    const maxKey = Math.max(...ContactUs2Data.map((field) => field.key));
    const newKey = maxKey === -Infinity ? 1 : maxKey + 1;
    setContactUs2Data([
      ...ContactUs2Data,
      {
        key: newKey,
        name: "",
        role: "",
        email: "",
        imageUrl: "",
        logo: "",
        contact_no: "",
        address: "",
      },
    ]);
  };

  useEffect(() => {
    fetchData();
    Search_Data();
  }, []);

  const Search_Data = async () => {
    const response = await get_Search_Data();
    const data = response.data.data;

    let barnsnrodsdata = data.filter(
      (item) => item.attributes.title === "Bars and Rods"
    );
    Setsearchdata(barnsnrodsdata);
  };

  const fetchData = async () => {
    try {
      const response = await get_barsnrods_Data();
      const data = response.data;

      const seoData = {
        meta_keywords: response.data.data.attributes.seo.meta_keywords,
        meta_description: response.data.data.attributes.seo.meta_description,
        page_title: response.data.data.attributes.seo.page_title,
      };

      const Bannerdata = response.data.data.attributes.banner_section;
      const newBannerFormFields = Bannerdata.map((item) => {
        return {
          key: item.id,
          order_no: item.order_no,
          title: item.title,
          navigator_text: item.navigator_text,
          imageUrl: item.main_img.data.attributes.formats.thumbnail.url,
          main_img: item.main_img.data.id,
          existing_id: item.id,
          img_alt_title: item.img_alt_title,
        };
      });

      const Businesslinesdata =
        response.data.data.attributes.businessline_block_item;
      const newBusinessFormFields = Businesslinesdata.map((item) => {
        let data = [];

        if (item.image_carousel.data.length > 0) {
          data = item.image_carousel.data.map((car) => {
            return {
              id: car.id,
              name: car.attributes.name,
              imgurl: car.attributes.formats.thumbnail.url,
            };
          });
        }

        return {
          key: item.id,
          title_text: item.title_text,
          order_no: item.order_no,
          bg_img: item.bg_img.data.id,
          imageUrl: item.bg_img.data.attributes.formats.thumbnail.url,
          carousel_data: data,
          description: item.description,
          img_alt_title: item.img_alt_title,
        };
      });

      setBusineslinesData(newBusinessFormFields);

      // const Contactusdatas = response.data.data.attributes.contact_us;

      const contact1data = response.data.data.attributes.contact_us1_fields;
      setContactUs1(response.data.data.attributes.Contact_us1);
      const contact1Formfields = contact1data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          role: item.role,
          email: item.email,
          // imageUrl: item.logo.data.attributes.formats.thumbnail.url,
          // logo: item.logo.data.id,
          contact_no: item.contact_no,
          address: item.address,
          country_code: item.country_code,
        };
      });

      //------------Set Contact tab ---------------------------------------

      setContactUs1Data(contact1Formfields);

      //------------set SEO data--------------
      setSeoFormData(seoData);
      form.setFieldsValue(seoData); // Set form fields value

      //-----------set Banner data----------------
      setBannerFormData(newBannerFormFields);
      Bannerform.setFieldValue(newBannerFormFields);

      //---------- set Businesslines data---------

      setTimeout(() => {}, 2000);
      Businessform.setFieldValue(newBusinessFormFields);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSeoInputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSeoFormData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const contentStyle = {
    // height: "60px",
    // width: "40px",
    textAlign: "center",
    background: "#ff3700",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
  };

  // -------------------------------
  const carouselRefs = useRef([]);
  const [isDeleting, setIsDeleting] = useState(
    BusineslinesData.map(() => false)
  );
  const handleImageDelete = async (carouselIndex, imgIndex) => {
    setIsDeleting((prev) => {
      const newDeleting = [...prev];
      newDeleting[carouselIndex] = true;
      return newDeleting;
    });
    const imageId = BusineslinesData[carouselIndex].carousel_data[imgIndex].id;
    try {
      const response = await deleteimageAPI(imageId, userJwtToken); // Call the API
      if (response.ok) {
        // If the response is successful
        const newData = [...BusineslinesData];
        newData[carouselIndex].carousel_data.splice(imgIndex, 1);
        setBusineslinesData(newData);
      } else {
        console.error("Failed to delete image:", response);
      }
    } catch (error) {
      console.error("An error occurred while deleting the image:", error);
    } finally {
      setIsDeleting((prev) => {
        const newDeleting = [...prev];
        newDeleting[carouselIndex] = false;
        return newDeleting;
      });
    }
  };

  const [islinecarouselUploading, setIslinecarouselUploading] = useState(
    BusineslinesData.map(() => false)
  );
  const Submit_carousel_img = async (info) => {
    let data = [];
    let rmdumplicate = [];

    const uploadPromises = info.fileList.map((item) => {
      if (!rmdumplicate.includes(item.uid)) {
        const formData = new FormData();
        formData.append("files", item.originFileObj);

        return uploadImageAPI(formData, userJwtToken)
          .then((uploadResponse) => {
            const url = URL.createObjectURL(info.file.originFileObj);

            let obj = {
              id: uploadResponse[0].id,
              name: uploadResponse[0].name,
              imgurl: uploadResponse[0].formats.thumbnail.url,
            };
            data.push(obj);
            rmdumplicate.push(item.uid);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    });

    await Promise.all(uploadPromises);
    return data;
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handle_carousel_upload = async (info, index) => {
    let data = [];
    setIslinecarouselUploading((prevState) => {
      // Start loading when the upload starts
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    Submit_carousel_img(info)
      .then((newImageData) => {
        setBusineslinesData((prevFields) => {
          const updatedFields = [...prevFields];
          // Replace the specific image in the carousel_data array
          updatedFields[index].carousel_data = updatedFields[
            index
          ].carousel_data.map((item, idx) => {
            // If the index matches the current image index, replace it with the new image data
            if (idx === currentImageIndex) {
              return newImageData[0];
            }
            // Otherwise, return the existing image data
            return item;
          });

          return updatedFields;
        });
        setIslinecarouselUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      })
      .catch((err) => {
        setIslinecarouselUploading((prevState) => {
          // Start loading when the upload starts
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };
  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //
  return (
    <Layout>
      <Headercomp />
      <div className="innerbody-pad">
        <div className="main-head">
          <h1>Bars and Rods</h1>
        </div>
        <Row>
          <Col span={24}>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <Tabs.TabPane tab="SEO" key="1">
                <Form
                  name="seo"
                  layout="vertical"
                  form={form}
                  initialValues={SeoformData}
                  // onValuesChange={handleSeoInputChange}
                  ref={SEOformRef} // Reference to the form for accessing form values
                  // onFinish={onFinish}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Page Title"
                        name="page_title"
                        rules={[
                          {
                            required: true,
                            message: "Please input Page Title!",
                          },
                        ]}
                        initialValue={SeoformData.page_title}
                        onChange={(event) => {
                          const newSeoFormData = {
                            ...SeoformData,
                            page_title: event.target.value,
                          };
                          setSeoFormData(newSeoFormData);
                          setFormEdited(true);
                        }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Meta Description"
                        name="meta_description"
                        rules={[
                          {
                            required: true,
                            message: "Please input Meta Description!",
                          },
                        ]}
                        initialValue={SeoformData.meta_description}
                        onChange={(event) => {
                          const newSeoFormData = {
                            ...SeoformData,
                            meta_description: event.target.value,
                          };
                          setSeoFormData(newSeoFormData);
                          setFormEdited(true);
                        }}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Meta Keywords"
                        name="meta_keywords"
                        rules={[
                          {
                            required: true,
                            message: "Please input Meta Keyword!",
                          },
                        ]}
                        initialValue={SeoformData.meta_keywords}
                        onChange={(event) => {
                          const newSeoFormData = {
                            ...SeoformData,
                            meta_keywords: event.target.value,
                          };
                          setSeoFormData(newSeoFormData);
                          setFormEdited(true);
                        }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Existing id" name="existing_id" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button type="primary" onClick={() => seosave(0)}>
                          Save
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={() => seosave(1)}>
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Banner" key="2">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      For the <b>'Banner Title'</b> please maintain{" "}
                      <b>20 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> to avoid
                      design break.
                    </li>
                    <li>
                      For the <b>'Banner Description'</b> please maintain{" "}
                      <b>500 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> to avoid
                      design break. Use <b>"Normal"</b> text for this field.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Banner Image'</b> to exact <b>1600 x 670 px</b> to
                      avoid design break. Please use file types: <b>jpg/png</b>{" "}
                      . Image size less than <b>800 kb</b> is recommended for
                      better performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  //   name="banner"
                  //   layout="vertical"
                  //   // onFinish={handleBannerFinish} // Handle form submission
                  //   onChange={() => setFormEdited(true)} // Set formEdited state to true when any field changes
                  ref={BannerformRef} // Reference to the form for accessing form values
                  name="Banner"
                  layout="vertical"
                  form={Bannerform}
                  initialValues={SeoformData}
                  //   onValuesChange={handleSeoInputChange}
                  //   onFinish={onFinish}
                  //   onChange={() => setFormEdited(true)}
                >
                  {BannerformData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      // Each row represents a banner form field
                      <Row key={field.key}>
                        {/* <Col span={4}>
                          <Form.Item
                            label="Seq no."
                            name={[field.key, "order"]} // Name attribute for form field
                            initialValue={field.order} // Initial value for the field
                            rules={[
                              // Validation rules for the field
                              {
                                required: true,
                                message: "Please input sequence number!",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Please enter only numerical values!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col> */}
                        <Col span={6}>
                          <Form.Item
                            label="Banner Title"
                            name={[field.key, "navigator_text"]}
                            initialValue={field.navigator_text}
                            onChange={(event) => {
                              const newData = [...BannerformData];
                              newData[index].navigator_text =
                                event.target.value;
                              setBannerFormData(newData);
                              setFormEdited(true);
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner Title!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label="Existing image id"
                            name={[field.key, "existing_img_id"]}
                            initialValue={field.existing_img_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[field.key, "existing_id"]}
                            initialValue={field.existing_id}
                            hidden
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            label="Banner Description"
                            name={[field.key, "title"]}
                            initialValue={field.title}
                            rules={[
                              {
                                required: true,
                                message: "Please input Banner Description!",
                              },
                            ]}
                          >
                            <ReactQuill
                              modules={modules}
                              value={field.navigator_text}
                              onChange={(content) => {
                                if (content !== field.navigator_text) {
                                  setFormEdited(true);
                                }
                                handleQuillChangeba(
                                  content,
                                  field.key,
                                  "title"
                                );
                                const newData = [...BannerformData];
                                newData[index].title = content;
                                setBannerFormData(newData);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Banner Image"
                            name={[field.key, "imageUrl"]}
                            initialValue={field.imageUrl}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Banner Image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {" "}
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {" "}
                                {/* Image preview container */}
                                {isUploading[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <ImagePreview // Component to render image preview
                                    key={BannerformData[index].imageUrl}
                                    imageUrl={BannerformData[index].imageUrl}
                                  />
                                )}
                              </div>
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  onChange={(info) => handleChange(info, index)} // Handle image upload change
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "img_alt_title"]} // Name attribute for form field
                            initialValue={field.img_alt_title} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...BannerformData];
                              newData[index].img_alt_title = event.target.value;
                              setBannerFormData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {index > 0 && ( // Render delete button for all fields except the first one
                            <div className="del-btn">
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  handleDeleteBannerFormField(field.key)
                                }
                              ></Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col className="col-pad" span={12}>
                      <Form.Item
                        label="Deleting image id"
                        name={"delete_img_id"}
                        initialValue=""
                        hidden
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => handleBannerFinish(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Page Blocks" key="3">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      Please avoid using Same <b>'Order No'</b>.
                    </li>
                    <li>
                      <b>30 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Block Title'</b> field is recommended.
                    </li>
                    <li>
                      For the <b>'Description' 700 Characters</b> max is
                      recommended including{" "}
                      <b>alphabets, spaces, symbols and numbers</b>.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Main image'</b> to exact{" "}
                      <b>533 x 666 px ( Aspect Ratio 533:666 )</b> to avoid
                      design break. Please use file types: <b>jpg/png</b> .Image
                      size less than <b>500 kb</b> is recommended for better
                      performance.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Carousel image'</b> to exact{" "}
                      <b>600 x 450 px ( Aspect Ratio 4:3 )</b> to avoid design
                      break. Please use file types: <b>jpg/png</b> .Image size
                      less than <b>500 kb</b> is recommended for better
                      performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <Form
                  ref={BannerformRef} // Reference to the form for accessing form values
                  name="Banner"
                  layout="vertical"
                  form={Businessform}
                  initialValues={SeoformData}
                >
                  {BusineslinesData.map(
                    (
                      field,
                      index // Map through banner form fields array
                    ) => (
                      // Each row represents a banner form field
                      <Row key={field.key}>
                        <Col span={2}>
                          <Form.Item
                            label="Order No"
                            name={[field.key, "order_no"]}
                            initialValue={field.order_no}
                            rules={[
                              {
                                required: true,
                                message: "Please input Order No!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const item = BusineslinesData.find(
                                    (item, idx) =>
                                      idx !== index &&
                                      item.order_no === Number(value)
                                  ); // ignore the current item during the check

                                  if (!value || !item) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "This order number already exists!"
                                    )
                                  );
                                },
                              }),
                            ]}
                            onChange={(event) => {
                              const newData = [...BusineslinesData];
                              newData[index].order_no = event.target.value;
                              setBusineslinesData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label=" Block Title"
                            name={[field.key, "title_text"]}
                            initialValue={field.title_text}
                            rules={[
                              {
                                required: true,
                                message: "Please input Block Title!",
                              },
                            ]}
                            onChange={(event) => {
                              const newData = [...BusineslinesData];
                              newData[index].title_text = event.target.value;
                              setBusineslinesData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label=" Description"
                            name={[field.key, "description"]}
                            initialValue={field.description}
                            rules={[
                              {
                                required: true,
                                message: "Please input banner Description!",
                              },
                            ]}
                          >
                            <ReactQuill
                              modules={modules}
                              value={field.description}
                              onChange={(content) => {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(field.description)
                                ) {
                                  setFormEdited(true);
                                  const newData = [...BusineslinesData];
                                  newData[index].description =
                                    cleanHTML(content);
                                  setBusineslinesData(newData);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Main image"
                            name={[field.key, "imageUrl"]}
                            initialValue={field.imageUrl}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Main image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {" "}
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {" "}
                                {/* Image preview container */}
                                {islineUploading[index] ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  <ImagePreview // Component to render image preview
                                    key={BusineslinesData[index].imageUrl}
                                    imageUrl={BusineslinesData[index].imageUrl}
                                  />
                                )}
                              </div>
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  onChange={(info) =>
                                    handle_lineitem_upload(info, index)
                                  } // Handle image upload change
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            label="Carousel image"
                            name={[field.key, "imageUrl"]}
                            // initialValue={field.imageUrl}
                            rules={[
                              {
                                required: true,
                                message: "Please upload Carousel images!",
                              },
                            ]}
                          >
                            <div className="banner-cl">
                              {ismultipleUploading[index] ? (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      className="spin-icon"
                                      spin
                                    />
                                  }
                                />
                              ) : (
                                <Carousel
                                  {...settings}
                                  ref={(ref) =>
                                    (carouselRefs.current[index] = ref)
                                  }
                                >
                                  {BusineslinesData[index].carousel_data.map(
                                    (item, imgIndex) => {
                                      return (
                                        <div className="car-cont">
                                          {islinecarouselUploading[index] ? (
                                            <Spin
                                              indicator={
                                                <LoadingOutlined
                                                  className="spin-icon"
                                                  spin
                                                />
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={`${item.imgurl}`}
                                              alt="carousel-item"
                                              // style={contentStyle}
                                              style={settings}
                                            />
                                          )}
                                          <Button
                                            // icon={<DeleteOutlined />}
                                            icon={
                                              isDeleting[index] ? (
                                                <LoadingOutlined spin />
                                              ) : (
                                                <DeleteOutlined />
                                              )
                                            }
                                            onClick={() =>
                                              handleImageDelete(index, imgIndex)
                                            }
                                            disabled={imgIndex === 0}
                                          ></Button>
                                          <Upload // Upload component for image
                                            name="avatar"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            multiple={false}
                                            // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                            onChange={(info) =>
                                              handle_carousel_upload(
                                                info,
                                                index
                                              )
                                            } // Handle image upload change
                                          >
                                            <Button
                                              className="edit-button"
                                              icon={<EditOutlined />}
                                              onClick={() =>
                                                setCurrentImageIndex(imgIndex)
                                              }
                                            />
                                          </Upload>
                                        </div>
                                      );
                                    }
                                  )}
                                </Carousel>
                              )}
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  multiple={true}
                                  beforeUpload={() => false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  onChange={(info) =>
                                    handle_multiple_upload(info, index)
                                  } // Handle image upload change
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name={[field.key, "img_alt_title"]} // Name attribute for form field
                            initialValue={field.img_alt_title} // Initial value for the field
                            onChange={(event) => {
                              const newData = [...BusineslinesData];
                              newData[index].img_alt_title = event.target.value;
                              setBusineslinesData(newData);
                              setFormEdited(true);
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col className="col-pad" span={12}>
                      <Form.Item
                        label="Deleting image id"
                        name={"delete_img_id"}
                        initialValue=""
                        hidden
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="savebtns">
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => BusinesslinesSave(0)}
                        >
                          Save & go back
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => BusinesslinesSave(1)}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => BusinesslinesSave(2)}
                        >
                          Save & continue
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
                {isModalVisible && (
                  <div className="spinner-loading">
                    {/* <Spin size="large" /> */}
                    <Spin
                      indicator={<LoadingOutlined className="spin-icon" spin />}
                    />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab="Contact us" key="4">
                <Row>
                  <Col span={24}>
                    <Tabs
                      activeKey={activecontactTab}
                      onChange={handlecontactTabChange}
                    >
                      <Tabs.TabPane tab={ContactUs1} key="1">
                        <Form
                          name="Banner"
                          layout="vertical"
                          form={ContactUs1form}
                          initialValues={SeoformData}
                        >
                          <Row>
                            <Col span={23}>
                              <Form.Item
                                label="Department Name"
                                name="Tab_Name1"
                                initialValue={ContactUs1}
                                onChange={(event) => {
                                  // const newData = [...TechnicalassessmentData];
                                  // newData[index].technical_assessment_description =
                                  //   event.target.value;
                                  setContactUs1(event.target.value);
                                  setFormEdited(true);
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Department Name!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          {ContactUs1Data.map(
                            (
                              field,
                              index // Map through banner form fields array
                            ) => (
                              // Each row represents a banner form field
                              <Row key={field.key}>
                                <Col span={4}>
                                  <Form.Item
                                    label="Name"
                                    name={[field.key, "name"]}
                                    initialValue={field.name}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].name = event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Name!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={5}>
                                  <Form.Item
                                    label="Role"
                                    name={[field.key, "role"]}
                                    initialValue={field.role}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].role = event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Role!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={4}>
                                  <Form.Item
                                    label="Email"
                                    name={[field.key, "email"]}
                                    initialValue={field.email}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].email = event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    label="Contact No"
                                    name={[field.key, "contact_no"]}
                                    initialValue={field.contact_no}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].contact_no =
                                        event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>

                                <Col span={4}>
                                  <Form.Item
                                    label="Select Country"
                                    name={[field.key, "country_code"]}
                                  >
                                    <ReactFlagsSelect
                                      selected={
                                        field.country_code
                                          ? field.country_code
                                          : ""
                                      }
                                      onSelect={(code) => {
                                        const newData = [...ContactUs1Data];
                                        newData[index].country_code = code;
                                        setContactUs1Data(newData);
                                        setFormEdited(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    label="Location"
                                    name={[field.key, "address"]}
                                    onChange={(event) => {
                                      const newData = [...ContactUs1Data];
                                      newData[index].address =
                                        event.target.value;
                                      setContactUs1Data(newData);
                                      setFormEdited(true);
                                    }}
                                    initialValue={field.address}
                                  >
                                    <TextArea />
                                  </Form.Item>
                                </Col>

                                <Col span={1}>
                                  {index > 0 && ( // Render delete button for all fields except the first one
                                    <div className="del-btn">
                                      <Button
                                        danger
                                        icon={<DeleteOutlined />}
                                        onClick={() => removecontact1(index)}
                                      ></Button>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}

                          <Row>
                            <Col className="col-pad" span={12}>
                              <Button
                                type="primary"
                                className="add-fields"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={handleAddContact1FormField}
                              />
                            </Col>
                            <Col className="col-pad" span={12}>
                              <Form.Item
                                label="Deleting image id"
                                name={"delete_img_id"}
                                initialValue=""
                                hidden
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-pad" span={12}>
                              <Form.Item
                                label="Deleting image id"
                                name={"delete_img_id"}
                                initialValue=""
                                hidden
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className="savebtns">
                            <Space>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={() => contact1_save(1)}
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </Space>
                          </div>
                        </Form>
                        {isModalVisible && (
                          <div className="spinner-loading">
                            {/* <Spin size="large" /> */}
                            <Spin
                              indicator={
                                <LoadingOutlined className="spin-icon" spin />
                              }
                            />
                          </div>
                        )}
                      </Tabs.TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>

      {/* <Footercomp /> */}
    </Layout>
  );
};

export default MyForm;
