import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Delete_Newsdata,
  Save_Newsdata,
  Update_Newsdata,
  get_News_Data,
} from "../api/newsApi";
import { Modal, Button, Form, Input, Space, Table, InputNumber } from "antd";
import Headercomp from "./Headercomp";
import Footercomp from "./Footercomp";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row } from "antd";
import { Upload, message } from "antd";
import UserData from "./UserData";
import { Spin } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
// // home
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { uploadImageAPI } from "../api/bannerApi";
// import moment from "moment";
import moment from "moment-timezone";
import { render } from "@testing-library/react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./CustomLinkBlot";

const { Header, Content, Footer } = Layout;

const News = () => {
  const [NewsformData, setNewsformData] = useState([]);
  const [NewslettertableData, setNewslettertableData] = useState([]);

  const [Newsform] = Form.useForm(); // Using Ant Design Form

  const { TextArea } = Input;
  const [activeTab, setActiveTab] = useState("1");
  const [activecontactTab, setActivecontactTab] = useState("1");
  const [pendingTab, setPendingTab] = useState(null);
  const userJwtToken = localStorage.getItem("Token");
  const NewsformRef = useRef();
  const [visible, setVisible] = useState(false);
  const [method, setmethod] = useState(0);
  const handleCreate = () => {};

  const [formSaved, setFormSaved] = useState(false); // State to track whether form has been saved
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const [img1, setimg1] = useState("");
  const [img2, setimg2] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  const handleuploadChange = async (info) => {
    if (!info.file) {
      //   console.error("info.file is undefined");
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state
      const updatedFields = editingRecord;
      updatedFields.news_thumbnailUrl = url;
      updatedFields.news_thumbnail_file = info.file.originFileObj;
      setEditingRecord((updatedFields) => ({
        ...updatedFields,
        news_thumbnailUrl: url,
        news_thumbnail_file: info.file.originFileObj,
      }));
      setimg1(url);
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };
  const handleupload1Change = async (info) => {
    if (!info.file) {
      return;
    }
    // Manually set the status to 'done' if it's 'uploading'
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    // Check if the file status is 'done'
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("files", info.file.originFileObj);
      const url = URL.createObjectURL(info.file.originFileObj);
      // Update the imageUrl of the corresponding banner field in the state

      const updatedFields = editingRecord;
      setEditingRecord((updatedFields) => ({
        ...updatedFields,
        news_main_imageUrl: url,
        news_main_image_file: info.file.originFileObj,
      }));
      setimg2(url);
    } else {
      console.error("Unexpected file status:", info.file.status);
    }
  };

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt="avatar"
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_News_Data();
      const data = response.data.data;

      const Newsdata = response.data.data;
      const newNewsformFields = Newsdata.map((item, index) => {
        return {
          key: item.id,
          news_title: item.attributes.news_title,
          news_description: item.attributes.news_description,
          news_main_imageUrl:
            item.attributes.news_main_image.data.attributes.formats.thumbnail
              .url,
          news_main_image: item.attributes.news_main_image.data.id,
          news_main_image_file: "",
          news_thumbnailUrl:
            item.attributes.news_thumbnail.data.attributes.formats.thumbnail
              .url,
          news_thumbnail: item.attributes.news_thumbnail.data.id,
          news_thumbnail_file: "",
          // create_date: moment
          //   .utc(item.attributes.createdAt)
          //   .format("DD MMMM YYYY HH:mm:ss"),
          create_date: moment(item.attributes.createdAt)
            // .tz('Europe/Paris') // CET timezone
            .tz("CET")
            .format("DD MMMM YYYY HH:mm:ss"),
          id_index: index + 1,

          // existing_id: item.id,
        };
      });
      //-----------set Banner data----------------
      setNewsformData(newNewsformFields);
      Newsform.setFieldValue(newNewsformFields);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const [dataSource, setDataSource] = useState(NewsformData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState();
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image1Name, setImage1Name] = useState("");
  const [image2Name, setImage2Name] = useState("");
  const [quillKey, setQuillKey] = useState(0);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (isModalVisible) {
      // Increment the key to force re-render
      setQuillKey((prevKey) => prevKey + 1);
      setContent(editingRecord.news_description);
    }
  }, [isModalVisible]);

  const handleOpenModal = () => {
    // setModalOpen(true);
  };

  const handleCloseModal = () => {
    // setModalOpen(false);
  };
  const [form] = Form.useForm();

  const cleanHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  const showModal = () => {
    setmethod(0);
    setEditingRecord({
      key: 0,
      news_title: "",
      news_description: "",
      news_main_imageUrl: "",
      news_main_image: "",
      news_main_image_file: "",
      news_thumbnailUrl: "",
      news_thumbnail: "",
      news_thumbnail_file: "",
      create_date: "",
    });
    setTimeout(() => {}, 3000);
    setimg1("");
    setimg2("");
    setIsModalVisible(true);
    form.setFieldsValue({
      key: 0,
      news_title: "",
      news_description: "",
      news_main_imageUrl: "",
      news_main_image: "",
      news_main_image_file: "",
      news_thumbnailUrl: "",
      news_thumbnail: "",
      news_thumbnail_file: "",
      create_date: "",
    });
  };

  const editModal = (record) => {
    setmethod(1);
    handleOpenModal();
    // Clean the HTML content of the news description before setting it
    const cleanedDescription = cleanHTML(record.news_description);
    const cleanedRecord = { ...record, news_description: cleanedDescription };
    setEditingRecord(cleanedRecord);
    setimg1(record.news_thumbnailUrl);
    setimg2(record.news_main_imageUrl);
    setIsModalVisible(true);
    if (record) {
      form.setFieldsValue(cleanedRecord);
    } else {
      form.resetFields();
    }
  };

  const handleOk = async () => {
    try {
      const vals = await form.validateFields();
      if (vals) {
        setIsUploading(true);

        if (editingRecord.news_thumbnail_file !== "") {
          const formData = new FormData();
          formData.append("files", editingRecord.news_thumbnail_file);
          try {
            const uploadResponse = await uploadImageAPI(formData, userJwtToken);

            setEditingRecord((prevFields) => {
              // const updatedFields = [...prevFields];
              // updatedFields[index].imageUrl = url;
              prevFields.news_thumbnail = uploadResponse[0].id;
              prevFields.news_thumbnail_file = "";

              return prevFields;
            });
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
        if (editingRecord.news_main_image_file !== "") {
          const formData = new FormData();
          formData.append("files", editingRecord.news_main_image_file);
          try {
            const uploadResponse = await uploadImageAPI(formData, userJwtToken);

            setEditingRecord((prevFields) => {
              // const updatedFields = [...prevFields];
              // updatedFields[index].imageUrl = url;
              prevFields.news_main_image = uploadResponse[0].id;
              prevFields.news_main_image_file = "";

              return prevFields;
            });
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }

        let data = {
          data: {
            id: editingRecord.key,
            news_title: editingRecord.news_title,
            news_description: editingRecord.news_description,
            news_thumbnail: editingRecord.news_thumbnail,
            news_main_image: editingRecord.news_main_image,
          },
        };
        try {
          if (method === 0) {
            Save_Newsdata(data, userJwtToken)
              .then((response) => {
                message.success("Form saved successfully!");
                setIsModalVisible(false);
                fetchData();
                setIsUploading(false);
              })
              .catch((err) => {
                setIsUploading(false);
              });
          } else {
            Update_Newsdata(data, editingRecord.key, userJwtToken)
              .then((response) => {
                message.success("Form saved successfully!");
                setIsModalVisible(false);
                fetchData();
                setIsUploading(false);
              })
              .catch((err) => {
                setIsUploading(false);
              });
          }
        } catch (error) {
          console.error("Failed to post data to the Strapi backend:", error);
          setIsUploading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk: async () => {
        setNewsformData((prev) => prev.filter((item) => item.key !== key));
        await Delete_Newsdata(key, userJwtToken);

        message.success("Record deleted successfully");
      },
      onCancel: () => {
        message.info("Delete operation cancelled");
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const columns = [
    {
      title: "Sl. No",
      dataIndex: "id_index",
      key: "id_index",
    },
    {
      title: "News Title",
      dataIndex: "news_title",
      key: "news_title",
      // ...getColumnSearchProps("name"),
      sorter: (a, b) => a.news_title.localeCompare(b.news_title),
    },
    {
      title: "Published Date",
      key: "create_date",
      dataIndex: "create_date",
      sorter: (a, b) => a.create_date.localeCompare(b.create_date),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => editModal(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.key)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };
  return (
    <Layout>
      <Headercomp />
      <div className="innerbody-pad">
        <div className="main-head">
          <h1>News</h1>
        </div>

        {isUploading && (
          <div className="spinner-loading">
            <Spin
              indicator={
                <LoadingOutlined className="spin-icon" spin size="large" />
              }
            />
          </div>
        )}
        <Row>
          <Col span={24}>
            <Tabs
              className="tab-none"
              activeKey={activeTab}
              onChange={handleTabChange}
            >
              <Tabs.TabPane tab="News Details" key="1">
                <div className="note-info">
                  <h5>NOTE*</h5>
                  <ul className="m-0 p-0">
                    <li>
                      <b>100 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Title'</b> field is recommended.
                    </li>
                    <li>
                      <b>1200 Characters</b> max including{" "}
                      <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                      <b>'Description'</b> field is recommended.
                    </li>
                    <li>
                      Please maintain the image resolution of the{" "}
                      <b>'Main image'</b> and <b>'Thumbnail'</b> to exact{" "}
                      <b>400 x 500 px ( Aspect Ratio 4:5 )</b>
                      to avoid design break. Please use file types:{" "}
                      <b>jpg/png</b> . Image size less than <b>500 kb</b> is
                      recommended for better performance.
                    </li>
                    <li>
                      Please remember to click <b>'Save'</b> after uploading an{" "}
                      <b>image or PDF</b> to ensure your changes are updated.
                    </li>
                  </ul>
                </div>
                <div>
                  <Button type="primary" onClick={() => showModal()}>
                    Create News
                  </Button>
                  <Table
                    dataSource={NewsformData}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </div>

                {isModalVisible && (
                  <div
                    className={`modal-overlay ${
                      isModalVisible ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <div class="ant-modal-title" id=":r1n:">
                          {method ? "Edit News" : "Add News"}
                        </div>
                        <button
                          className="modal-close-button"
                          onClick={() => setIsModalVisible(false)}
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        {editingRecord && (
                          <Form
                            form={form}
                            initialValues={editingRecord}
                            layout="vertical"
                          >
                            <Form.Item
                              name="news_title"
                              label="News Title"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the name!",
                                },
                              ]}
                              onChange={(e) => {
                                const newData = editingRecord;
                                newData.news_title = e.target.value;

                                setEditingRecord(newData);
                              }}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name="news_description"
                              label="News Desciption"
                            >
                            
                              <ReactQuill
                                key={quillKey}
                                value={editingRecord.news_description}
                                modules={modules}
                                onChange={(content) => {
                                  if (
                                    editingRecord.news_description !=
                                    cleanHTML(content)
                                  ) {
                                   
                                    const cleanedContent = cleanHTML(content);
                                    const newData = editingRecord;
                                    newData.news_description = cleanedContent;
                                  }
                                }}
                              />
                            </Form.Item>
                            {/* <div style={{ width: '2000px' }}> Example for overflow content */}
              {/* This is a long content to trigger horizontal scroll... */}
            {/* </div>  */}
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  label="Thumbnail"
                                  name="news_thumbnail"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please upload thumbnail!",
                                    },
                                  ]}
                                >
                                  <div className="up-del">
                                    <div className="img-preview">
                                      <ImagePreview
                                        key={img1}
                                        imageUrl={img1}
                                      />
                                    </div>
                                    {
                                      <Upload
                                        name="avatar"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        onChange={(info) =>
                                          handleuploadChange(info)
                                        }
                                      >
                                        <Button icon={<UploadOutlined />} />
                                      </Upload>
                                    }
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Main Image"
                                  name="news_main_image"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please upload main image!",
                                    },
                                  ]}
                                >
                                  <div className="up-del">
                                    {" "}
                                    {/* Container for image upload and delete */}
                                    <div className="img-preview">
                                      {" "}
                                      {/* Image preview container */}
                                      <ImagePreview // Component to render image preview
                                        key={img2}
                                        imageUrl={img2}
                                      />
                                    </div>
                                    {
                                      <Upload // Upload component for image
                                        name="avatar"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        
                                        onChange={(info) =>
                                          handleupload1Change(info)
                                        } // Handle image upload change
                                      >
                                        <Button icon={<UploadOutlined />} />
                                      </Upload>
                                    }
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </div>
                      <div className="modal-footer">
                        <Space>
                          <Form.Item>
                            <Button onClick={() => setIsModalVisible(false)}>
                              Cancel
                            </Button>
                            <Button
                              style={{ margin: "10px" }}
                              type="primary"
                              onClick={() => handleOk()}
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Space>
                      </div>
                    </div>
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>

      {/* <Footercomp /> */}
    </Layout>
  );
};

export default News;
