import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Delete_a913mecaltbls_product_ByID,
  Delete_a913weldbtbl1s_product_ByID,
  Delete_a913weldbtbl2s_product_ByID,
  Save_Structural_a913,
  Save_a913mecaltbls_product,
  Save_a913mecaltbls_product_ByID,
  Save_a913weldbtbl1s_product,
  Save_a913weldbtbl1s_product_ByID,
  Save_a913weldbtbl2s_product,
  Save_a913weldbtbl2s_product_ByID,
  get_Structural_a913_Data,
  get_a913mecaltbls,
  get_a913weldbtb2s,
  get_a913weldbtbl1s,
} from "../../api/strucshapesApi";
import Headercomp from "../Headercomp";
import Footercomp from "../Footercomp";
import { Upload, message, Spin, Select, Modal, Table } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Col, Row } from "antd";
import UserData from "../UserData";
import { uploadPdfAPI } from "../../api/bannerApi";
import { useNavigate } from "react-router-dom";

import {
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { uploadImageAPI } from "../../api/bannerApi";
const { Header, Content, Footer } = Layout;

function A913() {
  const [a913form] = Form.useForm(); // Using Ant Design Form seo
  const [formEdited, setFormEdited] = useState(false); // State to track whether form has been edited
  const a913formRef = useRef();

  const [A913formData, setA913formData] = useState({});
  const [section1, setsection1] = useState({});
  const [a913_table, seta913_table] = useState({});
  const [process_acrd_dtls, setprocess_acrd_dtls] = useState({});
  const [weld_acrd_table1, setweld_acrd_table1] = useState({});
  const [weld_acrd_dtls1, setweld_acrd_dtls1] = useState({});
  const [weld_acrd_table2, setweld_acrd_table2] = useState({});
  const [tough_acrd_dtls, settough_acrd_dtls] = useState({});
  const [code_acrd_dtls, setcode_acrd_dtls] = useState({});
  const [seismic_acrd_dtls, setseismic_acrd_dtls] = useState({});
  const [avl_acrd_dtls, setavl_acrd_dtls] = useState({});
  const [weight_acrd_dtls, setweight_acrd_dtls] = useState([]);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  // ------------------------------------------------------ //
  const [Section2formData, setSection2formData] = useState({});
  const [section2form] = Form.useForm(); // Using Ant Design Form seo
  const [section2form1] = Form.useForm(); // Using Ant Design Form seo
  const [section2form2] = Form.useForm(); // Using Ant Design Form seo
  const [section2form3] = Form.useForm(); // Using Ant Design Form seo
  const [section2form4] = Form.useForm(); // Using Ant Design Form seo
  const [section2form5] = Form.useForm(); // Using Ant Design Form seo
  const [section2form6] = Form.useForm(); // Using Ant Design Form seo
  const section2formRef = useRef();

  const handleaa913Change = () => {};

  const [a913mecaltbls_TableData, Seta913mecaltbls_TableData] = useState({
    key: 0,
    Grade: "",
    A992: "",
    A913_Gr_50: "",
    A913_Gr_65: "",
    A913_Gr_70: "",
    A913_Gr_80: "",
  });

  const columns = [
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Category",
      dataIndex: "cat_name",
      key: "cat_name",
    },
    {
      title: "Sub Category",
      dataIndex: "subcat_name",
      key: "subcat_name",
    },
    {
      title: "Weight lb/ft",
      dataIndex: "Weight",
      key: "Weight",
    },
    {
      title: "A in.2",
      dataIndex: "A",
      key: "A",
    },
    {
      title: "d in.2",
      dataIndex: "d",
      key: "d",
    },
    {
      title: "b in.2",
      dataIndex: "b",
      key: "b",
    },
    {
      title: "tw in.2",
      dataIndex: "tw",
      key: "tw",
    },
    {
      title: "bf in.2",
      dataIndex: "bf",
      key: "bf",
    },
    {
      title: "tf in.2",
      dataIndex: "tf",
      key: "tf",
    },
    {
      title: "A992",
      dataIndex: "A992",
      key: "A992",
    },
    {
      title: "A913 gR 50+65",
      dataIndex: "A913_Gr_50add65",
      key: "A913_Gr_50add65",
    },
    {
      title: "A913 gR 70",
      dataIndex: "A913_Gr_70",
      key: "A913_Gr_70",
    },
    {
      title: "A913 gR 80",
      dataIndex: "A913_Gr_80",
      key: "A913_Gr_80",
    },
    {
      title: "A709",
      dataIndex: "A709",
      key: "A709",
    },
    {
      title: "A572 Gr 60",
      dataIndex: "A572_Gr_60",
      key: "A572_Gr_60",
    },
    {
      title: "A572 Gr 50",
      dataIndex: "A572_Gr_50",
      key: "A572_Gr_50",
    },
    {
      title: "A572 Gr 65",
      dataIndex: "A572_Gr_65",
      key: "A572_Gr_65",
    },
    {
      title: "A588",
      dataIndex: "A588",
      key: "A588",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a onClick={() => setIsEditing(true)}>Edit</a> */}
          <a onClick={() => handleEdit(record)}>Edit</a>
          <a onClick={() => handleDelete(record.key)}>Delete</a>
        </Space>
      ),
    },
  ];
  const data = [
    // {
    //   key: "1",
    //   section: "John Brown",
    //   category: "New York No. 1 Lake Park",
    //   // tags: ["nice", "developer"],
    // },
    // {
    //   key: "2",
    //   section: "Jim Green",
    //   // page_url: "London No. 1 Lake Park",
    // },
    // {
    //   key: "3",
    //   page_title: "Joe Black",
    //   page_url: "Sydney No. 1 Lake Park",
    // },
  ];
  const [a913_tabledataset, seta913_tabledataset] = useState(data);
  const [weltbl1_tabledataset, setweltbl1_tabledataset] = useState(data);
  const [weltbl2_tabledataset, setweltbl2_tabledataset] = useState(data);

  const [intitialTableVal1, SetinitialTablVal1] = useState({
    key: 0,
    section: "",
    Weight: "",
    A: "",
    d: "",
    b: "",
    tw: "",
    bf: "",
    tf: "",
    // cat_id: 1,
    // A992: "",
    // A913_Gr_50add65: "",
    // A913_Gr_70: "",
    // A913_Gr_80: "",
    // A709: "",
    // A572_Gr_60: "",
    // A572_Gr_50: "",
    // A572_Gr_65: "",
    // A588: "",
  });

  const [intitialTableVal2, SetinitialTablVal2] = useState({
    key: 0,
    section: "",
    Weight: "",
    A: "",
    d: "",
    b: "",
    tw: "",
    bf: "",
    tf: "",
    // cat_id: 1,
    // A992: "",
    // A913_Gr_50add65: "",
    // A913_Gr_70: "",
    // A913_Gr_80: "",
    // A709: "",
    // A572_Gr_60: "",
    // A572_Gr_50: "",
    // A572_Gr_65: "",
    // A588: "",
  });

  const handleDelete1 = async (key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk: async () => {
        setweltbl1_tabledataset((prev) =>
          prev.filter((item) => item.key !== key)
        );
        await Delete_a913weldbtbl1s_product_ByID(key, userJwtToken);
        message.success("Record deleted successfully");
      },
      onCancel: () => {
        message.info("Delete operation cancelled");
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleDelete2 = async (key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk: async () => {
        setweltbl2_tabledataset((prev) =>
          prev.filter((item) => item.key !== key)
        );
        await Delete_a913weldbtbl2s_product_ByID(key, userJwtToken);
        message.success("Record deleted successfully");
      },
      onCancel: () => {
        message.info("Delete operation cancelled");
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const a913mecaltbls_Table_columns = [
    {
      title: "Grade",
      dataIndex: "Grade",
      key: "Grade",
      sorter: (a, b) => a.Grade.localeCompare(b.section),

      // render: (text) => <a>{text}</a>,
    },
    {
      title: "A992",
      dataIndex: "A992",
      key: "A992",
    },
    {
      title: "A913 Gr 50",
      dataIndex: "A913_Gr_50",
      key: "A913_Gr_50",
    },
    {
      title: "A913 Gr 65",
      dataIndex: "A913_Gr_65",
      key: "A913_Gr_65",
    },
    {
      title: "A913 Gr 70",
      dataIndex: "A913_Gr_70",
      key: "A913_Gr_70",
    },
    {
      title: "A913 Gr 80",
      dataIndex: "A913_Gr_80",
      key: "A913_Gr_80",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a onClick={() => setIsEditing(true)}>Edit</a> */}
          <a onClick={() => handleEdit(record)}>Edit</a>
          <a onClick={() => handleDelete(record.key)}>Delete</a>
        </Space>
      ),
    },
  ];
  const weltable1_Table_columns = [
    {
      title: "Name",
      dataIndex: "NAME",
      key: "NAME",
      sorter: (a, b) => a.NAME.localeCompare(b.section),

      // render: (text) => <a>{text}</a>,
    },
    {
      title: "A992 Gr 50",
      dataIndex: "A992_Gr_50",
      key: "A992_Gr_50",
    },
    {
      title: "A913 Gr 50",
      dataIndex: "A913_Gr_50",
      key: "A913_Gr_50",
    },
    {
      title: "A913 Gr 65",
      dataIndex: "A913_Gr_65",
      key: "A913_Gr_65",
    },
    {
      title: "A913 Gr 70",
      dataIndex: "A913_Gr_70",
      key: "A913_Gr_70",
    },
    {
      title: "A913 Gr 80",
      dataIndex: "A913_Gr_80",
      key: "A913_Gr_80",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a onClick={() => setIsEditing(true)}>Edit</a> */}
          <a onClick={() => handleEdit1(record)}>Edit</a>
          <a onClick={() => handleDelete1(record.key)}>Delete</a>
        </Space>
      ),
    },
  ];
  const weltable2_Table_columns = [
    {
      title: "Thickness",
      dataIndex: "Thickness",
      key: "Thickness",
      sorter: (a, b) => a.Thickness.localeCompare(b.section),

      // render: (text) => <a>{text}</a>,
    },
    {
      title: "A913 Gr 50 Gr 65",
      dataIndex: "A913_Gr_50_Gr_65",
      key: "A913_Gr_50_Gr_65",
    },

    {
      title: "A913 Gr 70",
      dataIndex: "A913_Gr_70",
      key: "A913_Gr_70",
    },
    {
      title: "A913 Gr 80",
      dataIndex: "A913_Gr_80",
      key: "A913_Gr_80",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a onClick={() => setIsEditing(true)}>Edit</a> */}
          <a onClick={() => handleEdit2(record)}>Edit</a>
          <a onClick={() => handleDelete2(record.key)}>Delete</a>
        </Space>
      ),
    },
  ];

  const { Option } = Select;

  const handleDelete = (key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk: async () => {
        seta913_tabledataset((prev) => prev.filter((item) => item.key !== key));
        await Delete_a913mecaltbls_product_ByID(key, userJwtToken);

        message.success("Record deleted successfully");
      },
      onCancel: () => {
        message.info("Delete operation cancelled");
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleAddFields = () => {
    form.resetFields();
    // setIsEditing("editing");
    setIsModalVisible(true);

    // section3tableform.resetFields();
    // setEditingKey(null);
  };
  const handleAdd1Fields = () => {
    form1.resetFields();
    // setIsEditing("editing");
    setIsModalVisible1(true);

    // section3tableform.resetFields();
    // setEditingKey(null);
  };

  const handleAdd2Fields = () => {
    form2.resetFields();
    // setIsEditing("editing");
    setIsModalVisible2(true);

    // section3tableform.resetFields();
    // setEditingKey(null);
  };

  const handleEdit = (record) => {

    const mappedRecord = {
      key: record.key,
      Grade: record.Grade,
      A992: record.A992,
      A913_Gr_50: record.A913_Gr_50,
      A913_Gr_65: record.A913_Gr_65,
      A913_Gr_70: record.A913_Gr_70,
      A913_Gr_80: record.A913_Gr_80,
    };
    form.setFieldsValue(mappedRecord);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEdit1 = (record) => {
    const mappedRecord = {
      key: record.key,
      NAME: record.NAME,
      A992_Gr_50: record.A992_Gr_50,
      A913_Gr_50: record.A913_Gr_50,
      A913_Gr_65: record.A913_Gr_65,
      A913_Gr_70: record.A913_Gr_70,
      A913_Gr_80: record.A913_Gr_80,
    };
    form1.setFieldsValue(mappedRecord);
    setIsModalVisible1(true);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  const handleEdit2 = (record) => {

    const mappedRecord = {
      key: record.key,
      Thickness: record.Thickness,
      A913_Gr_50_Gr_65: record.A913_Gr_50_Gr_65,
      A913_Gr_70: record.A913_Gr_70,
      A913_Gr_80: record.A913_Gr_80,
    };
    form2.setFieldsValue(mappedRecord);
    setIsModalVisible2(true);
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };
  // ------------------------------------------------------ //
  const userJwtToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState("1");
  const [subactiveTab, setsubactiveTab] = useState("1");
  const [weldabilitytab, setweldability] = useState("1");
  const [welTable1, setwelTable1] = useState("1");
  const [welTable2, setwelTable2] = useState("1");

  const handleweldabilitytabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setweldability(key);
    }
  };
  const handlewelTable1Change = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setwelTable1(key);
    }
  };
  const handlewelTable2hange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setwelTable2(key);
    }
  };

  const handleTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setActiveTab(key);
    }
  };

  function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let text = doc.body.textContent || "";
    text = text.replace(/\u00a0/g, " "); // replace non-breaking spaces with regular spaces
    return text;
  }
  const handleSubTabChange = (key) => {
    if (formEdited) {
      message.warning("Please save the form first!");
    } else {
      setsubactiveTab(key);
    }
  };
  const [isUploading, setIsUploading] = useState(false);

  // function for handling image change for sustainaibility tab
  const handlesection1Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setsection1((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.image_id = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };

  const handleProcessimgChange = async (info) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setprocess_acrd_dtls((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.image_id = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };

  const handleweight_acrd_dtls_img = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          let updatedFields = [...weight_acrd_dtls];
          updatedFields[index].imageUrl = url;
          updatedFields[index].image_id = uploadResponse[0].id;
          setweight_acrd_dtls(updatedFields);
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };
  const handle_availabilityimg_Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setavl_acrd_dtls((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.image_id = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };
  const handle_codeapproveimg_Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setcode_acrd_dtls((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.image_id = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };
  const handle_toughnessimg_Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          settough_acrd_dtls((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.image_id = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };
  const handle_weldabilitysection_Change = async (info, index) => {
    const file = info.file.originFileObj;
    const fileType = file.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (!info.file) {
        //   console.error("info.file is undefined");
        return;
      }
      setIsUploading(true);

      // Manually set the status to 'done' if it's 'uploading'
      if (info.file.status === "uploading") {
        info.file.status = "done";
      }
      // Check if the file status is 'done'
      if (info.file.status === "done") {
        const formData = new FormData();
        formData.append("files", info.file.originFileObj);
        try {
          const uploadResponse = await uploadImageAPI(formData, userJwtToken);
          // Get the URL of the uploaded file
          const url = URL.createObjectURL(info.file.originFileObj);
          // Update the imageUrl of the corresponding banner field in the state
          setweld_acrd_dtls1((prevFields) => {
            const updatedFields = { ...prevFields };
            updatedFields.imageUrl = url;
            updatedFields.image_id = uploadResponse[0].id;
            return updatedFields;
          });
          // Update the form values with the new imageUrl
        } catch (error) {
          console.error("Failed to upload image:", error);
        } finally {
          // Set loading state to false when the upload is done
          setIsUploading(false);
        }
      } else {
        console.error("Unexpected file status:", info.file.status);
      }
    } else {
      alert("Please Upload pdf file");
    }
  };
  // ------------------------------------------------------- //

  // Function to render image preview
  const ImagePreview = ({ imageUrl }) => {
    // Declare state variable 'src' to store the image URL
    const [src, setSrc] = useState(imageUrl);
    // useEffect hook to update 'src' when 'imageUrl' changes
    useEffect(() => {
      // Update 'src' with the new 'imageUrl'
      setSrc(imageUrl);
    }, [imageUrl]);

    // Check if imageUrl is a blob URL or a data URL
    const isBlobOrDataUrl =
      src && (src.startsWith("blob:") || src.startsWith("data:image"));
    return src ? (
      <img
        src={isBlobOrDataUrl ? src : `${src}`} // Prepend the base URL only if imageUrl is not a blob URL or a data URL
        alt=""
      />
    ) : (
      <p>No Image</p>
    );
  };

  useEffect(() => {
    fetchData();
    fetchTableData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_Structural_a913_Data();
      const data = response.data;

      const a913_data = {
        sec2_mn_hd: response.data.data.attributes.sec2_mn_hd,
        a913_acrd_hd: response.data.data.attributes.a913_acrd_hd,
        process_acrd_hd: response.data.data.attributes.process_acrd_hd,
        weight_acrd_hd: response.data.data.attributes.weight_acrd_hd,
        weld_acrd_hd: response.data.data.attributes.weld_acrd_hd,
        tough_acrd_hd: response.data.data.attributes.tough_acrd_hd,
        code_acrd_hd: response.data.data.attributes.code_acrd_hd,
        seismic_acrd_hd: response.data.data.attributes.seismic_acrd_hd,
        avl_acrd_hd: response.data.data.attributes.avl_acrd_hd,
      };
      setA913formData(a913_data);

      const sec1_data = {
        title: response.data.data.attributes.sec1.title,
        description: response.data.data.attributes.sec1.description,
        page_url: response.data.data.attributes.sec1.page_url,
        img_credit: response.data.data.attributes.sec1.img_credit,
        image_id: response.data.data.attributes.sec1.image.data.id,
        imageUrl: response.data.data.attributes.sec1.image.data.attributes.url,
        img_alt_txt: response.data.data.attributes.sec1.img_alt_txt,
      };

      setsection1(sec1_data);

      const a913_table_data = {
        mn_tbl_hd1: response.data.data.attributes.a913_table.mn_tbl_hd1,
        mn_tbl_hd2: response.data.data.attributes.a913_table.mn_tbl_hd2,
        table_info1: response.data.data.attributes.a913_table.table_info1,
        table_info2: response.data.data.attributes.a913_table.table_info2,
      };
      seta913_table(a913_table_data);

      const process_acrd_dtls_data = {
        title: response.data.data.attributes.process_acrd_dtls.title,
        description:
          response.data.data.attributes.process_acrd_dtls.description,
        page_url: response.data.data.attributes.process_acrd_dtls.page_url,
        img_credit: response.data.data.attributes.process_acrd_dtls.img_credit,
        image_id: response.data.data.attributes.process_acrd_dtls.image.data.id,
        imageUrl:
          response.data.data.attributes.process_acrd_dtls.image.data.attributes
            .url,
        img_alt_txt:
          response.data.data.attributes.process_acrd_dtls.img_alt_txt,
      };

      setprocess_acrd_dtls(process_acrd_dtls_data);

      const weld_acrd_table1_data = {
        mn_tbl_hd1: response.data.data.attributes.weld_acrd_table1.mn_tbl_hd1,
        mn_tbl_hd2: response.data.data.attributes.weld_acrd_table1.mn_tbl_hd2,
        table_info1: response.data.data.attributes.weld_acrd_table1.table_info1,
        table_info2: response.data.data.attributes.weld_acrd_table1.table_info2,
      };
      setweld_acrd_table1(weld_acrd_table1_data);

      const weld_acrd_dtls1_data = {
        title: response.data.data.attributes.weld_acrd_dtls1.title,
        description: response.data.data.attributes.weld_acrd_dtls1.description,
        page_url: response.data.data.attributes.weld_acrd_dtls1.page_url,
        img_credit: response.data.data.attributes.weld_acrd_dtls1.img_credit,
        image_id: response.data.data.attributes.weld_acrd_dtls1.image.data.id,
        imageUrl:
          response.data.data.attributes.weld_acrd_dtls1.image.data.attributes
            .url,
        img_alt_txt: response.data.data.attributes.weld_acrd_dtls1.img_alt_txt,
      };
      setweld_acrd_dtls1(weld_acrd_dtls1_data);

      const weld_acrd_table2_data = {
        mn_tbl_hd1: response.data.data.attributes.weld_acrd_table2.mn_tbl_hd1,
        mn_tbl_hd2: response.data.data.attributes.weld_acrd_table2.mn_tbl_hd2,
        table_info1: response.data.data.attributes.weld_acrd_table2.table_info1,
        table_info2: response.data.data.attributes.weld_acrd_table2.table_info2,
      };
      setweld_acrd_table2(weld_acrd_table2_data);

      const tough_acrd_dtls_data = {
        title: response.data.data.attributes.tough_acrd_dtls.title,
        description: response.data.data.attributes.tough_acrd_dtls.description,
        page_url: response.data.data.attributes.tough_acrd_dtls.page_url,
        img_credit: response.data.data.attributes.tough_acrd_dtls.img_credit,
        image_id: response.data.data.attributes.tough_acrd_dtls.image.data.id,
        imageUrl:
          response.data.data.attributes.tough_acrd_dtls.image.data.attributes
            .url,
        img_alt_txt: response.data.data.attributes.tough_acrd_dtls.img_alt_txt,
      };
      settough_acrd_dtls(tough_acrd_dtls_data);

      const code_acrd_dtls_data = {
        title: response.data.data.attributes.code_acrd_dtls.title,
        description: response.data.data.attributes.code_acrd_dtls.description,
        page_url: response.data.data.attributes.code_acrd_dtls.page_url,
        img_credit: response.data.data.attributes.code_acrd_dtls.img_credit,
        image_id: response.data.data.attributes.code_acrd_dtls.image.data.id,
        imageUrl:
          response.data.data.attributes.code_acrd_dtls.image.data.attributes
            .url,
        img_alt_txt: response.data.data.attributes.code_acrd_dtls.img_alt_txt,
      };
      setcode_acrd_dtls(code_acrd_dtls_data);

      const seismic_acrd_dtls_data = {
        title: response.data.data.attributes.seismic_acrd_dtls.title,
        description:
          response.data.data.attributes.seismic_acrd_dtls.description,
        page_url: response.data.data.attributes.seismic_acrd_dtls.page_url,
        img_credit: response.data.data.attributes.seismic_acrd_dtls.img_credit,
        image_id: response.data.data.attributes.seismic_acrd_dtls.image.data.id,
        imageUrl:
          response.data.data.attributes.seismic_acrd_dtls.image.data.attributes
            .url,
      };
      setseismic_acrd_dtls(seismic_acrd_dtls_data);

      const avl_acrd_dtls_data = {
        title: response.data.data.attributes.avl_acrd_dtls.title,
        description: response.data.data.attributes.avl_acrd_dtls.description,
        page_url: response.data.data.attributes.avl_acrd_dtls.page_url,
        img_credit: response.data.data.attributes.avl_acrd_dtls.img_credit,
        image_id: response.data.data.attributes.avl_acrd_dtls.image.data.id,
        imageUrl:
          response.data.data.attributes.avl_acrd_dtls.image.data.attributes.url,
        img_alt_txt: response.data.data.attributes.avl_acrd_dtls.img_alt_txt,
      };

      setavl_acrd_dtls(avl_acrd_dtls_data);

      const weight_acrd_dtls_data =
        response.data.data.attributes.weight_acrd_dtls.map((item) => {
          return {
            title: item.title,
            description: item.description,
            image_id: item.image.data.id,
            imageUrl: item.image.data.attributes.url,
            img_alt_txt: item.img_alt_txt,
          };
        });

      setweight_acrd_dtls(weight_acrd_dtls_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTableData = async () => {
    try {
      const response = await get_a913mecaltbls();
      const data = response.data;

      const response1 = await get_a913weldbtbl1s();
      const data1 = response1.data;

      const response2 = await get_a913weldbtb2s();
      const data2 = response2.data;

      const a913mecaltbls_data = data.map((item) => ({
        Grade: item.attributes.Grade,
        A992: item.attributes.A992,
        A913_Gr_50: item.attributes.A913_Gr_50,
        A913_Gr_65: item.attributes.A913_Gr_65,
        A913_Gr_70: item.attributes.A913_Gr_70,
        A913_Gr_80: item.attributes.A913_Gr_80,
        key: item.id,
      }));

      const weldbtbl1_data = data1.map((item) => ({
        NAME: item.attributes.NAME,
        A992_Gr_50: item.attributes.A992_Gr_50,
        A913_Gr_50: item.attributes.A913_Gr_50,
        A913_Gr_65: item.attributes.A913_Gr_65,
        A913_Gr_70: item.attributes.A913_Gr_70,
        A913_Gr_80: item.attributes.A913_Gr_80,
        key: item.id,
      }));

      const weldbtbl2_data = data2.map((item) => ({
        Thickness: item.attributes.Thickness,
        A913_Gr_50_Gr_65: item.attributes.A913_Gr_50_Gr_65,
        A913_Gr_70: item.attributes.A913_Gr_70,
        A913_Gr_80: item.attributes.A913_Gr_80,
        key: item.id,
      }));

      setweltbl1_tabledataset(weldbtbl1_data);
      setweltbl2_tabledataset(weldbtbl2_data);
      seta913_tabledataset(a913mecaltbls_data);
    } catch (error) {}
  };

  const handleA913InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setA913formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const handleXcarb1InputChange = (changedValues, allValues) => {
    setFormEdited(true);
    setSection2formData((prevState) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const navigate = useNavigate();

  const a913save = async (index) => {
    setIsModalVisible(true);

    try {
      await a913form.validateFields();
      const data = {
        data: {
          section1_details: {
            description: A913formData.description,
            page_url: A913formData.page_url,
            title: A913formData.title,
            image: A913formData.image,
            existing_id: A913formData.existing_id,
            imagesUrl: A913formData.imagesUrl,
            // id: values.existing_id,
          },
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsModalVisible(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const section1_save = async (index) => {
    // setIsModalVisible(true);
    setIsUploading(true);

    const data = {
      data: {
        sec1: {
          title: section1.title,
          description: section1.description,

          image: section1.image_id,
          img_alt_txt: section1.img_alt_txt,
          existing_id: section1.existing_id,
        },
      },
    };

    try {
      Save_Structural_a913(data, userJwtToken)
        .then((response) => {
          message.success("Form saved successfully!");
          localStorage.setItem("formEdited", "false");
          setTimeout(() => {
            setFormEdited(false);
            localStorage.setItem("formEdited", "false");
            setTimeout(() => {
              setFormEdited(false);

              // If index is 1, proceed to the prev tab
              if (index === 1) {
                const nextTabIndex = parseInt(activeTab) + 1;
                setActiveTab(nextTabIndex.toString());
                setIsUploading(false);
              }
            }, 500);
          });
        })
        .catch((err) => {
        })
        .finally(() => {
          setIsUploading(false); 
        });
    } catch (error) {}
  };

  // --------------------------------------------------- //
  const process_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          process_acrd_dtls: {
            // title: process_acrd_dtls.title,
            description: process_acrd_dtls.description,
            image: process_acrd_dtls.image_id,
            img_alt_txt: process_acrd_dtls.img_alt_txt,
          },
          process_acrd_hd: A913formData.process_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(subactiveTab) - 1;
                setsubactiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(subactiveTab) + 1;
                setsubactiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const Weight_Save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      let weight_data = weight_acrd_dtls.map((item) => {
        return {
          description: item.description,
          image: item.image_id,
          title: item.title,
          img_credit: item.img_credit,
          img_alt_txt: item.img_alt_txt,
        };
      });
      const data = {
        data: {
          weight_acrd_dtls: weight_data,
          weight_acrd_hd: A913formData.weight_acrd_hd,
        },
      };
      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(subactiveTab) - 1;
                setsubactiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(subactiveTab) + 1;
                setsubactiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const avilability_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          avl_acrd_dtls: {
            title: avl_acrd_dtls.title,
            description: avl_acrd_dtls.description,
            image: avl_acrd_dtls.image_id,
            img_alt_txt: avl_acrd_dtls.img_alt_txt,
          },
          avl_acrd_hd: A913formData.avl_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(subactiveTab) - 1;
                setsubactiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(subactiveTab) + 1;
                setsubactiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const seismic_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          seismic_acrd_dtls: {
            description: seismic_acrd_dtls.description,
            image: seismic_acrd_dtls.image_id,
          },
          seismic_acrd_hd: A913formData.seismic_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(subactiveTab) - 1;
                setsubactiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(subactiveTab) + 1;
                setsubactiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const codeapprove_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          code_acrd_dtls: {
            description: code_acrd_dtls.description,
            image: code_acrd_dtls.image_id,
            img_alt_txt: code_acrd_dtls.img_alt_txt,
          },
          code_acrd_hd: A913formData.code_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(subactiveTab) - 1;
                setsubactiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(subactiveTab) + 1;
                setsubactiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const toughness_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          tough_acrd_dtls: {
            description: tough_acrd_dtls.description,
            image: tough_acrd_dtls.image_id,
            img_alt_txt: tough_acrd_dtls.img_alt_txt,
          },
          tough_acrd_hd: A913formData.tough_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(subactiveTab) - 1;
                setsubactiveTab(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(subactiveTab) + 1;
                setsubactiveTab(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const table1info_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          weld_acrd_table1: {
            mn_tbl_hd1: weld_acrd_table1.mn_tbl_hd1,
            table_info1: weld_acrd_table1.table_info1,
          },
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(welTable1) - 1;
                setwelTable1(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(welTable1) + 1;
                setwelTable1(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };
  const table2info_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          weld_acrd_table2: {
            mn_tbl_hd1: weld_acrd_table2.mn_tbl_hd1,
            mn_tbl_hd2: weld_acrd_table2.mn_tbl_hd2,
            table_info1: weld_acrd_table2.table_info1,
            table_info2: weld_acrd_table2.table_info2,
          },
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(welTable2) - 1;
                setwelTable2(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(welTable2) + 1;
                setwelTable2(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const accord_sec1_tab1_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          a913_acrd_hd: A913formData.a913_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(weldabilitytab) - 1;
                setweldability(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(weldabilitytab) + 1;
                setweldability(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const weldabilityheading_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          weld_acrd_hd: A913formData.weld_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(weldabilitytab) - 1;
                setweldability(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(weldabilitytab) + 1;
                setweldability(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const weldabilitysection_save = async (index) => {
    setIsUploading(true);

    try {
      await section2form.validateFields();
      const data = {
        data: {
          weld_acrd_dtls1: {
            description: weld_acrd_dtls1.description,
            image: weld_acrd_dtls1.image_id,
            img_alt_txt: weld_acrd_dtls1.img_alt_txt,
          },
          // tough_acrd_hd: A913formData.tough_acrd_hd,
        },
      };

      try {
        Save_Structural_a913(data, userJwtToken)
          .then((response) => {
            message.success("Form saved successfully!");
            setTimeout(() => {
              setFormEdited(false);

              if (index === 0) {
                const prevTabIndex = parseInt(weldabilitytab) - 1;
                setweldability(prevTabIndex.toString());
              }
              if (index === 2) {
                const nextTabIndex = parseInt(weldabilitytab) + 1;
                setweldability(nextTabIndex.toString());
              }
            }, 500);
          })
          .catch((err) => {
          })
          .finally(() => {
            setIsUploading(false); 
          });
      } catch (error) {
        console.error("Failed to post data to the Strapi backend:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      // Handle form validation error
      console.error("Form validation failed:", error);
      setIsModalVisible(false);
    }
  };

  const xcarb1save = () => {};

  // const a913_tablesave = async (index)=>{

  // }

  // --------------------------------------------------- //

  const onFinish = async (values) => {
    try {
      const mappedRecord = {
        // key: values.key,
        Grade: values.Grade,
        A992: values.A992,
        A913_Gr_50: values.A913_Gr_50,
        A913_Gr_65: values.A913_Gr_65,
        A913_Gr_70: values.A913_Gr_70,
        A913_Gr_80: values.A913_Gr_80,
      };
      let data = {
        data: mappedRecord,
      };

      if (values.key === 0) {
        Save_a913mecaltbls_product(data, userJwtToken).then((response) => {
          fetchTableData();
          setIsModalVisible(false);
          message.success("Form saved successfully!");
          // setTimeout(() => {
          //   // If index is 1, proceed to the prev tab
          //   if (index === 1) {
          //     const nextTabIndex = parseInt(activeTab) + 1;
          //     setActiveTab(nextTabIndex.toString());
          //     setIsModalVisible(false);
          //   }
          // }, 500);
        });
      } else {
        Save_a913mecaltbls_product_ByID(data, values.key, userJwtToken).then(
          (response) => {
            fetchTableData();
            setIsModalVisible(false);

            message.success("Form saved successfully!");
            // setTimeout(() => {
            //   // If index is 1, proceed to the prev tab
            //   if (index === 1) {
            //     const nextTabIndex = parseInt(activeTab) + 1;
            //     setActiveTab(nextTabIndex.toString());
            //     setIsModalVisible(false);
            //   }
            // }, 500);
          }
        );
      }

      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const onFinish1 = async (values) => {
    try {
      const mappedRecord = {
        NAME: values.NAME,
        A992_Gr_50: values.A992_Gr_50,
        A913_Gr_50: values.A913_Gr_50,
        A913_Gr_65: values.A913_Gr_65,
        A913_Gr_70: values.A913_Gr_70,
        A913_Gr_80: values.A913_Gr_80,
      };
      let data = {
        data: mappedRecord,
      };

      if (values.key === 0) {
        Save_a913weldbtbl1s_product(data, userJwtToken).then((response) => {
          fetchTableData();
          setIsModalVisible1(false);
          message.success("Form saved successfully!");
          // setTimeout(() => {
          //   // If index is 1, proceed to the prev tab
          //   if (index === 1) {
          //     const nextTabIndex = parseInt(activeTab) + 1;
          //     setActiveTab(nextTabIndex.toString());
          //     setIsModalVisible(false);
          //   }
          // }, 500);
        });
      } else {
        Save_a913weldbtbl1s_product_ByID(data, values.key, userJwtToken).then(
          (response) => {
            fetchTableData();
            setIsModalVisible(false);

            message.success("Form saved successfully!");
            // setTimeout(() => {
            //   // If index is 1, proceed to the prev tab
            //   if (index === 1) {
            //     const nextTabIndex = parseInt(activeTab) + 1;
            //     setActiveTab(nextTabIndex.toString());
            //     setIsModalVisible(false);
            //   }
            // }, 500);
          }
        );
      }

      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const onFinish2 = async (values) => {
    try {
      const mappedRecord = {
        // key: values.key,
        Thickness: values.Thickness,
        A913_Gr_50_Gr_65: values.A913_Gr_50_Gr_65,
        A913_Gr_70: values.A913_Gr_70,
        A913_Gr_80: values.A913_Gr_80,
      };
      let data = {
        data: mappedRecord,
      };

      if (values.key === 0) {
        Save_a913weldbtbl2s_product(data, userJwtToken).then((response) => {
          fetchTableData();
          setIsModalVisible2(false);
          message.success("Form saved successfully!");
          // setTimeout(() => {
          //   // If index is 1, proceed to the prev tab
          //   if (index === 1) {
          //     const nextTabIndex = parseInt(activeTab) + 1;
          //     setActiveTab(nextTabIndex.toString());
          //     setIsModalVisible(false);
          //   }
          // }, 500);
        });
      } else {
        Save_a913weldbtbl2s_product_ByID(data, values.key, userJwtToken).then(
          (response) => {
            fetchTableData();
            setIsModalVisible2(false);

            message.success("Form saved successfully!");
            // setTimeout(() => {
            //   // If index is 1, proceed to the prev tab
            //   if (index === 1) {
            //     const nextTabIndex = parseInt(activeTab) + 1;
            //     setActiveTab(nextTabIndex.toString());
            //     setIsModalVisible(false);
            //   }
            // }, 500);
          }
        );
      }

      // Perform save operation here
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const cleanHTML = (html) => {
    // Use a DOMParser to parse the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove all <p><br></p> elements
    doc.querySelectorAll("p").forEach((p) => {
      if (p.innerHTML === "<br>") {
        p.remove();
      }
    });
    return doc.body.innerHTML;
  };

  // -------------------------------- //
  useEffect(() => {
    // Store formEdited state in local storage whenever it changes
    localStorage.setItem("formEdited", formEdited);
  }, [formEdited]);
  // -------------------------------- //
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <>
      <Layout>
        <Headercomp />
        <div className="innerbody-pad">
          <div className="main-head">
            {/* <h1>A913 High-Strength Steel</h1> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                onClick={() => navigate("/admin/structuralshapes")}
                style={{ marginRight: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
              <h1>A913 High-Strength Steel</h1>
            </div>
          </div>
          <Row>
            <Col span={24}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="Section 1 " key="1">
                  <div className="note-info">
                    <h5>NOTE*</h5>
                    <ul className="m-0 p-0">
                      <li>
                        <b>30 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                        <b>'Title'</b> field is recommended.
                      </li>
                      <li>
                        <b>1200 Characters</b> max including{" "}
                        <b>alphabets, spaces, symbols and numbers</b> for the{" "}
                        <b>'Description'</b> field is recommended.
                      </li>
                      <li>
                        Please maintain the image resolution of the{" "}
                        <b>'Main image'</b> to exact{" "}
                        <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to avoid design
                        break. Please use file types: <b>jpg/png</b> . Image
                        size less than <b>500 kb</b> is recommended for better
                        performance.
                      </li>
                      <li>
                        Please remember to click <b>'Save'</b> after uploading
                        an <b>image or PDF</b> to ensure your changes are
                        updated.
                      </li>
                    </ul>
                  </div>
                  {section1.title && (
                    <Form
                      name="section1a913"
                      layout="vertical"
                      form={a913form}
                      initialValues={section1}
                      onValuesChange={handleA913InputChange}
                      onFinish={onFinish}
                      // ref={a913formRef}
                      onChange={() => setFormEdited(true)}
                    >
                      <Row>
                        <Col span={4}>
                          <Form.Item
                            label="Title"
                            name="title"
                            initialValue={section1.title}
                            // initialValue={A913formData.title}

                            //A913formData
                            rules={[
                              {
                                required: true,
                                message: "Please input the title!",
                              },
                            ]}
                            onChange={(e) =>
                              setsection1((prv) => {
                                // let data =

                                prv.title = e.target.value;
                                return prv;
                              })
                            }
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: "Please input Description!",
                              },
                            ]}
                            initialValue={section1.description}
                          >
                            {/* <Input /> */}
                            <ReactQuill
                              value={section1.description}
                              onChange={(content, delta, source, editor) => {
                                if (
                                  cleanHTML(content) !==
                                  cleanHTML(section1.description)
                                ) {
                                  setsection1((prv) => {
                                    // let data =
                                    prv.description = cleanHTML(content);
                                    return prv;
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            label="Image"
                            name="image"
                            rules={[
                              {
                                required: true,
                                message: "Please upload an Image!",
                              },
                            ]}
                          >
                            <div className="up-del">
                              {" "}
                              {/* Container for image upload and delete */}
                              <div className="img-preview">
                                {" "}
                                {/* Image preview container */}
                                <ImagePreview // Component to render image preview
                                  key={section1.imageUrl}
                                  imageUrl={section1.imageUrl}
                                />
                              </div>
                              {
                                <Upload // Upload component for image
                                  name="avatar"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                  onChange={(info) =>
                                    handlesection1Change(info)
                                  } // Handle image upload change
                                >
                                  <Button icon={<UploadOutlined />} />
                                </Upload>
                              }
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Alt/Title Text"
                            name="img_alt_txt" // Name attribute for form field
                            initialValue={section1.img_alt_txt} // Initial value for the field
                            onChange={(e) =>
                              setsection1((prv) => {
                                // let data =

                                prv.img_alt_txt = e.target.value;
                                return prv;
                              })
                            }
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input Alt/Title Text!",
                            //   }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label="Existing image id"
                            name="existing_img_id"
                            hidden
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="Existing id"
                            name="existing_id"
                            hidden
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <div className="savebtns">
                        <Space>
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={() => section1_save(0)}
                            >
                              Save
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={() => section1_save(1)}
                            >
                              Save & Continue
                            </Button>
                          </Form.Item>
                        </Space>
                      </div>
                    </Form>
                  )}

                  {isUploading && (
                    <div className="spinner-loading">
                      <Spin
                        indicator={
                          <LoadingOutlined className="spin-icon" spin />
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Accodian Section" key="2">
                  <Tabs
                    activeKey={subactiveTab}
                    onChange={handleSubTabChange}
                    className="sub_tab"
                  >
                    <Tabs.TabPane tab="A913 " key="1">
                      <Tabs
                        activeKey={weldabilitytab}
                        onChange={handleweldabilitytabChange}
                      >
                        <Tabs.TabPane tab="Tab 1" key="1" className="sub_tab">
                          <Form
                            name="xcarb1"
                            layout="vertical"
                            // form={section2form}
                            // onValuesChange={handleXcarb1InputChange}
                            // onFinish={onFinish}
                            // ref={section2formRef}
                            onChange={() => setFormEdited(true)}
                            form={section2form}
                          >
                            <Row>
                              <Col span={14}>
                                <Form.Item
                                  label="Accordion heading"
                                  name="a913_acrd_hd"
                                  // initialValue={weld_acrd_dtls1.img_alt_txt}
                                  initialValue={A913formData.a913_acrd_hd}
                                  onChange={(e) => {
                                    setA913formData((prv) => {
                                      prv.a913_acrd_hd = e.target.value;
                                      return prv;
                                    });
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input the Accordion heading!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  label="Existing image id"
                                  name="existing_img_id"
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Existing id"
                                  name="existing_id"
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="savebtns">
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => accord_sec1_tab1_save(1)}
                                  >
                                    Save
                                  </Button>
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => accord_sec1_tab1_save(2)}
                                  >
                                    Save & Continue
                                  </Button>
                                </Form.Item>
                              </Space>
                            </div>
                          </Form>
                          {isModalVisible && (
                            <div className="spinner-loading">
                              {/* <Spin size="large" /> */}
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            </div>
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Tab 2" key="2">
                          <Button
                            type="primary"
                            className="add-fields"
                            shape="circle"
                            icon={<PlusOutlined />}
                            onClick={handleAddFields}
                          />
                          <div style={{ overflowX: "auto" }}>
                            <Table
                              columns={a913mecaltbls_Table_columns}
                              dataSource={a913_tabledataset}
                              // dataSource={Section3tableformData}
                            />
                          </div>

                          <Modal
                            title=""
                            open={isModalVisible}
                            onCancel={handleCancel}
                            footer={null}
                          >
                            <Form
                              form={form}
                              name="basic"
                              initialValues={a913mecaltbls_TableData}
                              onFinish={onFinish}
                              // autoComplete="off"
                              layout="vertical"
                            >
                              <Row>
                                <Col span={8} style={{ display: "none" }}>
                                  <Form.Item
                                    name="key"
                                    label="Section Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Section title!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name="Grade"
                                    label="Grade"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input grade!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={6}>
                                  <Form.Item name="A992" label="A992">
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name="A913_Gr_50"
                                    label="A913 Gr 50"
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name="A913_Gr_65"
                                    label="A913 Gr 65"
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name="A913_Gr_70"
                                    label="A913 Gr 70"
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name="A913_Gr_80"
                                    label="A913 Gr 80"
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>

                              {/* 
                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item> */}

                              <div
                                // className="savebtns"
                                style={{
                                  marginTop: 20,
                                  alignItems: "flex-end",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Space>
                                  <Form.Item>
                                    <Button
                                      type="primary"
                                      onClick={() => handleCancel()}
                                    >
                                      Cancel
                                    </Button>
                                  </Form.Item>
                                  <Form.Item>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                    >
                                      Save
                                    </Button>
                                  </Form.Item>
                                </Space>
                              </div>
                            </Form>
                          </Modal>

                          {isUploading && (
                            <div className="spinner-loading">
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            </div>
                          )}
                        </Tabs.TabPane>
                      </Tabs>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Process" key="2">
                      <div className="note-info">
                        <h5>NOTE*</h5>
                        <ul className="m-0 p-0">
                          <li>
                            <b>30 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Title'</b> field is recommended.
                          </li>
                          <li>
                            <b>700 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Description'</b> field is recommended.
                          </li>
                          <li>
                            Please maintain the image resolution of the{" "}
                            <b>'Main image'</b> to exact{" "}
                            <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to avoid
                            design break. Please use file types: <b>jpg/png</b>{" "}
                            . Image size less than <b>500 kb</b> is recommended
                            for better performance.
                          </li>
                          <li>
                            Please remember to click <b>'Save'</b> after
                            uploading an
                            <b>image or PDF</b> to ensure your changes are
                            updated.
                          </li>
                        </ul>
                      </div>
                      <Form
                        name="xcarb1"
                        layout="vertical"
                        form={section2form1}
                        // onValuesChange={handleXcarb1InputChange}
                        // onFinish={onFinish}
                        // ref={section2formRef}
                        onChange={() => setFormEdited(true)}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label="Title"
                              name="title"
                              initialValue={A913formData.process_acrd_hd}
                              onChange={(e) => {
                                setA913formData((prv) => {
                                  prv.process_acrd_hd = e.target.value;
                                  return prv;
                                });
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the title!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="Description"
                              name="description"
                              initialValue={process_acrd_dtls.description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Description!",
                                },
                              ]}
                            >
                              {/* <Input /> */}
                              <ReactQuill
                                modules={modules}
                                value={process_acrd_dtls.description}
                                onChange={(content, delta, source, editor) =>
                                  setprocess_acrd_dtls((prv) => {
                                    prv.description = content;
                                    return prv;
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              label="Image"
                              name="image"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please upload an Image!",
                              //   },
                              // ]}
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value || process_acrd_dtls.imageUrl
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error("Please upload an Image!")
                                        ),
                                },
                              ]}
                            >
                              <div className="up-del">
                                {" "}
                                {/* Container for image upload and delete */}
                                <div className="img-preview">
                                  {" "}
                                  {/* Image preview container */}
                                  <ImagePreview // Component to render image preview
                                    key={process_acrd_dtls.imageUrl}
                                    imageUrl={process_acrd_dtls.imageUrl}
                                  />
                                </div>
                                {
                                  <Upload // Upload component for image
                                    name="avatar"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                    onChange={(info) =>
                                      handleProcessimgChange(info)
                                    } // Handle image upload change
                                  >
                                    <Button icon={<UploadOutlined />} />
                                  </Upload>
                                }
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label="Alt/Title Text"
                              name="img_alt_txt"
                              initialValue={process_acrd_dtls.img_alt_txt}
                              onChange={(e) => {
                                setprocess_acrd_dtls((prv) => {
                                  prv.img_alt_txt = e.target.value;
                                  return prv;
                                });
                              }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Existing image id"
                              name="existing_img_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Existing id"
                              name="existing_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="savebtns">
                          <Space>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => process_save(0)}
                              >
                                Save & go back
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => process_save(1)}
                              >
                                Save
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => process_save(2)}
                              >
                                Save & Continue
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      </Form>
                      {isModalVisible && (
                        <div className="spinner-loading">
                          {/* <Spin size="large" /> */}
                          <Spin
                            indicator={
                              <LoadingOutlined className="spin-icon" spin />
                            }
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Weight Savings" key="3">
                      <div className="note-info">
                        <h5>NOTE*</h5>
                        <ul className="m-0 p-0">
                          <li>
                            <b>30 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Title'</b> field is recommended.
                          </li>
                          <li>
                            <b>700 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Description'</b> field is recommended.
                          </li>
                          <li>
                            Please maintain the image resolution of the{" "}
                            <b>'Main image'</b> to exact{" "}
                            <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to avoid
                            design break. Please use file types: jpg/png . Image
                            size less than <b>500 kb</b> is recommended for
                            better performance.
                          </li>
                          <li>
                            Please remember to click <b>'Save'</b> after
                            uploading an
                            <b>image or PDF</b> to ensure your changes are
                            updated.
                          </li>
                        </ul>
                      </div>
                      <Form
                        //   name="banner"
                        //   layout="vertical"
                        //   // onFinish={handleBannerFinish} // Handle form submission
                        //   onChange={() => setFormEdited(true)} // Set formEdited state to true when any field changes
                        // ref={BannerformRef} // Reference to the form for accessing form values
                        name="Banner"
                        layout="vertical"
                        form={section2form2}
                        // initialValues={SeoformData}
                        //   onValuesChange={handleSeoInputChange}
                        //   onFinish={onFinish}
                        onChange={() => setFormEdited(true)}
                      >
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              label="Title"
                              name="title"
                              initialValue={A913formData.weight_acrd_hd}
                              onChange={(e) => {
                                setA913formData((prv) => {
                                  prv.weight_acrd_hd = e.target.value;
                                  return prv;
                                });
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Title!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        {weight_acrd_dtls.map(
                          (
                            field,
                            index // Map through banner form fields array
                          ) => (
                            // Each row represents a banner form field
                            <Row key={field.key}>
                              <Col span={14}>
                                <Form.Item
                                  label=" Description"
                                  name={[field.key, "description"]}
                                  initialValue={field.description}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "11px",
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Banner Description!",
                                    },
                                  ]}
                                >
                                  <p style={{ display: "none" }}>
                                    {field.description}
                                  </p>
                                  <ReactQuill
                                    modules={modules}
                                    value={field.description}
                                    onChange={(content) => {
                                      if (
                                        cleanHTML(content) !==
                                        cleanHTML(field.description)
                                      ) {
                                        setFormEdited(true);
                                        const newData = [...weight_acrd_dtls];
                                        newData[index].description =
                                          cleanHTML(content);
                                        setweight_acrd_dtls(newData);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label="Image"
                                  name={[field.key, "imageUrl"]}
                                  initialValue={field.imageUrl}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please upload Banner Image!",
                                    },
                                  ]}
                                >
                                  <div className="up-del">
                                    {/* Container for image upload and delete */}
                                    <div className="img-preview">
                                      {/* Image preview container */}
                                      {isUploading[index] ? (
                                        <Spin
                                          indicator={
                                            <LoadingOutlined
                                              className="spin-icon"
                                              spin
                                            />
                                          }
                                        />
                                      ) : (
                                        <ImagePreview // Component to render image preview
                                          key={weight_acrd_dtls[index].imageUrl}
                                          imageUrl={
                                            weight_acrd_dtls[index].imageUrl
                                          }
                                        />
                                      )}
                                    </div>
                                    {
                                      <Upload // Upload component for image
                                        name="avatar"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                        onChange={(info) =>
                                          handleweight_acrd_dtls_img(
                                            info,
                                            index
                                          )
                                        } // Handle image upload change
                                      >
                                        <Button icon={<UploadOutlined />} />
                                        {/* {isUploading[index] ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          className="spin-icon"
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    <Button icon={<UploadOutlined />} />
                                  )} */}
                                      </Upload>
                                    }
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  label="Alt/Title Text"
                                  name={[field.key, "img_alt_txt"]}
                                  initialValue={field.img_alt_txt}
                                >
                                  <p style={{ display: "none" }}>
                                    {field.img_alt_txt}
                                  </p>
                                  <Input
                                    value={field.img_alt_txt}
                                    onChange={(e) => {
                                      const newData = [...weight_acrd_dtls];
                                      newData[index].img_alt_txt =
                                        e.target.value;
                                      setweight_acrd_dtls(newData);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )
                        )}

                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Existing image id"
                              name="existing_img_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Existing id"
                              name="existing_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="savebtns">
                          <Space>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => Weight_Save(0)}
                              >
                                Save & go back
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => Weight_Save(1)}
                              >
                                Save
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => Weight_Save(2)}
                              >
                                Save & Continue
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      </Form>
                      {isModalVisible && (
                        <div className="spinner-loading">
                          {/* <Spin size="large" /> */}
                          <Spin
                            indicator={
                              <LoadingOutlined className="spin-icon" spin />
                            }
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Weldability" key="4" className="sub_tab">
                      <Tabs
                        activeKey={weldabilitytab}
                        onChange={handleweldabilitytabChange}
                      >
                        <Tabs.TabPane tab="Heading" key="1">
                          <Form
                            name="xcarb1"
                            layout="vertical"
                            form={section2form}
                            // onValuesChange={handleXcarb1InputChange}
                            // onFinish={onFinish}
                            // ref={section2formRef}
                            onChange={() => setFormEdited(true)}
                          >
                            <Row>
                              <Col span={4}>
                                <Form.Item
                                  label="Heading"
                                  name="weld_acrd_hd"
                                  initialValue={A913formData.weld_acrd_hd}
                                  onChange={(e) => {
                                    setA913formData((prv) => {
                                      prv.weld_acrd_hd = e.target.value;
                                      return prv;
                                    });
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the title!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  label="Existing image id"
                                  name="existing_img_id"
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Existing id"
                                  name="existing_id"
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="savebtns">
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => weldabilityheading_save(1)}
                                  >
                                    Save
                                  </Button>
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => weldabilityheading_save(2)}
                                  >
                                    Save & Continue
                                  </Button>
                                </Form.Item>
                              </Space>
                            </div>
                          </Form>
                          {isModalVisible && (
                            <div className="spinner-loading">
                              {/* <Spin size="large" /> */}
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            </div>
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Table 1" key="2" className="sub_tab">
                          <Tabs
                            activeKey={welTable1}
                            onChange={handlewelTable1Change}
                          >
                            <Tabs.TabPane tab="Table Information" key="1">
                              <Form
                                name="xcarb1"
                                layout="vertical"
                                form={section2form}
                                // onValuesChange={handleXcarb1InputChange}
                                // onFinish={onFinish}
                                // ref={section2formRef}
                                onChange={() => setFormEdited(true)}
                              >
                                <Row>
                                  <Col span={6}>
                                    <Form.Item
                                      label="Table Header"
                                      name="tableheader"
                                      initialValue={weld_acrd_table1.mn_tbl_hd1}
                                      onChange={(e) => {
                                        setweld_acrd_table1((prv) => {
                                          prv.mn_tbl_hd1 = e.target.value;
                                          return prv;
                                        });
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input Table Header!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={14}>
                                    <Form.Item
                                      label="Table Info"
                                      name="tableinfo"
                                      initialValue={
                                        weld_acrd_table1.table_info1
                                      }
                                      onChange={(e) => {
                                        setweld_acrd_table1((prv) => {
                                          prv.table_info1 = e.target.value;
                                          return prv;
                                        });
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input Table Info!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <div className="savebtns1">
                                  <Space>
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        onClick={() => table1info_save(1)}
                                      >
                                        Save
                                      </Button>
                                    </Form.Item>
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        onClick={() => table1info_save(2)}
                                      >
                                        Save & Continue
                                      </Button>
                                    </Form.Item>
                                  </Space>
                                </div>
                              </Form>
                              {isModalVisible && (
                                <div className="spinner-loading">
                                  {/* <Spin size="large" /> */}
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                </div>
                              )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Data " key="2">
                              <Button
                                type="primary"
                                className="add-fields"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={handleAdd1Fields}
                              />
                              <div style={{ overflowX: "auto" }}>
                                <Table
                                  columns={weltable1_Table_columns}
                                  dataSource={weltbl1_tabledataset}
                                  // dataSource={Section3tableformData}
                                />
                              </div>

                              <Modal
                                title=""
                                open={isModalVisible1}
                                onCancel={handleCancel1}
                                // footer={[
                                //   <Button key="back" onClick={handleCancel}>
                                //     Cancel
                                //   </Button>,
                                //   <Button
                                //     key="submit"
                                //     type="primary"
                                //     onClick={() => section3tablesave(1)}
                                //   >
                                //     Save
                                //   </Button>,
                                // ]}
                                footer={null}
                              >
                                <Form
                                  form={form1}
                                  name="basic"
                                  initialValues={a913mecaltbls_TableData}
                                  onFinish={onFinish1}
                                  autoComplete="off"
                                  layout="vertical"
                                >
                                  <Row>
                                    <Col span={8} style={{ display: "none" }}>
                                      <Form.Item
                                        name="key"
                                        label="Section Title"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input Section title!",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name="NAME"
                                        label="Name"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please input name!",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={6}>
                                      <Form.Item
                                        name="A992_Gr_50"
                                        label="A992 Gr 50"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        name="A913_Gr_50"
                                        label="A913 Gr 50"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        name="A913_Gr_65"
                                        label="A913 Gr 65"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        name="A913_Gr_70"
                                        label="A913 Gr 70"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        name="A913_Gr_80"
                                        label="A913 Gr 80"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  {/* 
                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item> */}

                                  <div
                                    // className="savebtns"
                                    style={{
                                      marginTop: 20,
                                      alignItems: "flex-end",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Space>
                                      <Form.Item>
                                        <Button
                                          type="primary"
                                          onClick={() => handleCancel1()}
                                        >
                                          Cancel
                                        </Button>
                                      </Form.Item>
                                      <Form.Item>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                        >
                                          Save
                                        </Button>
                                      </Form.Item>
                                    </Space>
                                  </div>
                                </Form>
                              </Modal>

                              {isUploading && (
                                <div className="spinner-loading">
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                </div>
                              )}
                            </Tabs.TabPane>
                          </Tabs>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Section" key="3">
                          <div className="note-info">
                            <h5>NOTE*</h5>
                            <ul className="m-0 p-0">
                              <li>
                                <b>700 Characters</b> max including{" "}
                                <b>alphabets, spaces, symbols and numbers</b>{" "}
                                for the <b>'Description'</b> field is
                                recommended.
                              </li>
                              <li>
                                Please maintain the image resolution of the
                                <b>'Image'</b> to exact{" "}
                                <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to
                                avoid design break. Please use file types:
                                jpg/png . Image size less than <b>500 kb</b> is
                                recommended for better performance.
                              </li>
                              <li>
                                Please remember to click <b>'Save'</b> after
                                uploading an <b>image or PDF</b> to ensure your
                                changes are updated.
                              </li>
                            </ul>
                          </div>
                          <Form
                            name="xcarb1"
                            layout="vertical"
                            // form={section2form}
                            // onValuesChange={handleXcarb1InputChange}
                            // onFinish={onFinish}
                            // ref={section2formRef}
                            onChange={() => setFormEdited(true)}
                          >
                            <Row>
                              <Col span={14}>
                                <Form.Item
                                  label="Description"
                                  name="description"
                                  initialValue={weld_acrd_dtls1.description}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Description!",
                                    },
                                  ]}
                                >
                                  {/* <Input /> */}
                                  <ReactQuill
                                    value={weld_acrd_dtls1.description}
                                    onChange={(
                                      content,
                                      delta,
                                      source,
                                      editor
                                    ) => {
                                      if (
                                        cleanHTML(content) !==
                                        cleanHTML(weld_acrd_dtls1.description)
                                      ) {
                                        setweld_acrd_dtls1((prv) => {
                                          prv.description = cleanHTML(content);
                                          return prv;
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={4}>
                                <Form.Item
                                  label="Image"
                                  name="image"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please upload an Image!",
                                    },
                                  ]}
                                >
                                  <div className="up-del">
                                    {" "}
                                    {/* Container for image upload and delete */}
                                    <div className="img-preview">
                                      {" "}
                                      {/* Image preview container */}
                                      <ImagePreview // Component to render image preview
                                        key={weld_acrd_dtls1.imageUrl}
                                        imageUrl={weld_acrd_dtls1.imageUrl}
                                      />
                                    </div>
                                    {
                                      <Upload // Upload component for image
                                        name="avatar"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                        onChange={(info) =>
                                          handle_weldabilitysection_Change(info)
                                        } // Handle image upload change
                                      >
                                        <Button icon={<UploadOutlined />} />
                                      </Upload>
                                    }
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  label="Alt/Title Text"
                                  name="img_alt_txt"
                                  initialValue={weld_acrd_dtls1.img_alt_txt}
                                  onChange={(e) => {
                                    setweld_acrd_dtls1((prv) => {
                                      prv.img_alt_txt = e.target.value;
                                      return prv;
                                    });
                                  }}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please input the title!",
                                  //   },
                                  // ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  label="Existing image id"
                                  name="existing_img_id"
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Existing id"
                                  name="existing_id"
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="savebtns">
                              <Space>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => weldabilitysection_save(0)}
                                  >
                                    Save & go back
                                  </Button>
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => weldabilitysection_save(1)}
                                  >
                                    Save
                                  </Button>
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    onClick={() => weldabilitysection_save(2)}
                                  >
                                    Save & Continue
                                  </Button>
                                </Form.Item>
                              </Space>
                            </div>
                          </Form>
                          {isModalVisible && (
                            <div className="spinner-loading">
                              {/* <Spin size="large" /> */}
                              <Spin
                                indicator={
                                  <LoadingOutlined className="spin-icon" spin />
                                }
                              />
                            </div>
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Table 2" key="4">
                          <Tabs
                            activeKey={welTable2}
                            onChange={handlewelTable2hange}
                          >
                            <Tabs.TabPane
                              tab="Table information"
                              key="1"
                              className="sub_tab"
                            >
                              <Form
                                name="xcarb1"
                                layout="vertical"
                                form={section2form}
                                // onValuesChange={handleXcarb1InputChange}
                                // onFinish={onFinish}
                                // ref={section2formRef}
                                onChange={() => setFormEdited(true)}
                              >
                                <Row>
                                  <Col span={6}>
                                    <Form.Item
                                      label="First Heading"
                                      name="title"
                                      initialValue={weld_acrd_table2.mn_tbl_hd1}
                                      onChange={(e) => {
                                        setweld_acrd_table2((prv) => {
                                          prv.mn_tbl_hd1 = e.target.value;
                                          return prv;
                                        });
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input First Heading!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      label="Second Heading"
                                      name="description1"
                                      initialValue={weld_acrd_table2.mn_tbl_hd2}
                                      onChange={(e) => {
                                        setweld_acrd_table2((prv) => {
                                          prv.weld_acrd_table2 = e.target.value;
                                          return prv;
                                        });
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input Second Heading!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      label="Table Info1"
                                      name="description2"
                                      initialValue={
                                        weld_acrd_table2.table_info1
                                      }
                                      onChange={(e) => {
                                        setweld_acrd_table2((prv) => {
                                          prv.table_info1 = e.target.value;
                                          return prv;
                                        });
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input Table Info1!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      label="Table info2"
                                      name="description3"
                                      initialValue={
                                        weld_acrd_table2.table_info2
                                      }
                                      onChange={(e) => {
                                        setweld_acrd_table2((prv) => {
                                          prv.table_info2 = e.target.value;
                                          return prv;
                                        });
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input Table info2!",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <div className="savebtns">
                                  <Space>
                                    {/* <Form.Item>
                                      <Button
                                        type="primary"
                                        onClick={() => table2info_save(0)}
                                      >
                                        Save & go back
                                      </Button>
                                    </Form.Item> */}
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        onClick={() => table2info_save(1)}
                                      >
                                        Save
                                      </Button>
                                    </Form.Item>
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        onClick={() => table2info_save(2)}
                                      >
                                        Save & Continue
                                      </Button>
                                    </Form.Item>
                                  </Space>
                                </div>
                              </Form>
                              {isModalVisible && (
                                <div className="spinner-loading">
                                  {/* <Spin size="large" /> */}
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                </div>
                              )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Data " key="2">
                              <Button
                                type="primary"
                                className="add-fields"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={handleAdd2Fields}
                              />
                              <div style={{ overflowX: "auto" }}>
                                <Table
                                  columns={weltable2_Table_columns}
                                  dataSource={weltbl2_tabledataset}
                                  // dataSource={Section3tableformData}
                                />
                              </div>

                              <Modal
                                title=""
                                open={isModalVisible2}
                                onCancel={handleCancel2}
                                // footer={[
                                //   <Button key="back" onClick={handleCancel}>
                                //     Cancel
                                //   </Button>,
                                //   <Button
                                //     key="submit"
                                //     type="primary"
                                //     onClick={() => section3tablesave(1)}
                                //   >
                                //     Save
                                //   </Button>,
                                // ]}
                                footer={null}
                              >
                                <Form
                                  form={form2}
                                  name="basic"
                                  initialValues={a913mecaltbls_TableData}
                                  onFinish={onFinish2}
                                  // autoComplete="off"
                                  layout="vertical"
                                >
                                  <Row>
                                    <Col span={1} style={{ display: "none" }}>
                                      <Form.Item
                                        name="key"
                                        label="Section Title"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input Section title!",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Thickness"
                                        label="Thickness"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please input thickness!",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="A913_Gr_50_Gr_65"
                                        label="A913 Gr 50 Gr 65"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={12}>
                                      <Form.Item
                                        name="A913_Gr_70"
                                        label="A913 Gr 70"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="A913_Gr_80"
                                        label="A913 Gr 80"
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  {/* 
                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item> */}

                                  <div
                                    // className="savebtns"
                                    style={{
                                      marginTop: 20,
                                      alignItems: "flex-end",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Space>
                                      <Form.Item>
                                        <Button
                                          type="primary"
                                          onClick={() => handleCancel2()}
                                        >
                                          Cancel
                                        </Button>
                                      </Form.Item>
                                      <Form.Item>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                        >
                                          Save
                                        </Button>
                                      </Form.Item>
                                    </Space>
                                  </div>
                                </Form>
                              </Modal>

                              {isUploading && (
                                <div className="spinner-loading">
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        className="spin-icon"
                                        spin
                                      />
                                    }
                                  />
                                </div>
                              )}
                            </Tabs.TabPane>
                          </Tabs>
                        </Tabs.TabPane>
                      </Tabs>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Toughness" key="5">
                      <div className="note-info">
                        <h5>NOTE*</h5>
                        <ul className="m-0 p-0">
                          <li>
                            <b>30 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Title'</b> field is recommended.
                          </li>
                          <li>
                            <b>700 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Description'</b> field is recommended.
                          </li>
                          <li>
                            Please maintain the image resolution of the{" "}
                            <b>'Main image'</b> to exact{" "}
                            <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to avoid
                            design break. Please use file types: <b>jpg/png</b>{" "}
                            . Image size less than <b>500 kb</b> is recommended
                            for better performance.
                          </li>
                          <li>
                            Please remember to click <b>'Save'</b> after
                            uploading an
                            <b>image or PDF</b> to ensure your changes are
                            updated.
                          </li>
                        </ul>
                      </div>
                      <Form
                        name="xcarb1"
                        layout="vertical"
                        form={section2form3}
                        // onValuesChange={handleXcarb1InputChange}
                        // onFinish={onFinish}
                        // ref={section2formRef}
                        onChange={() => setFormEdited(true)}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label="Title"
                              name="title"
                              initialValue={A913formData.tough_acrd_hd}
                              onChange={(e) => {
                                setA913formData((prv) => {
                                  prv.tough_acrd_hd = e.target.value;
                                  return prv;
                                });
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the title!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="Description"
                              name="description"
                              initialValue={tough_acrd_dtls.description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Description!",
                                },
                              ]}
                            >
                              {/* <Input /> */}
                              <ReactQuill
                                modules={modules}
                                value={tough_acrd_dtls.description}
                                onChange={(content, delta, source, editor) => {
                                  if (
                                    cleanHTML(content) !==
                                    cleanHTML(tough_acrd_dtls.description)
                                  ) {
                                    settough_acrd_dtls((prv) => {
                                      prv.description = cleanHTML(content);
                                      return prv;
                                    });
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              label="Image"
                              name="image"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please upload an Image!",
                              //   },
                              // ]}
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value || process_acrd_dtls.imageUrl
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error("Please upload an Image!")
                                        ),
                                },
                              ]}
                            >
                              <div className="up-del">
                                {" "}
                                {/* Container for image upload and delete */}
                                <div className="img-preview">
                                  {" "}
                                  {/* Image preview container */}
                                  <ImagePreview // Component to render image preview
                                    key={tough_acrd_dtls.imageUrl}
                                    imageUrl={tough_acrd_dtls.imageUrl}
                                  />
                                </div>
                                {
                                  <Upload // Upload component for image
                                    name="avatar"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                    onChange={(info) =>
                                      handle_toughnessimg_Change(info)
                                    } // Handle image upload change
                                  >
                                    <Button icon={<UploadOutlined />} />
                                  </Upload>
                                }
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label="Alt/Title Text"
                              name="img_alt_txt"
                              initialValue={tough_acrd_dtls.img_alt_txt}
                              onChange={(e) => {
                                settough_acrd_dtls((prv) => {
                                  prv.img_alt_txt = e.target.value;
                                  return prv;
                                });
                              }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Existing image id"
                              name="existing_img_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Existing id"
                              name="existing_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="savebtns">
                          <Space>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => toughness_save(0)}
                              >
                                Save & go back
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => toughness_save(1)}
                              >
                                Save
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => toughness_save(2)}
                              >
                                Save & Continue
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      </Form>
                      {isModalVisible && (
                        <div className="spinner-loading">
                          {/* <Spin size="large" /> */}
                          <Spin
                            indicator={
                              <LoadingOutlined className="spin-icon" spin />
                            }
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Code Approved" key="6">
                      <div className="note-info">
                        <h5>NOTE*</h5>
                        <ul className="m-0 p-0">
                          <li>
                            <b>30 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Title'</b> field is recommended.
                          </li>
                          <li>
                            <b>700 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Description'</b> field is recommended.
                          </li>
                          <li>
                            Please maintain the image resolution of the{" "}
                            <b>'Main image'</b> to exact{" "}
                            <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to avoid
                            design break. Please use file types: <b>jpg/png</b>{" "}
                            . Image size less than <b>500 kb</b> is recommended
                            for better performance.
                          </li>
                          <li>
                            Please remember to click <b>'Save'</b> after
                            uploading an
                            <b>image or PDF</b> to ensure your changes are
                            updated.
                          </li>
                        </ul>
                      </div>
                      <Form
                        name="xcarb1"
                        layout="vertical"
                        form={section2form4}
                        // onValuesChange={handleXcarb1InputChange}
                        // onFinish={onFinish}
                        // ref={section2formRef}
                        onChange={() => setFormEdited(true)}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label="Title"
                              name="title"
                              initialValue={A913formData.code_acrd_hd}
                              onChange={(e) => {
                                setA913formData((prv) => {
                                  prv.code_acrd_hd = e.target.value;
                                  return prv;
                                });
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the title!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="Description"
                              name="description"
                              initialValue={code_acrd_dtls.description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Description!",
                                },
                              ]}
                            >
                              {/* <Input /> */}
                              <ReactQuill
                                modules={modules}
                                value={code_acrd_dtls.description}
                                onChange={(content, delta, source, editor) => {
                                  if (
                                    cleanHTML(content) !==
                                    cleanHTML(code_acrd_dtls.description)
                                  ) {
                                    setcode_acrd_dtls((prv) => {
                                      prv.description = cleanHTML(content);
                                      return prv;
                                    });
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              label="Image"
                              name="image"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please upload an Image!",
                              //   },
                              // ]}
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value || process_acrd_dtls.imageUrl
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error("Please upload an Image!")
                                        ),
                                },
                              ]}
                            >
                              <div className="up-del">
                                {" "}
                                {/* Container for image upload and delete */}
                                <div className="img-preview">
                                  {" "}
                                  {/* Image preview container */}
                                  <ImagePreview // Component to render image preview
                                    key={code_acrd_dtls.imageUrl}
                                    imageUrl={code_acrd_dtls.imageUrl}
                                  />
                                </div>
                                {
                                  <Upload // Upload component for image
                                    name="avatar"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                    onChange={(info) =>
                                      handle_codeapproveimg_Change(info)
                                    } // Handle image upload change
                                  >
                                    <Button icon={<UploadOutlined />} />
                                  </Upload>
                                }
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label="Alt/Title Text"
                              name="img_alt_txt"
                              initialValue={code_acrd_dtls.img_alt_txt}
                              onChange={(e) => {
                                setcode_acrd_dtls((prv) => {
                                  prv.img_alt_txt = e.target.value;
                                  return prv;
                                });
                              }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Existing image id"
                              name="existing_img_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Existing id"
                              name="existing_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="savebtns">
                          <Space>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => codeapprove_save(0)}
                              >
                                Save & go back
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => codeapprove_save(1)}
                              >
                                Save
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => codeapprove_save(2)}
                              >
                                Save & Continue
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      </Form>
                      {isModalVisible && (
                        <div className="spinner-loading">
                          {/* <Spin size="large" /> */}
                          <Spin
                            indicator={
                              <LoadingOutlined className="spin-icon" spin />
                            }
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Seismic Application" key="7">
                      <div className="note-info">
                        <h5>NOTE*</h5>
                        <ul className="m-0 p-0">
                          <li>
                            <b>30 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Title'</b> field is recommended.
                          </li>
                          <li>
                            <b>700 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Description'</b> field is recommended.
                          </li>
                          <li>
                            Please remember to click <b>'Save'</b> after
                            uploading an
                            <b>image or PDF</b> to ensure your changes are
                            updated.
                          </li>
                        </ul>
                      </div>
                      <Form
                        name="xcarb1"
                        layout="vertical"
                        form={section2form5}
                        // onValuesChange={handleXcarb1InputChange}
                        // onFinish={onFinish}
                        // ref={section2formRef}
                        onChange={() => setFormEdited(true)}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label="Title"
                              name="title"
                              initialValue={A913formData.seismic_acrd_hd}
                              onChange={(e) => {
                                setA913formData((prv) => {
                                  prv.seismic_acrd_hd = e.target.value;
                                  return prv;
                                });
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the title!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={18}>
                            <Form.Item
                              label="Description"
                              name="description"
                              initialValue={seismic_acrd_dtls.description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Description!",
                                },
                              ]}
                            >
                              {/* <Input /> */}
                              <ReactQuill
                                modules={modules}
                                value={seismic_acrd_dtls.description}
                                onChange={(content, delta, source, editor) => {
                                  if (
                                    cleanHTML(content) !==
                                    cleanHTML(seismic_acrd_dtls.description)
                                  ) {
                                    setseismic_acrd_dtls((prv) => {
                                      prv.description = cleanHTML(content);
                                      return prv;
                                    });
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              label="Existing image id"
                              name="existing_img_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Existing id"
                              name="existing_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="savebtns">
                          <Space>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => seismic_save(0)}
                              >
                                Save & go back
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => seismic_save(1)}
                              >
                                Save
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => seismic_save(2)}
                              >
                                Save & Continue
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      </Form>
                      {isModalVisible && (
                        <div className="spinner-loading">
                          {/* <Spin size="large" /> */}
                          <Spin
                            indicator={
                              <LoadingOutlined className="spin-icon" spin />
                            }
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Availability" key="8">
                      <div className="note-info">
                        <h5>NOTE*</h5>
                        <ul className="m-0 p-0">
                          <li>
                            <b>30 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Title'</b> field is recommended.
                          </li>
                          <li>
                            <b>700 Characters</b> max including{" "}
                            <b>alphabets, spaces, symbols and numbers</b> for
                            the <b>'Description'</b> field is recommended.
                          </li>
                          <li>
                            Please maintain the image resolution of the{" "}
                            <b>'Main image'</b> to exact{" "}
                            <b>400 x 500 px ( Aspect Ratio 4:5 )</b> to avoid
                            design break. Please use file types: <b>jpg/png</b>{" "}
                            . Image size less than <b>500 kb</b> is recommended
                            for better performance.
                          </li>
                          <li>
                            Please remember to click <b>'Save'</b> after
                            uploading an
                            <b>image or PDF</b> to ensure your changes are
                            updated.
                          </li>
                        </ul>
                      </div>
                      <Form
                        name="xcarb1"
                        layout="vertical"
                        form={section2form6}
                        // onValuesChange={handleXcarb1InputChange}
                        // onFinish={onFinish}
                        // ref={section2formRef}
                        onChange={() => setFormEdited(true)}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label="Title"
                              name="title"
                              initialValue={A913formData.avl_acrd_hd}
                              onChange={(e) => {
                                setA913formData((prv) => {
                                  prv.avl_acrd_hd = e.target.value;
                                  return prv;
                                });
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the title!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="Description"
                              name="description"
                              initialValue={avl_acrd_dtls.description}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Description!",
                                },
                              ]}
                            >
                              {/* <Input /> */}
                              <ReactQuill
                                modules={modules}
                                value={avl_acrd_dtls.description}
                                onChange={(content, delta, source, editor) => {
                                  if (
                                    cleanHTML(content) !==
                                    cleanHTML(avl_acrd_dtls.description)
                                  ) {
                                    setavl_acrd_dtls((prv) => {
                                      prv.description = cleanHTML(content);
                                      return prv;
                                    });
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              label="Image"
                              name="image"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please upload an Image!",
                              //   },
                              // ]}
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value || process_acrd_dtls.imageUrl
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error("Please upload an Image!")
                                        ),
                                },
                              ]}
                            >
                              <div className="up-del">
                                {" "}
                                {/* Container for image upload and delete */}
                                <div className="img-preview">
                                  {" "}
                                  {/* Image preview container */}
                                  <ImagePreview // Component to render image preview
                                    key={avl_acrd_dtls.imageUrl}
                                    imageUrl={avl_acrd_dtls.imageUrl}
                                  />
                                </div>
                                {
                                  <Upload // Upload component for image
                                    name="avatar"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"  // Action URL for image upload
                                    onChange={(info) =>
                                      handle_availabilityimg_Change(info)
                                    } // Handle image upload change
                                  >
                                    <Button icon={<UploadOutlined />} />
                                  </Upload>
                                }
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              label="Alt/Title Text"
                              name="img_alt_txt"
                              initialValue={avl_acrd_dtls.img_alt_txt}
                              onChange={(e) => {
                                setavl_acrd_dtls((prv) => {
                                  prv.img_alt_txt = e.target.value;
                                  return prv;
                                });
                              }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Existing image id"
                              name="existing_img_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Existing id"
                              name="existing_id"
                              hidden
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="savebtns">
                          <Space>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => avilability_save(0)}
                              >
                                Save & go back
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => avilability_save(1)}
                              >
                                Save
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      </Form>
                      {isModalVisible && (
                        <div className="spinner-loading">
                          {/* <Spin size="large" /> */}
                          <Spin
                            indicator={
                              <LoadingOutlined className="spin-icon" spin />
                            }
                          />
                        </div>
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
}

export default A913;
