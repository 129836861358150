import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import UserData from "./components/UserData";
// import Structuralshapes from "./components/Structuralshapes";
import Barsnrods from "./components/Barsnrods";
import Flats from "./components/Flats";
import Logistics from "./components/Logistics";
import Wiresolns from "./components/Wiresolns";
import News from "./components/News";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import "./App.css";
import Agriculture from "./components/flats_subpages/Agriculture";
import Automotive from "./components/flats_subpages/Automotive";
import Construction from "./components/flats_subpages/Construction";
import Packaging from "./components/flats_subpages/Packaging";
import Appliance from "./components/flats_subpages/Appliance";
import Building from "./components/flats_subpages/Building";
import Energy from "./components/flats_subpages/Energy";
import Transportation from "./components/flats_subpages/Transportation";
import Sustainaibility from "./components/flats_subpages/Sustainaibility";
import Steel from "./components/wiresol_subpages/Steel";
import Fencing from "./components/wiresol_subpages/Fencing";
import Wire from "./components/wiresol_subpages/Wire";
import Product from "./components/structuralshapes_subpages/Product";
import A913 from "./components/structuralshapes_subpages/A913";
import Sustainaibilityss from "./components/structuralshapes_subpages/Sustainaibilityss";
import Project from "./components/structuralshapes_subpages/Project";
import Subscribers from "./components/Subscribers";
import Social from "./components/Social";
import Changepass from "./components/Changepass";
import Tab_StructuralShapes from "./components/Tab_StructuralShapes";
import { useNavigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const token = localStorage.getItem("Token");
  return token ? children : <Navigate to="/admin" />;
}

function App() {
  const navigate = useNavigate();
  // const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentCounterValue = localStorage.getItem("lastLoginTimestamp");
      let Lastuptime = parseInt(
        localStorage.getItem("lastUpdateTimestamp") || "0",
        10
      );

      if (!currentCounterValue) {
        localStorage.setItem("lastLoginTimestamp", 0);
      }

      if (parseInt(currentCounterValue) !== 0) {
        let flag = true;
        const currentTimestamp = new Date().getTime();
        const timeDifferenceInSeconds = (currentTimestamp - Lastuptime) / 1000;
        const newCounterValue = parseInt(currentCounterValue, 10) + 1;

        if (
          timeDifferenceInSeconds + newCounterValue >= 28800 &&
          Lastuptime !== 0
        ) {
          localStorage.setItem("Token", "");
          navigate("/admin");
          localStorage.setItem("lastLoginTimestamp", 0);
          localStorage.setItem("lastUpdateTimestamp", "0");
          flag = false;
        }

        if (newCounterValue >= 28800) {
          localStorage.setItem("Token", "");
          navigate("/admin");
          localStorage.setItem("lastLoginTimestamp", 0);
          localStorage.setItem("lastUpdateTimestamp", "0");
          flag = false;
        }

        if (flag) {
          localStorage.setItem(
            "lastLoginTimestamp",
            newCounterValue.toString()
          );
          localStorage.setItem(
            "lastUpdateTimestamp",
            currentTimestamp.toString()
          );
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Save the unload time in localStorage
      localStorage.setItem("unloadTime", JSON.stringify(new Date()));
    };

    const handleLoad = () => {
      // Clear the localStorage when the user closes the browser or tab
      let loadTime = new Date();
      let unloadTime = new Date(JSON.parse(localStorage.getItem("unloadTime")));
      let refreshTime = loadTime.getTime() - unloadTime.getTime();

      if (refreshTime > 10000) {
        // 3000 milliseconds
        // Perform your action when the refresh time is greater than the threshold
        localStorage.setItem("Token", "");
        UserData.setUserId("");
        UserData.setEmail("");
        UserData.setJwtToken("");
        localStorage.setItem("lastLoginTimestamp", 0);
        localStorage.setItem("lastUpdateTimestamp", "0");
        window.location.href = "/admin";
      }
    };

    // Add the event listeners
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);

    // Cleanup: Remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
    };
  }, [navigate]);

  return (
    <div className="App">
      {/* <Router> */}
      <Routes>
        {/* <Route exact path="/" element={<Navigate to="/admin" />} /> */}
        <Route path="/admin" element={<Login />} />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              {" "}
              <Dashboard />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/structuralshapes"
          element={
            <PrivateRoute>
              <Tab_StructuralShapes />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/barsnrods"
          element={
            <PrivateRoute>
              <Barsnrods />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats"
          element={
            <PrivateRoute>
              <Flats />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/logistics"
          element={
            <PrivateRoute>
              <Logistics />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/wiresolns"
          element={
            <PrivateRoute>
              <Wiresolns />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/news"
          element={
            <PrivateRoute>
              <News />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/subscribers"
          element={
            <PrivateRoute>
              <Subscribers />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/social"
          element={
            <PrivateRoute>
              <Social />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/agriculture"
          element={
            <PrivateRoute>
              <Agriculture />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/automotive"
          element={
            <PrivateRoute>
              <Automotive />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/construction"
          element={
            <PrivateRoute>
              <Construction />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/packaging"
          element={
            <PrivateRoute>
              <Packaging />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/appliances"
          element={
            <PrivateRoute>
              <Appliance />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/building"
          element={
            <PrivateRoute>
              <Building />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/energy"
          element={
            <PrivateRoute>
              <Energy />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/transportation"
          element={
            <PrivateRoute>
              <Transportation />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/flats/sustainaibility"
          element={
            <PrivateRoute>
              <Sustainaibility />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/wiresolns/steel"
          element={
            <PrivateRoute>
              <Steel />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/wiresolns/fencing"
          element={
            <PrivateRoute>
              <Fencing />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/wiresolns/wireropes"
          element={
            <PrivateRoute>
              <Wire />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/structuralshapes/productsnsizerange"
          element={
            <PrivateRoute>
              <Product />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/structuralshapes/a913highstrength"
          element={
            <PrivateRoute>
              <A913 />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/structuralshapes/sustainaibility"
          element={
            <PrivateRoute>
              <Sustainaibilityss />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/structuralshapes/projectgallery"
          element={
            <PrivateRoute>
              <Project />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/changep"
          element={
            <PrivateRoute>
              <Changepass />
            </PrivateRoute>
          }
        />
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
